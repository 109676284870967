
export default class Production {
  constructor(core) {
    this.core = core;
  }

  async getProductions(data) {
    return await this.core.apiCall('post', `/production/search`, data);
  }

  async onNewProcedure(data) {
    return await this.core.apiCall('post', `/production`, data);
  }

  async onProcedureCompleted(id, data) {
    return await this.core.apiCall('post', `/production/${id}`, data);
  }

  async getProcedure(id) {
    return await this.core.apiCall('get', `/production/${id}`);
  }

  async deleteProcedure(id) {
    return await this.core.apiCall('delete', `/production/${id}`);
  }

  async getReport(data) {
    return await this.core.apiCall('post', `/production/report`, data);
  }
}
