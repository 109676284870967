import React, { Component } from 'react'

import Header from './../../components/Header/header.component'
import Middleware from './platform.middleware'
import Notifications from './../../components/Notifications/notifications.component'
import Sidebar from './../../components/Sidebar/sidebar.component'

export default class Layout extends Component {
  
  render() {
    return (
      <div id="layout-wrapper">
        <Middleware />

        <Header />

        {/* <Notifications /> */}

        <Sidebar />

        <div className="main-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
