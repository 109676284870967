import React from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Container,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Collapse,
  TabContent,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams } from 'react-router-dom'

import Procedures from './procedures.data'

const ProductionPage = () => {
  const params = useParams();

  let displayProcedure = (key, idx) => {
    let data = Procedures[key];
    return <li className="list-group-item text-truncate" key={idx} >
      <Link to={`/quality-assurance/production/${data.name}`} className="card-link">
        <div className="d-flex">
          <div className="me-3">
            <i className={`${data.image} h1 text-primary`} ></i>
          </div>
          <div className="flex-1">
            <h5 className="mb-1"> {data.title}</h5>
            <p className="text-muted">{data.description}</p>
          </div>
        </div>
      </Link>
    </li>;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-center align-self-center">
          <div className="text-center" style={{ width: '500px' }}>
            <h4 className="mb-3">Choose a procedure</h4>
            <p className="text-muted mb-4">
              Several procedures are available, be sure to select the correct one
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-self-center">
          <div className="card border" style={{ width: '350px' }} >
            <ul className="list-group list-group-flush">
              {Object.keys(Procedures).map((key, idx) => (
                displayProcedure(key, idx)
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ProductionPage;
