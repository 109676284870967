import React from 'react'
import { Card, Button } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'


const SettingsSidebar = () => {
  const params = useParams();

  return (
    <Card className="email-leftbar">
      <Button type="button" 
        className="btn btn-danger btn-block waves-effect waves-light fw-semibold" 
        disabled={true} > 
          Delete Account  
      </Button>

      <div className="mail-list mt-4">
        <Link to="/settings/profile" className={params?.section === 'profile'? 'active' : null} >
          <i className="far fa-user me-2"></i> Profile
        </Link>
        <Link to="/settings/sessions" className={params?.section === 'sessions'? 'active' : null} disabled >
          <i className="fas fa-history me-2"></i> Sessions
        </Link>
      </div>
    </Card>
  );
}

export default SettingsSidebar;
