import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Container,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Collapse,
  TabContent,
  Label,
  Form
} from 'reactstrap'
import { Link, useHistory, useParams } from 'react-router-dom'

import { production } from '../../../actions/index'

import Procedures from './procedures.data'

const NewProcedurePage = () => {
  const params = useParams(),
    history = useHistory(),
    [errors, setErrors] = useState([]);

  // Form and submit management
  let serialNumber = null;
  const onSubmit = async e => {
    e.preventDefault();

    let res = await production.onNewProcedure({
      procedure: procedure.name,
      serialNumber: serialNumber.value
    });


    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      return setErrors(err);
    }


    history.push(`/quality-assurance/production/${res.data.procedure}/${res.data._id}`);
  }

  // Get the procedure
  const procedure = Procedures[params.procedure];
  if (!procedure) {
    console.log('TODO: Error management..')
  }

  // By default, the first step is always the serial number
  let step = procedure.steps[0];

  return (
    <div className="page-content">
      <Container fluid>

        <div className="d-flex justify-content-center align-self-center mt-2 mb-3">
          <div className="d-flex" >
            <div className="me-3">
              <i className={`${procedure.image} h1 text-primary`} ></i>
            </div>
            <div className="flex-1">
              <h5 className="mb-1"> {procedure.title}</h5>
              <p className="text-muted">{procedure.description}</p>
            </div>
          </div>
        </div>
        <Row>
          <Col xs="12">
            <Card className="overflow-hidden">
              <Form onSubmit={onSubmit}>
                <Row className="g-0">
                  <Col lg={{ size: 6, order: 1 }} className="order-2">
                    <div className="p-lg-5 p-4">
                      <h5>{step.title}</h5>
                      <p className="font-size-15 text-muted">{step.description}</p>

                      <div className="input-section mt-5">
                        <Row >
                          <Col>
                            <div className="position-relative">
                              <input type="text"
                                className={`form-control ${errors.serialNumber && 'is-invalid'}`}
                                name="value"
                                placeholder={step.placeholder}
                                ref={input => (serialNumber = input)} />
                            </div>
                          </Col>
                          <div className="col-auto">
                            <button type="submit" className="btn btn-primary w-md waves-effect waves-light">Next</button>
                          </div>
                        </Row>
                        <label className="fw-semibold"><small className="text-danger" >{errors.serialNumber && errors.serialNumber}</small></label>
                      </div>

                    </div>
                  </Col>
                  <Col lg={6} className="order-1 order-lg-2">
                    <div className="p-lg-5 p-4 bg h-100">

                      <div className="comingsoon-img">
                        <img src={step.image} alt="" className="img-fluid mx-auto d-block" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewProcedurePage;
