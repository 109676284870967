export default class Centers {
  LANGUAGES = {
    'french': 'Français',
    'english': 'Anglais'
  }

  CENTRAL_TYPE = {
    'default': '911',
    'monitoring': 'Télésurveillance'
  }

  constructor(core) {
    this.core = core;
  }

  async create(data) {
    return await this.core.apiCall('post', `/centers`, data);
  }

  async update(id, data) {
    return await this.core.apiCall('put', `/centers/${id}`, data);
  }

  async get(id) {
    return await this.core.apiCall('get', `/centers/${id}`);
  }

  async search(data) {
    return await this.core.apiCall('post', `/centers/search`, data);
  }
}
