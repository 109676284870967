import React, { useEffect, useState, useRef } from 'react'

const Loader = props => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: props.height? props.height : "calc(100vh - 160px)"}} >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Loader;