import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Components
import ProfileMenu from './../ProfileMenu/profileMenu.component'

import logo from './../../assets/images/logo-full.png'

const Header = () => {
  const [displayDesktop, setDisplayDesktop] = useState(true);
  const [displayMobile, setDisplayMobile] = useState(false);

  // TODO: Put this sidebar logic inside Redux/localstorage
  useEffect(() => {

    // Logic for changing sidebar
    if (document.body.clientWidth >= 993) { // Desktop view
      document.body.setAttribute('data-sidebar-size', displayDesktop ? 'lg' : 'sm');
    } else { // Mobile view
      document.body.setAttribute('data-sidebar-display', displayMobile ? 'true' : 'false');
    }
  }, [displayDesktop, displayMobile])

  window.addEventListener('resize', () => {
    if (document.body.clientWidth >= 993) { // Desktop view
      document.body.setAttribute('data-sidebar-size', displayDesktop ? 'lg' : 'sm');
    } else {
      document.body.setAttribute('data-sidebar-size', 'lg');
      setDisplayMobile(false);
    }
  });

  const toggleSidebar = () => {
    if (document.body.clientWidth >= 993) {
      setDisplayDesktop(!displayDesktop);
    } else {
      setDisplayMobile(!displayMobile);
    }
  }

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo">
              <img src="/logo_white.png" alt="Incendia Canada" />
            </Link>
          </div>
          <button
            type="button"
            onClick={toggleSidebar}
            className="btn btn-sm px-3 font-size-16 header-item "
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
        <div className="d-flex">

          {/* TODO: Make the notificationDropdown component */}
          {/* <NotificationDropdown /> */}

          <ProfileMenu />
        </div>
      </div>
    </header>
  );
}



export default Header;