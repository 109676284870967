import { data } from 'jquery';
import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { sites } from './../../../../actions/index'

const SiteAnimals = props => {
  const params = useParams();
  let [errors, setErrors] = useState({}),
    [selected, setSelected] = useState(null),
    animal = {
      name: useRef(null),
      type: useRef(null),
      breed: useRef(null),
      color: useRef(null)
    };



  const onSubmit = async e => {
    e.preventDefault();

    var data = {
      name: animal.name.current?.value,
      type: parseInt(animal.type.current?.value),
      breed: animal.breed.current?.value,
      color: animal.color.current?.value
    };

    // TODO: Error management
    let res = null;
    if (selected) {
      res = await sites.updateAnimal(params.id, selected, data);
    } else {
      res = await sites.addAnimal(params.id, data);
    }

    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  const onDelete = async (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet animal?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {

        // TODO: Error management
        await sites.deleteAnimal(params.id, id);
        window.location.reload(true);
      }
    })
  }

  const onEdit = async (e, data, idx) => {
    e.preventDefault();

    animal.name.current.value = data.name;
    animal.type.current.value = data.type;
    animal.breed.current.value = data.breed;
    animal.color.current.value = data.color;
    setSelected(data._id);
  }

  const onCancel = e => {
    e.preventDefault();

    animal.name.current.value = '';
    animal.type.current.value = '0';
    animal.breed.current.value = '';
    animal.color.current.value = '';
    setSelected(null);
  }

  const displayRow = (row, idx) => {
    return <tr key={idx} >
      <td>{row.name}</td>
      <td>{sites.ANIMALS[row.type]}</td>
      <td>{row.breed}</td>
      <td>{row.color}</td>
      <td className="text-right">
        <span style={{ width: '100px', display: 'inline-block' }} >
          <button className="me-2 btn btn-primary btn-sm"
            onClick={(e) => onEdit(e, row, idx)} >
            <i className='fas fa-pencil-alt' />
          </button>
          <button className="btn btn-danger btn-sm"
            onClick={(e) => onDelete(e, row._id)} >
            <i className='fas fa-trash' />
          </button>
        </span>
      </td>
    </tr>;
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h4 className="text-center"><strong>Animaux</strong></h4>

          <div className="table-responsive mt-4 mb-3">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Type</th>
                  <th>Race</th>
                  <th>Couleur</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {props.site?.animals?.map((row, idx) => (
                  displayRow(row, idx)
                ))}

                {props.site?.animals?.length === 0 && <tr>
                  <td colSpan="5" className='text-center' >Aucun animal...</td>
                </tr>}
              </tbody>
            </Table>
          </div>

          <Row className="mt-2" >
            <Col md={12}>
              <hr className="mt-4" />
            </Col>
          </Row>

          <Form onSubmit={onSubmit}>
            <Row className="mt-2" >
              <Col lg={12} className="text-right" >
                <button
                  onClick={onCancel}
                  className="me-3 btn btn-danger btn-sm waves-effect waves-light" >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-success btn-sm waves-effect waves-light" >
                  Sauvegarder
                </button>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Nom <small className="text-danger" >{errors?.name && `● ${errors?.name}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={animal.name}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.type && `● ${errors?.type}`}</small></label>
                  <select className="form-select"
                    ref={input => (animal.type.current = input)}
                  >
                    {Object.keys(sites.ANIMALS).map((key, idx) => (
                      <option key={idx} value={key} > {sites.ANIMALS[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Race <small className="text-danger" >{errors?.breed && `● ${errors?.breed}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={animal.breed}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Couleur <small className="text-danger" >{errors?.color && `● ${errors?.color}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={animal.color}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}



export default SiteAnimals;