export default class Municipalities {
  LANGUAGES = {
    'french': 'Français',
    'english': 'Anglais'
  }

  constructor(core) {
    this.core = core;
  }

  async create(data) {
    return await this.core.apiCall('post', `/municipalities`, data);
  }

  async update(id, data) {
    return await this.core.apiCall('put', `/municipalities/${id}`, data);
  }

  async get(id) {
    return await this.core.apiCall('get', `/municipalities/${id}`);
  }

  async search(data) {
    return await this.core.apiCall('post', `/municipalities/search`, data);
  }
}
