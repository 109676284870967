export default class SerialNumbers {
    constructor(core) {
      this.core = core;
    }
  
    async onCreatePcbSerialNumber(data) {
      return await this.core.apiCall('post', `/serialnumber/pcb`, data);
    }
  
    async onEditPcbSerialNumber(id, data) {
      return await this.core.apiCall('put', `/serialnumber/pcb/${id}`, data);
    }
  
    async getPcbSerialNumber(id) {
      return await this.core.apiCall('get', `/serialnumber/pcb/${id}`);
    }
  
    /*
    Returns the list of serial number (SN) batches associated to a Printed Circuit Board
    The DB has each SN as a unique entry so the query will associate the entries based on
    the createdAt value, report the total entries per batch, how many are assigned to a board

    This can be viewed from the ingressDB module in the serialnumbers/pcb.controller.ts file
    */
    async getPcbSerialNumbers(data) {
      return await this.core.apiCall('post', `/serialnumber/pcb/search`, data);
    }
    async getSerialNumberBatches(data) {
      return await this.core.apiCall('post', `/serialnumber/batch/search`, data);
    }
  }