import { data } from 'jquery';
import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'

import { core, sites } from './../../../../actions/index'
import Swal from 'sweetalert2'
import moment from 'moment'

import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'

const SiteDevice = props => {
  const params = useParams();
  let [errors, setErrors] = useState({}),
    [selected, setSelected] = useState(null),
    [isSiteFormChanged, setIsSiteFormChanged] = useState(false),
    [installationDate, setInstallationDate] = useState(new Date()),
    [user, setUser] = useState({}),

    device = {
      type: useRef(null),
      serialNumber: useRef(null),
      floor: useRef(null),
      room: useRef(null),
      sector: useRef(null),
      communication: useRef(null),
      position: useRef(null),
      serialNumberCommunicator: useRef(null),

      falseAlarmExistingDetector: useRef(null),
      falseAlarmBathroom: useRef(null),
      falseAlarmCooker: useRef(null),
      falseAlarmFirePlace: useRef(null),
      falseAlarmSmooking: useRef(null),
      falseAlarmDust: useRef(null),
      falseAlarmFrequentCooking: useRef(null),
      falseAlarmManipulation: useRef(null),
      falseAlarmSpecialActivity: useRef(null)
    },
    site = {
      silent: useRef(null),
      noFireman: useRef(null),
      noTransmission: useRef(null),
      noOutgoingMaydayRF: useRef(null),
      noIncomingMaydayRF: useRef(null)
    },
    forceAdd = false;

  useEffect(() => {
    let ref = core.sub('me', user => {
      setUser(user);
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  const onSubmit = async e => {
    if (e) e.preventDefault();

    var data = {
      type: device.type.current?.value,
      serialNumber: device.serialNumber.current?.value,
      floor: device.floor.current?.value,
      room: device.room.current?.value,
      sector: device.sector.current?.value,
      communication: device.communication.current?.value,
      position: device.position.current?.value,
      serialNumberCommunicator: device.serialNumberCommunicator.current?.value,

      installationDate: installationDate,

      falseAlarmExistingDetector: device.falseAlarmExistingDetector.current?.checked,
      falseAlarmBathroom: device.falseAlarmBathroom.current?.checked,
      falseAlarmCooker: device.falseAlarmCooker.current?.checked,
      falseAlarmFirePlace: device.falseAlarmFirePlace.current?.checked,
      falseAlarmSmooking: device.falseAlarmSmooking.current?.checked,
      falseAlarmDust: device.falseAlarmDust.current?.checked,
      falseAlarmFrequentCooking: device.falseAlarmFrequentCooking.current?.checked,
      falseAlarmManipulation: device.falseAlarmManipulation.current?.checked,
      falseAlarmSpecialActivity: device.falseAlarmSpecialActivity.current?.checked,
    };

    if (forceAdd) {
      data.forcedAdd = true;
    }

    let res = null;
    if (selected) {
      await sites.deleteDevice(params.id, selected);
    }
    res = await sites.addDevice(params.id, data);

    if (res.errors) {
      let err = {};
      forceAdd = false;
      for (const error of res.errors) {
        if (error.code == 'deviceAlreadyTaken') {
          Swal.fire({
            title: 'Ce pentagone est déjà associé à un autre site, voulez-vous forcer l\'association à ce site?',
            html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler',
          }).then(async (result) => {
            if (result.isConfirmed) {
              forceAdd = true;
              onSubmit(null);
            }
          })
        }
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  const onDelete = async (e, data) => {
    e.preventDefault();

    Swal.fire({
      title: 'Comment souhaitez-vous retirer ce pentagone du site?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',

      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Archiver!',
      denyButtonText: `Dissocier!`,
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) { // Archive
        await sites.addDevice('1355eb3c-170f-4d4d-8dad-09ab95f3cb09', {
          forcedAdd: true,
          type: '0',
          serialNumber: data.serialNumber,
          floor: '0',
          room: '0',
          sector: '0',
          communication: '0',
          position: '0',
          falseAlarmExistingDetector: false,
          falseAlarmBathroom: false,
          falseAlarmCooker: false,
          falseAlarmFirePlace: false,
          falseAlarmSmooking: false,
          falseAlarmDust: false,
          falseAlarmFrequentCooking: false,
          falseAlarmManipulation: false,
          falseAlarmSpecialActivity: false,
          serialNumberCommunicator: '',
          installationDate: null,
        });
        window.location.reload(true);
      } else if (result.isDenied) { // Dissociate
        await sites.deleteDevice(params.id, data._id);
        window.location.reload(true);
      }
    })
  }

  const onEdit = async (e, data, idx) => {
    e.preventDefault();

    device.type.current.value = data.model;
    device.serialNumber.current.value = data.serialNumber;
    device.floor.current.value = data.floor;
    device.room.current.value = data.room;
    device.sector.current.value = data.sector;
    device.communication.current.value = data.transceiver;
    device.position.current.value = data.position;
    device.serialNumberCommunicator.current.value = data.serialNumberCommunicator;

    device.falseAlarmExistingDetector.current.checked = data.falseAlarmExistingDetector;
    device.falseAlarmBathroom.current.checked = data.falseAlarmBathroom;
    device.falseAlarmCooker.current.checked = data.falseAlarmCooker;
    device.falseAlarmFirePlace.current.checked = data.falseAlarmFirePlace;
    device.falseAlarmSmooking.current.checked = data.falseAlarmSmooking;
    device.falseAlarmDust.current.checked = data.falseAlarmDust;
    device.falseAlarmFrequentCooking.current.checked = data.falseAlarmFrequentCooking;
    device.falseAlarmManipulation.current.checked = data.falseAlarmManipulation;
    device.falseAlarmSpecialActivity.current.checked = data.falseAlarmSpecialActivity;

    setInstallationDate(data.installationDate);
    setSelected(data._id);
  }

  const onCancel = e => {
    e.preventDefault();

    device.type.current.value = '0';
    device.serialNumber.current.value = '';
    device.floor.current.value = '0';
    device.room.current.value = '0';
    device.sector.current.value = '0';
    device.communication.current.value = '0';
    device.position.current.value = '0';

    device.falseAlarmExistingDetector.current.checked = false;
    device.falseAlarmBathroom.current.checked = false;
    device.falseAlarmCooker.current.checked = false;
    device.falseAlarmFirePlace.current.checked = false;
    device.falseAlarmSmooking.current.checked = false;
    device.falseAlarmDust.current.checked = false;
    device.falseAlarmFrequentCooking.current.checked = false;
    device.falseAlarmManipulation.current.checked = false;
    device.falseAlarmSpecialActivity.current.checked = false;

    device.serialNumberCommunicator.current.value = '';

    setSelected(null);
    setInstallationDate(new Date());
  }

  const defaultValues = {
    silent: props.site?.silent,
    noFireman: props.site?.noFireman,
    timezone: props.site?.timezone,
    noTransmission: props.site?.noTransmission,
    noOutgoingMaydayRF: props.site?.noOutgoingMaydayRF,
    noIncomingMaydayRF: props.site?.noIncomingMaydayRF,
    municipality: props.site?.municipality
  }

  const _getData = () => {
    return {
      silent: site.silent.current?.checked,
      noFireman: site.noFireman.current?.checked,
      timezone: site.timezone.value,
      noTransmission: site.noTransmission.current?.checked,
      noOutgoingMaydayRF: site.noOutgoingMaydayRF.current?.checked,
      noIncomingMaydayRF: site.noIncomingMaydayRF.current?.checked,
      municipality: site.municipality.value
    }
  }

  const onSiteFormChange = () => {
    let data = _getData();

    if (JSON.stringify(data) != JSON.stringify(defaultValues)) {
      setIsSiteFormChanged(true);
      return;
    }
    setIsSiteFormChanged(false);
  }

  const onSaveSite = async e => {
    e.preventDefault();

    let res = await sites.update(params.id, _getData());

    // TODO: Error management
    if (!res.errors) window.location.reload(true);
  }

  const displayRow = (row, idx) => {
    return <tr key={idx} >
      <td>{sites.DEVICE_TYPES[row.model]}</td>
      <td>{row.serialNumber}</td>
      <td>{sites.ROOM[row.room]}</td>
      <td>{sites.FLOOR[row.floor]}</td>
      <td>{sites.SECTOR[row.sector]}</td>
      <td>{sites.POSITION[row.position]}</td>
      <td>{sites.COMMUNICATION[row.transceiver]}</td>
      <td>{row.serialNumberCommunicator}</td>
      <td className="text-right">
        <span style={{ width: '100px', display: 'inline-block' }} >
          <button className="me-2 btn btn-primary btn-sm d-inline"
            onClick={(e) => onEdit(e, row, idx)} >
            <i className='fas fa-pencil-alt' />
          </button>
          <button className="btn btn-danger btn-sm d-inline"
            onClick={(e) => onDelete(e, row)} >
            <i className='fas fa-trash' />
          </button>
        </span>
      </td>
    </tr>;
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          {user?.role != 'risc' && <button
            onClick={onSaveSite}
            className="btn btn-success btn-sm waves-effect waves-light float-right"
            disabled={!isSiteFormChanged} >
            Sauvegarder
          </button>}
          <h4 className="text-center"><strong>Pentagones</strong></h4>

          <Form onSubmit={onSubmit}>
            {user?.role != 'risc' && <div>
              <Row className="mt-3">
                <Col md="6">
                  <div className="position-relative">
                    <label className="fw-semibold">No de Famille</label>
                    <input type="text"
                      className={`form-control`}
                      disabled={true}
                      value={props.site?.familyId}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <label className="fw-semibold">Municipalité</label>
                    <select className="form-select"
                      defaultValue={defaultValues.municipality}
                      ref={input => (site.municipality = input)}
                      onChange={onSiteFormChange} >
                      <option value={null} > </option>
                      {Object.keys(props.municipalities).map((key, idx) => (
                        <option key={idx} value={props.municipalities[key]._id} > {props.municipalities[key].name} </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="position-relative">
                    <label className="form-label">Fuseau horaire</label>
                    <select className="form-select"
                      defaultValue={defaultValues.timezone}
                      onChange={onSiteFormChange}
                      ref={input => (site.timezone = input)}
                    >
                      {moment.tz.names().map((timezone) => (
                        <option key={timezone} value={timezone}>{timezone}</option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg="6" xl="2" className="pt-3">
                  <div className="form-check form-switch form-switch-lg" >
                    <input className="form-check-input" type="checkbox"
                      defaultChecked={defaultValues.silent}
                      ref={site.silent}
                      onChange={onSiteFormChange} />
                    <label className="fw-semibold">Silencieux <small className="text-danger" >{errors?.silent && `● ${errors?.silent}`}</small></label>
                  </div>
                </Col>
                <Col lg="6" xl="2" className="pt-3">
                  <div className="form-check form-switch form-switch-lg" >
                    <input className="form-check-input" type="checkbox"
                      defaultChecked={defaultValues.noFireman}
                      ref={site.noFireman}
                      onChange={onSiteFormChange} />
                    <label className="fw-semibold">Pas de Pompier <small className="text-danger" >{errors?.noFireman && `● ${errors?.noFireman}`}</small></label>

                  </div>
                </Col>
                <Col lg="6" xl="2" className="pt-3">
                  <div className="form-check form-switch form-switch-lg" >
                    <input className="form-check-input" type="checkbox"
                      defaultChecked={defaultValues.noTransmission}
                      ref={site.noTransmission}
                      onChange={onSiteFormChange} />
                    <label className="fw-semibold">Pas de Transmission <small className="text-danger" >{errors?.noTransmission && `● ${errors?.noTransmission}`}</small></label>
                  </div>
                </Col>
                <Col lg="6" xl="3" className="pt-3">
                  <div className="form-check form-switch form-switch-lg" >
                    <input className="form-check-input" type="checkbox"
                      defaultChecked={defaultValues.noIncomingMaydayRF}
                      ref={site.noIncomingMaydayRF}
                      onChange={onSiteFormChange} />
                    <label className="fw-semibold">Pas de Mayday RF entrant <small className="text-danger" >{errors?.noIncomingMaydayRF && `● ${errors?.noIncomingMaydayRF}`}</small></label>
                  </div>
                </Col>
                <Col lg="6" xl="3" className="pt-3">
                  <div className="form-check form-switch form-switch-lg" >
                    <input className="form-check-input" type="checkbox"
                      defaultChecked={defaultValues.noOutgoingMaydayRF}
                      ref={site.noOutgoingMaydayRF}
                      onChange={onSiteFormChange} />
                    <label className="fw-semibold">Pas de Mayday RF sortant <small className="text-danger" >{errors?.noOutgoingMaydayRF && `● ${errors?.noOutgoingMaydayRF}`}</small></label>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <hr className="mt-4" />
                </Col>
              </Row>
            </div>}


            <div className="table-responsive mt-4 mb-3">
              <Table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Numéro Série</th>
                    <th>Pièce</th>
                    <th>Étage</th>
                    <th>Secteur</th>
                    <th>Position</th>
                    <th>Communication</th>
                    <th>Numéro Série Communicateur</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.site?.devices?.map((row, idx) => (
                    displayRow(row, idx)
                  ))}

                  {(!props.site?.devices || props.site?.devices?.length === 0) && <tr>
                    <td colSpan="9" className='text-center' >Aucun pentagone</td>
                  </tr>}
                </tbody>
              </Table>
            </div>

            <Row>
              <Col md={12}>
                <hr className="mt-4" />
              </Col>
            </Row>

            <Row className="mt-2" >
              <Col lg={12} className="text-right" >
                <button
                  onClick={onCancel}
                  className="me-3 btn btn-danger btn-sm waves-effect waves-light" >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-success btn-sm waves-effect waves-light" >
                  Sauvegarder
                </button>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Numéro de série <small className="text-danger" >{errors?.serialNumber && `● ${errors?.serialNumber}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    disabled={selected && device.serialNumber.current.value != ''}
                    ref={device.serialNumber}
                  />
                </div>
              </Col>
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.type && `● ${errors?.type}`}</small></label>
                  <select className="form-select"
                    ref={input => (device.type.current = input)} >
                    {Object.keys(sites.DEVICE_TYPES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.DEVICE_TYPES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12" xl="6">
                <Row className="mt-3" >
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Pièce <small className="text-danger" >{errors?.room && `● ${errors?.room}`}</small></label>
                      <select className="form-select"
                        ref={input => (device.room.current = input)} >
                        {Object.keys(sites.ROOM).map((key, idx) => (
                          <option key={idx} value={key} > {sites.ROOM[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3" >
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Secteur <small className="text-danger" >{errors?.sector && `● ${errors?.sector}`}</small></label>
                      <select className="form-select"
                        ref={input => (device.sector.current = input)} >
                        {Object.keys(sites.SECTOR).map((key, idx) => (
                          <option key={idx} value={key} > {sites.SECTOR[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12" xl="6">
                <Row className="mt-3" >
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Étage <small className="text-danger" >{errors?.floor && `● ${errors?.floor}`}</small></label>
                      <select className="form-select"
                        ref={input => (device.floor.current = input)} >
                        {Object.keys(sites.FLOOR).map((key, idx) => (
                          <option key={idx} value={key} > {sites.FLOOR[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3" >
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Position <small className="text-danger" >{errors?.position && `● ${errors?.position}`}</small></label>
                      <select className="form-select"
                        ref={input => (device.position.current = input)} >
                        {Object.keys(sites.POSITION).map((key, idx) => (
                          <option key={idx} value={key} > {sites.POSITION[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Numéro de série du communicateur<small className="text-danger" >{errors?.serialNumberCommunicator && `● ${errors?.serialNumberCommunicator}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={device.serialNumberCommunicator}
                  />
                </div>
              </Col>
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Communication <small className="text-danger" >{errors?.communication && `● ${errors?.communication}`}</small></label>
                  <select className="form-select"
                    ref={input => (device.communication.current = input)} >
                    {Object.keys(sites.COMMUNICATION).map((key, idx) => (
                      <option key={idx} value={key} > {sites.COMMUNICATION[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg="12" xl="6" >
                <div className="mb-3">
                  <label className="fw-semibold">Date d'installation du Pentagone <small className="text-danger" >{errors?.installationDate && `● ${errors?.installationDate}`}</small></label>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="YYYY-MM-DD"
                    options={{
                      altInput: true,
                      altFormat: "Y-m-d",
                      dateFormat: "YYYY-MM-DD",
                      enableTime: false,
                    }}
                    value={new Date(installationDate)}
                    onChange={e => setInstallationDate(new Date(e).toISOString())}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12" className='mt-4'>
                <h4 className='card-title text-center text-white'>Secteurs pompiers</h4>
                <img src="/sector.png" className="mx-auto d-block" />
              </Col>
            </Row>

            <Row className="mt-4 mb-4">
              <Col md="12">
                <h4 className='card-title text-left text-white'>Risques de Fausse-alarme</h4>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmManipulation}
                    ref={device.falseAlarmManipulation} />
                  <label className="fw-semibold">Manipulation du Pentagone <small className="text-danger" >{errors?.falseAlarmManipulation && `● ${errors?.falseAlarmManipulation}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmExistingDetector}
                    ref={device.falseAlarmExistingDetector} />
                  <label className="fw-semibold">Avertisseur existant <small className="text-danger" >{errors?.falseAlarmExistingDetector && `● ${errors?.falseAlarmExistingDetector}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmBathroom}
                    ref={device.falseAlarmBathroom} />
                  <label className="fw-semibold">Salle-de-bain à -2m <small className="text-danger" >{errors?.falseAlarmBathroom && `● ${errors?.falseAlarmBathroom}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmCooker}
                    ref={device.falseAlarmCooker} />
                  <label className="fw-semibold">Cuisinière à -5m <small className="text-danger" >{errors?.falseAlarmCooker && `● ${errors?.falseAlarmCooker}`}</small></label>

                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmFirePlace}
                    ref={device.falseAlarmFirePlace} />
                  <label className="fw-semibold">Foyer à -5m <small className="text-danger" >{errors?.falseAlarmFirePlace && `● ${errors?.falseAlarmFirePlace}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmSmooking}
                    ref={device.falseAlarmSmooking} />
                  <label className="fw-semibold">Fumeur à l'intérieur <small className="text-danger" >{errors?.falseAlarmSmooking && `● ${errors?.falseAlarmSmooking}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmDust}
                    ref={device.falseAlarmDust} />
                  <label className="fw-semibold">Poussière importante <small className="text-danger" >{errors?.falseAlarmDust && `● ${errors?.falseAlarmDust}`}</small></label>

                </div>
              </Col>
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmFrequentCooking}
                    ref={device.falseAlarmFrequentCooking} />
                  <label className="fw-semibold">Friture / Cuisson fréquente <small className="text-danger" >{errors?.falseAlarmFrequentCooking && `● ${errors?.falseAlarmFrequentCooking}`}</small></label>

                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="6" xl="3" className="ps-5">
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={props.site?.device?.falseAlarmSpecialActivity}
                    ref={device.falseAlarmSpecialActivity} />
                  <label className="fw-semibold">Activité particulière <small className="text-danger" >{errors?.falseAlarmSpecialActivity && `● ${errors?.falseAlarmSpecialActivity}`}</small></label>

                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col >
  );
}



export default SiteDevice;