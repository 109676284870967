import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'

import Loader from './../../../components/Common/Loader'
import { centers } from './../../../actions/index'


const CenterPage = () => {
  const params = useParams(),
    history = useHistory(),
    [center, setCenter] = useState({}),
    [errors, setErrors] = useState({}),
    [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {

    // TODO: Error management
    if (params.id !== 'add') {
      let res = await centers.get(params.id);
      setCenter(res.data);
    }

    // Stop loading
    setIsLoading(false);
  }, [])


  let inputs = {}, address = {};
  const onSubmit = async e => {
    e.preventDefault();

    // Manage central data and address
    let data = { address: {} };
    for (const key in inputs) {
      if (['technicalManagerPhone', 'adminManagerPhone'].includes(key) && inputs[key].value.trim() != '') {
        data[key] = [inputs[key].value];
        continue;
      } else if (['automatedCallAllowed'].includes(key)) {
        data[key] = inputs[key].checked;
        continue;
      }

      data[key] = inputs[key].value;
    }
    for (const key in address) {
      data.address[key] = address[key].value;
    }

    // Reset error
    setErrors({})

    if (params.id === 'add') {
      return await onAdd(data);
    }
    return await onEdit(data);
  }

  const onAdd = async data => {
    let res = await centers.create(data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    history.push(`/admin/center/${res.data._id}`);
    setCenter(res.data);
  }

  const onEdit = async data => {
    let res = await centers.update(params.id, data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    setCenter(res.data);
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row className="mb-3" >
                    <Col lg={12} >
                      <h4 className="card-title">{params.id === 'add' ? 'Nouvelle centrale' : `Modifier ${center?.name}`}</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Nom <small className="text-danger" >{errors.name && `● ${errors.name}`}</small></Label>
                        <input className={`form-control ${errors.name && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.name}
                          ref={input => (inputs.name = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Langue <small className="text-danger" >{errors.language && `● ${errors.language}`}</small></Label>
                        <select className="form-select"
                          defaultValue={center?.language}
                          ref={input => (inputs.language = input)}
                        >
                          {Object.keys(centers.LANGUAGES).map((key, idx) => (
                            <option key={idx} value={key} > {centers.LANGUAGES[key]} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone d'urgence <small className="text-danger" >{errors.emergencyPhone && `● ${errors.emergencyPhone}`}</small></Label>
                        <input className={`form-control ${errors.emergencyPhone && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.emergencyPhone}
                          ref={input => (inputs.emergencyPhone = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Type de centrale <small className="text-danger" >{errors.type && `● ${errors.type}`}</small></Label>
                        <select className="form-select"
                          defaultValue={center?.type}
                          ref={input => (inputs.type = input)}
                        >
                          {Object.keys(centers.CENTRAL_TYPE).map((key, idx) => (
                            <option key={idx} value={key} > {centers.CENTRAL_TYPE[key]} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Responsable technique <small className="text-danger" >{errors.technicalManager && `● ${errors.technicalManager}`}</small></Label>
                        <input className={`form-control ${errors.technicalManager && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.technicalManager}
                          ref={input => (inputs.technicalManager = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone du responsable technique <small className="text-danger" >{errors.technicalManagerPhone && `● ${errors.technicalManagerPhone}`}</small></Label>
                        <input className={`form-control ${errors.technicalManagerPhone && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.technicalManagerPhone}
                          ref={input => (inputs.technicalManagerPhone = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Responsable administratif <small className="text-danger" >{errors.adminManager && `● ${errors.adminManager}`}</small></Label>
                        <input className={`form-control ${errors.adminManager && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.adminManager}
                          ref={input => (inputs.adminManager = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone du responsable administratif <small className="text-danger" >{errors.adminManagerPhone && `● ${errors.adminManagerPhone}`}</small></Label>
                        <input className={`form-control ${errors.adminManagerPhone && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.adminManagerPhone}
                          ref={input => (inputs.adminManagerPhone = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Adresse <small className="text-danger" >{errors['address.street'] && `● ${errors['address.street']}`}</small></Label>
                        <input className={`form-control ${errors['address.street'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.street}
                          ref={input => (address.street = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Appartement <small className="text-danger" >{errors['address.appt'] && `● ${errors['address.appt']}`}</small></Label>
                        <input className={`form-control ${errors['address.appt'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.appt}
                          ref={input => (address.appt = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Ville <small className="text-danger" >{errors['address.city'] && `● ${errors['address.city']}`}</small></Label>
                        <input className={`form-control ${errors['address.city'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.city}
                          ref={input => (address.city = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Code postal <small className="text-danger" >{errors['address.postalCode'] && `● ${errors['address.postalCode']}`}</small></Label>
                        <input className={`form-control ${errors['address.postalCode'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.postalCode}
                          ref={input => (address.postalCode = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Province <small className="text-danger" >{errors['address.province'] && `● ${errors['address.province']}`}</small></Label>
                        <input className={`form-control ${errors['address.province'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.province}
                          ref={input => (address.province = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Pays <small className="text-danger" >{errors['address.country'] && `● ${errors['address.country']}`}</small></Label>
                        <input className={`form-control ${errors['address.country'] && 'is-invalid'}`}
                          type="text"
                          defaultValue={center?.address?.country}
                          ref={input => (address.country = input)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3" >
                    <Col lg={6} >
                      <div className="form-check form-switch form-switch-lg" >
                        <input className="form-check-input" type="checkbox"
                          defaultChecked={center.automatedCallAllowed}
                          ref={input => inputs.automatedCallAllowed = input} />
                        <label className="fw-semibold pt-1">Appel automatisé incendia permise</label>
                      </div>
                    </Col>
                    <Col lg={6} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-success waves-effect waves-light" >
                        {params.id === 'add' ? 'Add' : 'Save'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default CenterPage;
