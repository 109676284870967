import React, { useRef, useState, useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { core } from './../../actions/index'


const Sidebar = () => {
  const ref = useRef(),
    location = useLocation(),
    [user, setUser] = useState({});


  useEffect(() => {
    let ref = core.sub('me', user => {
      setUser(user);
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  const isActive = prefix => {
    return (location.pathname.startsWith(prefix)) ? 'active' : '';
  }

  let displayAdminMenu = () => {
    return <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title mt-3">Production</li>
      <li>
        <Link to="/admin/serialnumbers" className={isActive('/admin/serialnumber')}>
          <i className='fas fa-qrcode'></i>
          <span key="t-dashboard">Générer No Série</span>
        </Link>
        <Link to="/quality-assurance/production" className={isActive('/quality-assurance/production')}>
          <i className='fas fa-microchip'></i>
          <span key="t-dashboard">PCB</span>
        </Link>
        <Link to="/quality-assurance/audit" className={isActive('/quality-assurance/audit')} >
          <i className='fas fa-history'></i>
          <span key="t-dashboard">Audit</span>
        </Link>
        <Link to="/quality-assurance/report" className={isActive('/quality-assurance/report')} >
          <i className='fas fa-file-alt'></i>
          <span key="t-dashboard">Rapport</span>
        </Link>
      </li>

      <li className="menu-title mt-3">Clients</li>
      <li>
        <Link to="/technician/sites" className={isActive('/technician/site')} >
          <i className='fas fa-map-marker-alt'></i>
          <span key="t-dashboard">Sites</span>
        </Link>
      </li>

      <li className="menu-title mt-3">Service</li>
      <li>
        <Link to="/technician/alerts" className={isActive('/technician/alert')}>
          <i className='fas fa-exclamation-triangle'></i>
          <span key="t-dashboard">Alertes</span>
        </Link>
        <Link to="/technician/devices" className={isActive('/technician/device')}>
          <svg xmlns="http://www.w3.org/2000/svg" className="pentagone-icon menu" viewBox="0 0 16 16"> <path d="m8 1.288 6.842 5.56L12.267 15H3.733L1.158 6.847 8 1.288zM16 6.5 8 0 0 6.5 3 16h10l3-9.5z" /> </svg> Pentagones
        </Link>
        <Link to="/technician/analytic" className={isActive('/technician/analytic')} >
          <i className='fas fa-chart-line'></i>
          <span key="t-dashboard">Analyse</span>
        </Link>
      </li>

      <li className="menu-title mt-3">Centrales</li>
      <li>
        <Link to="/operator/alerts" className={isActive('/operator/alert')}>
          <i className='fas fa-exclamation-triangle'></i>
          <span key="t-dashboard">Alarmes récentes</span>
        </Link>
        <Link to="/admin/centers" className={isActive('/admin/center')}>
          <i className='fas fa-headset'></i>
          <span key="t-dashboard">Centrales</span>
        </Link>
        <Link to="/admin/municipalities" className={isActive('/admin/municipalit')}>
          <i className='fas fa-truck'></i>
          <span key="t-dashboard">Municipalités</span>
        </Link>
      </li>

      <li className="menu-title mt-3">Paramètres</li>
      <li>
        <Link to="/admin/users" className={isActive('/admin/user')}>
          <i className='fas fa-user-shield'></i>
          <span key="t-dashboard">Utilisateurs</span>
        </Link>
        <Link to="/admin/events" className={isActive('/admin/event')} >
          <i className='fas fa-database'></i>
          <span key="t-dashboard">Évènements</span>
        </Link>
      </li>
    </ul>
  }



  let displayProducerMenu = () => {
    return <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title mt-3">Quality assurance</li>
      <li>
        <Link to="/quality-assurance/production">
          <i className='fas fa-microchip'></i>
          <span key="t-dashboard">Production</span>
        </Link>
      </li>
    </ul>
  }

  let displayOperator = () => {
    return <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title mt-3">Centrales</li>
      <li>
        <Link to="/operator/alerts" className={isActive('/operator/alert')}>
          <i className='fas fa-exclamation-triangle'></i>
          <span key="t-dashboard">Alarmes récentes</span>
        </Link>
      </li>
    </ul>
  }

  let displayRiscMenu = () => {
    return <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title mt-3">Clients</li>
      <li>
        <Link to="/technician/sites" className={isActive('/technician/site')} >
          <i className='fas fa-map-marker-alt'></i>
          <span key="t-dashboard">Sites</span>
        </Link>
      </li>
    </ul>
  }

  // TODO: Hide based on user role
  return (
    <div className="vertical-menu">
      <div className="h-100">
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            {user?.role === 'risc' && displayRiscMenu()}
            {user?.role === 'producer' && displayProducerMenu()}
            {user?.role === 'operator' && displayOperator()}
            {user?.role === 'admin' && displayAdminMenu()}
          </div>
        </SimpleBar>
      </div>
      <div className="sidebar-background"></div>
    </div>
  );
}

export default Sidebar;