import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import { devices } from './../../../../actions/index'

import Loader from './../../../../components/Common/Loader'
import Pagination from './../../../../components/Pagination/pagination.component'

const DISPLSAY_PER_PAGE = 25;

const DevicesPage = () => {
  const params = useParams(),
    history = useHistory(),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' }),
    [data, setData] = useState({ count: 0, page: 1, devices: [] });


  useEffect(async () => {
    let search = null, page = 1;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    getDevices(page, search);
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/technician/devices/${query}`);
    getDevices(page, search);
  }

  const getDevices = async (page, search) => {

    // Start loading
    setIsLoading(true);

    let res = await devices.getDevices({
      search: search,
      page: page,
      length: DISPLSAY_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, devices: res.data.devices, page: page });

    // Start loading
    setIsLoading(false);
  }

  let displayDevice = (device, idx) => {

    let site = 'N/A', silent = <span className="badge bg-success me-1">Non</span>;
    if (device.sites?.length > 0) {
      site = <Link to={`/technician/site/${device.siteId}/owner`}>
        {device.sites[0].familyId}
      </Link>

      if (device.sites[0].silent) {
        silent = <span className="badge bg-danger me-1">Oui</span>;
      }
    }

    return <tr key={idx} >
      <th scope="row">{device.serialNumber}</th>
      <td>{site}</td>
      <th style={{ width: '100px' }} >
        {silent}
      </th>
      <td className="text-right">
        <Link to={`/technician/device/${device._id}`}>
          <button className="btn btn-primary btn-sm" >
            View
          </button>
        </Link>
      </td>
    </tr>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}


      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Device management</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, null) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Family ID</th>
                        <th>Silencieux</th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.devices.map((device, idx) => (
                        displayDevice(device, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="4" className='text-center' >Aucune donnée n'est disponible...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default DevicesPage;
