import React, { useState, useRef, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Table
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'

import Loader from './../../../../components/Common/Loader'
import { users, alerts, core } from './../../../../actions/index'


const AlertPage = () => {
  const params = useParams(),
    history = useHistory(),
    [userList, setUserList] = useState({}),
    [comments, setComments] = useState({}),
    [alert, setAlert] = useState({}),
    [errors, setErrors] = useState({}),
    [isLoading, setIsLoading] = useState(true),
    inputs = {
      assigned: useRef(null),
      status: useRef(null),
      comment: useRef(null)
    },
    [myTimezone, setMyTimezone] = useState('');

  useEffect(async () => {
    // TODO: Error management
    let res = await users.getUsers({
      search: null,
      page: 1,
      length: 100
    });
    setUserList(res.data.users);

    core.sub('me', async user => {
      setMyTimezone(user.timezone);

      res = await alerts.getAlert(params.id);
      setAlert(res.data);

      res = await alerts.getComments(params.id);
      setComments(res.data);

      // Stop loading
      setIsLoading(false);
    });
  }, [])

  const getPriority = (priority) => {
    switch (priority) {
      case 'warning':
        return <span className="badge bg-info me-1">AVERTISSEMENT</span>;
      case 'error':
        return <span className="badge bg-secondary me-1">ERREUR</span>;
      case 'major':
        return <span className="badge bg-warning me-1">MAJEURE</span>;
      case 'critical':
        return <span className="badge bg-danger me-1">CRITIQUE</span>;
      default:
        return <span className="badge bg-success me-1">LOG</span>;
    }
  }

  const getState = (state) => {
    switch (state) {
      case 'pending_trig':
        return <span className="badge bg-warning me-1">DÉCLENCHEMENT EN ATTENTE</span>;
      case 'pending_ok':
        return <span className="badge bg-warning me-1">RESTAURATION EN ATTENTE</span>;
      case 'ok':
        return <span className="badge bg-success me-1">RESTAURÉE</span>;
      default:
        return <span className="badge bg-danger me-1">DÉCLENCHÉ</span>;
    }
  }

  const onSubmitManagement = async e => {
    e.preventDefault();

    alert.assigned = inputs.assigned.current?.value;
    alert.status = inputs.status.current?.value;
    if (alert.assigned == "false") alert.assigned = null;

    // TODO: Error management
    await alerts.updateAlert(alert._id, alert);
    window.location.reload(true);
  }

  const onRestore = async e => {
    e.preventDefault();

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir restaurer cette alerte?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, restaurer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {

        // TODO: Error management
        alert.state = 'ok';
        await alerts.updateAlert(alert._id, alert);
        window.location.reload(true);
      }
    })
  }

  const onSubmitComment = async e => {
    e.preventDefault();

    // TODO: Error management
    await alerts.addComment(alert._id, {
      comment: inputs.comment.current?.value
    });
    window.location.reload(true);
  }

  const displayComment = (comment, idx) => {
    return <Row key={idx} >
      <Col lg={12}>
        <hr />
        <Row>
          <Col md={6}>
            <small><strong>Utilisateur:</strong> {comment.userInfo[0].firstName} {comment.userInfo[0].lastName}</small>
          </Col>
          <Col md={6} className="text-right">
            <small><strong>Heure:</strong> {moment.tz(new Date(comment.createdAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}</small>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={12}>
            <p> {comment.comment} </p>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  return (
    <div className="page-content">

      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col md="5">
            <Card>
              <CardBody>
                <h4 className="card-title">Détails</h4>


                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <tbody>
                      <tr>
                        <th>Identifiant</th>
                        <td>
                          {alert?._id.substring(0, 8).toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <th>Règle</th>
                        <td>
                          {alert?.rule.substring(0, 8).toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <th>Titre</th>
                        <td>
                          {alert?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>
                          {alert?.description}
                        </td>
                      </tr>
                      <tr>
                        <th>Pentagone</th>
                        <td>
                          <Link to={`/technician/device/${alert?.serialNumber}/owner`}>
                            {alert?.serialNumber}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>Type</th>
                        <td>
                          {alert?.type.toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <th>Heure</th>
                        <td>
                          {moment.tz(new Date(alert?.triggedAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}
                        </td>
                      </tr>
                      <tr>
                        <th>Priorité</th>
                        <td>
                          {getPriority(alert?.priority)}
                        </td>
                      </tr>
                      <tr>
                        <th>État</th>
                        <td> {getState(alert?.state)} </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="7">
            <Card>
              <CardBody>
                <h4 className="card-title">Gestion</h4>

                <Form onSubmit={onSubmitManagement}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Responsable</Label>
                        <select className="form-select"
                          ref={input => (inputs.assigned.current = input)}
                          defaultValue={alert?.assigned} >
                          <option value={false} > Non attribué </option>
                          {Object.keys(userList).map((key, idx) => (
                            <option key={idx} value={userList[key]?._id} > {userList[key]?.firstName} {userList[key]?.lastName} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Status</Label>
                        <select className="form-select"
                          ref={input => (inputs.status.current = input)}
                          defaultValue={alert?.status} >
                          <option value="open">Ouvert</option>
                          <option value="ongoing">En cours</option>
                          <option value="closed">Fermé</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        onClick={e => onRestore(e)}
                        disabled={alert?.state === 'ok'}
                        className="me-3 btn btn-sm btn-warning waves-effect waves-light" >
                        Restaurer
                      </button>

                      <button
                        type="submit"
                        className="btn btn-sm btn-success waves-effect waves-light" >
                        Sauvegarder
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title">Commentaires</h4>

                {comments?.length > 0 && comments?.map((comment, idx) => (
                  displayComment(comment, idx)
                ))}

                <Form onSubmit={onSubmitComment}>
                  <Row className='mt-4'>
                    <Col lg={12}>
                      <div className="mb-3">
                        <textarea className="form-control"
                          type="text"
                          placeholder="Commentaire"
                          rows={5}
                          ref={inputs.comment}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success waves-effect waves-light" >
                        Ajouter
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default AlertPage;
