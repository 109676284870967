import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Input,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'

import Loader from './../../../components/Common/Loader'
import { events } from './../../../actions/index'

const EventPage = () => {
  const params = useParams(),
    history = useHistory(),
    [event, setEvent] = useState({}),
    [isLoading, setIsLoading] = useState(true),
    [errors, setErrors] = useState({});

  useEffect(async () => {

    // TODO: Error management
    if (params.id !== 'add') {
      let res = await events.getEvent(params.id);
      setEvent(res.data);
    }

    // Stop loading
    setIsLoading(false);
  }, [])

  // The data structure is a bit complex so we explicitly define it
  let inputs = {};
  let descs = {};
  let dvals = {};
  let sms = {};
  let dests = [];
  let notifs = [];

  const onSubmit = async e => {
    e.preventDefault();

    let data = {}
    for (const key in inputs) {
      data[key] = parseInt(inputs[key].value);
    }
    data["descriptions"] = {};
    for (const key in descs) {
      data["descriptions"][key] = descs[key].value;
    }
    data["datavalues"] = {};
    for (const key in dvals) {
      data["datavalues"][key] = dvals[key].value;
    }
    data["datavalues"]["shortmessages"] = {};
    for (const key in sms) {
      data["datavalues"]["shortmessages"][key] = sms[key].value;
    }

    data["notifications"] = [1];
    //data["notifications"][0] = 1;
    //for (let i = 0; i < notifs.length; i++) {
    //    data["notifications"][i] = notifs[i].value;
    //}
    data["destinations"] = [1];
    //data["destinations"][0] = 1;
    //for (let i = 0; i < dests.length; i++) {
    //    data["destinations"][i] = dests[i].value;
    //}

    console.log(data);

    // Reset error
    setErrors({})

    if (params.id === 'add') {
      return await onAdd(data);
    }
    return await onEdit(data);
  }

  const onAdd = async data => {
    let res = await events.onCreateEvent(data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    history.push(`/admin/event/${res.data._id}`);
    setEvent(res.data);
  }

  const onEdit = async data => {
    console.log(data);
    let res = await events.onEditEvent(params.id, data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    setEvent(res.data);
  }

  let eventCode = () => {
    let lblText = "Choose from an unused code";
    return <div className="mb-3">
      <label className="form-label">{lblText}</label>
      <select className="form-select">
      </select>
    </div>
  }

  let multiInputs = (typeDef, entries, values, valueType) => {
    if (entries === undefined) {
      return ''
    }

    return <Table className="table table-striped mb-0">
      <tbody>
        {entries.map((val, idx) => (
          <tr>
            <td>
              <input className="form-control"
                type="text"
                defaultValue={val}
                ref={input => (typeDef[val] = input)}
              />
            </td>
            <td>
              {valueType === "destinations" ?
                <input className="form-control"
                  type="text"
                  defaultValue={values.sims}
                  ref={input => (dvals.sims = input)}
                />
                :
                <>
                  <div>
                    <label className="form-label">French</label>
                    <input className="form-control"
                      type="text"
                      placeholder="french descriptions"
                      defaultValue={values.shortmessages.fr}
                      ref={input => (sms.fr = input)}
                    />
                  </div>
                  <div style={{ margin: '2em 0 0 0' }}>
                    <label className="form-label">English</label>
                    <input className="form-control"
                      type="text"
                      placeholder="english descriptions"
                      defaultValue={values.shortmessages.en}
                      ref={input => (sms.en = input)}
                    />
                  </div>
                </>
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  }

  return (
    <div className="page-content">

      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Form onSubmit={onSubmit}>
              <Row className="mt-3">
                <Col lg={2}>
                  <h4 className="card-title">{params.id !== 'add' ? `CODE #${event.code}` : eventCode()}</h4>
                </Col>
                <Col lg={10} className="text-right" >
                  <button
                    type="submit"
                    className="btn btn-success waves-effect waves-light" >
                    {params.id === 'add' ? 'Add' : 'Save'}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <Row>
                    <Card>
                      <CardBody>
                        <label className="form-label">Associated event code</label>
                        <input className="form-control"
                          type="text"
                          placeholder="associated code value"
                          defaultValue={event?.associatedEventCode}
                          ref={input => (inputs.associatedEventCode = input)}
                        />
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Card>
                      <CardBody>
                        <h4 className="card-title">Event descriptions</h4>
                        <div style={{ margin: '2em 0' }}>
                          <label className="form-label">French</label>
                          <input className="form-control"
                            type="text"
                            placeholder="french descriptions"
                            defaultValue={event?.descriptions.fr}
                            ref={input => (descs.fr = input)}
                          />
                        </div>
                        <div style={{ margin: '2em 0' }}>
                          <label className="form-label">English</label>
                          <input className="form-control"
                            type="text"
                            placeholder="english descriptions"
                            defaultValue={event?.descriptions.en}
                            ref={input => (descs.en = input)}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Card>
                    <CardBody>
                      <div>
                        <label className="form-label">Alert type</label>
                        <select className="form-select"
                          defaultValue={event?.datavalues.type}
                          ref={input => (dvals.type = input)} >
                          <option value="">LOG</option>
                          <option value="fire">Fire</option>
                          <option value="medical">Medical</option>
                          <option value="help">Help</option>
                        </select>
                      </div>
                      <div style={{ margin: '2em 0 0 0' }}>
                        <label className="form-label">Alert state</label>
                        <select className="form-select"
                          defaultValue={event?.datavalues.state}
                          ref={input => (dvals.state = input)} >
                          <option value="active">Escalate</option>
                          <option value="restore">Restore</option>
                        </select>
                      </div>
                      <div style={{ margin: '2em 0 0 0' }}>
                        <label className="form-label">Priority</label>
                        <select className="form-select"
                          defaultValue={event?.priority}
                          ref={input => (inputs.priority = input)} >
                          <option value="1">High - 1</option>
                          <option value="2">Level - 2</option>
                          <option value="3">Level - 3</option>
                          <option value="4">Low - 4</option>
                        </select>
                      </div>
                      <div style={{ margin: '2em 0 0 0' }}>
                        <label className="form-label">Route ID</label>
                        <input className="form-control"
                          type="text"
                          placeholder="route ..."
                          defaultValue={event?.event}
                          ref={input => (inputs.event = input)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4 className="card-title">External Links</h4>
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <td>Emergency services:</td>
                              <td>{multiInputs(dests, event.destinations, event.datavalues, "destinations")}</td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <td>Notification services:</td>
                              <td>{multiInputs(notifs, event.notifications, event.datavalues, "notifications")}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}
export default EventPage;