import React, { useState, useEffect } from 'react'
import { Table, Modal } from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'

import Pagination from '../Pagination/pagination.component'

const MAP_PARAM = {
  adpdbbaseline: 'ADPDB Base Line',
  adpdbbaselineshift: 'ADPDB Base Line Shift',
  adpdbrangetestcurrent: 'ADPDB Range Test Current',
  adpdbrangetestproduced: 'ADPDB Range Test Produced',
  adpdrbaseline: 'ADPDR Base Line',
  adpdrblineshiftprod: 'ADPDR Base Line Shift Prod',
  adpdrrangetestcurrent: 'ADPDR Range Test Current',
  adpdrrangetestproduced: 'ADPDR Range Test Produced',
  alrtenvironmentpaused: 'alrtenvironmentpaused',
  alrtfreeze: 'Alert Freeze',
  alrthighhumidity: 'Alert High Humidity',
  alrthightemperature: 'Alert High Temperature',
  alrtlowtemperature: 'Alert Low Temperature',
  alrttamper: 'Alert Tamper',
  alrttamperpaused: 'Alert Tamper Paused',
  carbonmonoxideppm: 'Carbon Monoxide PPM',
  codeDetails: 'Code Details',
  cpuinit: 'CPU Init',
  createdAt: 'Created At',
  epoch: 'Device Time',
  evacuationexercice: 'Evacuation Exercice',
  eventcode1: 'Event Code 1',
  eventcode2: 'Event Code 2',
  eventpriority: 'Event Priority',
  flameflickerpercent: 'Flame Flicker Percent',
  flashfreespace: 'Flash Free Space',
  floor: 'Floor',
  frequencyrf: 'Frequency RF',
  hertzflicker: 'Hertz Flicker',
  humiditypercent: 'Humidity Percent',
  hushbutton: 'Hush Button',
  hushbycentral: 'Hush By Central',
  hushbyfirefighter: 'Hush By Fire Fighter',
  isacpresent: 'ISAC Present',
  isonac: 'ISONAC',
  isprimary: 'Is Primary',
  language1: 'Language 1',
  language1used: 'Language 1 Used',
  language2: 'Language 2',
  language2used: 'Language 2 Used',
  lumen: 'Lumen',
  maydayindisabled: 'Mayday In Disabled',
  maydayoutdisabled: 'Mayday Out Disabled',
  maydayoutofservice: 'Mayday Out Of Service',
  mobileinstalled: 'Mobile Installed',
  nicacarbonmonoxyde: 'NICA Carbon Monoxyde',
  nicageneral: 'NICA General',
  nicaheat: 'NICA Heat',
  nicainterconnect: 'NICA Interconnect',
  nicasmoke: 'NICA Smoke',
  nicat3: 'NICA T3',
  pirtotalt0t1: 'PIR Total T0T1',
  pirtotalt1t15: 'PIR Total T1T15',
  pressurelevel: 'Pressure Level',
  remainingbatteryinmonth: 'Remaining Battery In Month',
  remaininglifeinmonth: 'Remaining Life In Month',
  rfresynchronizationpercent: 'RF Resynchronization Percent',
  rfretransmitpercent: 'RF Retransmit Percent',
  room: 'Room',
  sector: 'Sector',
  senderserialnumber: 'Sender Serial Number',
  siteId: 'Site ID',
  smokepercent: 'Smoke Percent',
  sourceserialnumber: 'Source Serial Number',
  stateeventmanager: 'State Event Manager',
  statemachine4: 'State Machine 4',
  statemenu: 'State Menu',
  statesmoke: 'State Smoke',
  temperaturelevel: 'Temperature Level',
  testauto: 'Test Auto',
  testautotrouble: 'Test Autot Rouble',
  testmanualcentral: 'Test Manual Central',
  testmanualcentraltrouble: 'Test Manual Central Trouble',
  testmanualquick: 'Test Manual Quick',
  trbl120v: ' TRBL 120V',
  trblbaselineoutofrange: 'TRBL Base Line Out Of Range',
  trblbuzzer: 'TRBL Buzzer',
  trblcarbonmonoxyde: 'TRBL Carbonmonoxyde',
  trblcarbonmonoxydeselftest: 'TRBL Carbonmonoxyde Selftest',
  trbldsp: 'TRBL DSP',
  trblendoflife: 'TRBL End Of Life',
  trblfan: 'TRBL Fan',
  trblfilter: 'TRBL Filter',
  trblhighhumidity: 'TRBL High Humidity',
  trbli2c: 'TRBL I2C',
  trblinterconnect: 'TRBL Interconnect',
  trbllogpartitionfull: 'TRBL Log Partition Full',
  trbllowbattery: 'TRBL Low Battery',
  trblntcdsptemperature: 'TRBL NTCDSP Temperature',
  trblrgb: 'TRBL RGB',
  trblsiren: 'TRBL Siren',
  trbltemperatureabove125: 'TRBL Temperature Above 125',
  trbltemperaturebelow40: 'TRBL Temperature Below 40',
  updatebackoutfailed: 'Update Backout Failed',
  updatebackoutsuccessful: 'Update Backout Successful',
  updatedAt: 'Updated At',
  updatereceived: 'Update Received',
  updateslot1successful: 'Update Slot 1 Successful',
  updateslot2successful: 'Update Slot 2 Successful',
  vocalidincomplete: 'Vocal ID Incomplete',
  vocalidmissing: 'Vocal ID Missing',
  voltageloaded: 'Voltage Loaded',
  wallhush: 'Wall Hush',
  wifiinstalled: 'WIFI Installed',
  wirepasinstalled: 'WirePas Installed',
  xtmodel: 'XT Model',
  __v: '_v',
  _id: 'ID'
}

const EventsTable = props => {
  const [modal, setModal] = useState({ idx: null, event: null });

  const toggleDetails = (e, idx) => {
    e.preventDefault();

    let event = null
    if (idx !== null && props.data.events[idx]) {
      event = props.data.events[idx];
    } else {
      idx = null;
    }
    setModal({ idx: idx, event: event });
  }

  let displayEvent = (event, idx) => {
    var priority = <span className="badge bg-success me-1">INFO</span>;
    switch (event.eventpriority) {
      case 1:
        priority = <span className="badge bg-danger me-1">HAUTE</span>;
        break;
      case 2:
        priority = <span className="badge bg-warning me-1">MOYENNE</span>;
        break;
      case 3:
        priority = <span className="badge bg-info me-1">BASE</span>;
        break;
    }

    let description = event?.codeDetails[0]?.descriptions?.en || 'N/A';
    return <tr key={idx} >
      <th scope="row">
        {event._id.substring(0, 8).toUpperCase()}</th>
      <td>{event.epoch}</td>
      <td>{description}</td>
      <td>
        <Link to={`/technician/device/${event.sourceserialnumber}`} >
          {event.sourceserialnumber}
        </Link>
      </td>
      <td>
        <Link to={`/technician/device/${event.senderserialnumber}`} >
          {event.senderserialnumber}
        </Link>
      </td>
      <td>{priority}</td>
      <td>{event.createdAt}</td>
      <td className="text-right">
        <Link to="#" onClick={e => { toggleDetails(e, idx) }} >
          <button className="btn btn-primary btn-sm" >
          Détails
          </button>
        </Link>
      </td>
    </tr>;
  }

  return (
    <div className="table-responsive">
      <Table className="table table-striped mb-0">
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ width: '250px' }} >
              <Link to="#" onClick={() => { props.onChange(null, { epoch: props.data.sort.hasOwnProperty('epoch') ? props.data.sort.epoch * -1 : -1 }) }} >
              Heure Pentagone
              </Link>
            </th>
            <th>Description</th>
            <th>Source</th>
            <th>Expéditeur</th>
            <th style={{ width: '100px' }} >Priorité</th>
            <th style={{ width: '250px' }} >
              <Link to="#" onClick={() => { props.onChange(null, { createdAt: props.data.sort.hasOwnProperty('createdAt') ? props.data.sort.createdAt * -1 : -1 }) }} >
              Créé
              </Link>
            </th>
            <th style={{ width: '100px' }} className="text-right" >Action</th>
          </tr>
        </thead>
        <tbody>

          {props.data.events.map((event, idx) => (
            displayEvent(event, idx)
          ))}

          {props.data.count === 0 && <tr>
            <td colSpan="8" className='text-center' >Aucune donnée n'est disponible...</td>
          </tr>}
        </tbody>
      </Table>

      <Pagination data={props.data} onChange={props.onChange} />

      <Modal
        size="xl"
        isOpen={modal.idx !== null}
        toggle={e => toggleDetails(e, null)}
        scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Event Details - {modal.event?._id.toUpperCase()}
          </h5>
          <button
            type="button"
            onClick={e => toggleDetails(e, null)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body modal-dialog-scrollable">

          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Valeur</th>
                <th>Paramètre</th>
                <th>Valeur</th>
              </tr>
            </thead>
            <tbody>
              {modal?.event && [...Array(Math.ceil(Object.keys(modal.event).length / 2))].map((x, i) => {
                let fields = Object.keys(modal.event), idx = i * 2;
                let a = fields[idx], b = fields[idx + 1];

                let formatParam = (p) => {
                  return MAP_PARAM[p] ? MAP_PARAM[p] : p;
                }

                let formatValue = (v) => {
                  if (typeof v === 'number' || typeof v === 'string') {
                    return v;
                  } else if (typeof v === 'boolean') {
                    return v === false ? 'False' : 'True'
                  }
                  return 'N/A';
                }

                return <tr key={i}>
                  <th>{formatParam(a)}</th>
                  <td>{formatValue(modal.event[a])}</td>
                  <th>{formatParam(b)}</th>
                  <td>{formatValue(modal.event[b])}</td>
                </tr>
              }
              )}
            </tbody>
          </Table>
        </div>
      </Modal>
    </div>
  );
};

export default EventsTable;
