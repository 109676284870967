import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import { production } from './../../../actions/index'

import Pagination from './../../../components/Pagination/pagination.component'
import SweetAlert from 'react-bootstrap-sweetalert'


const DISPLSAY_PER_PAGE = 25;

const AuditPage = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, productions: [] }),
    [deleteAlert, setDeleteAlert] = useState(null),
    [confirmationAlert, setConfirmationAlert] = useState(null);


  useEffect(async () => {
    let search = null, page = 1;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    // Set search input's default value
    inputs.search.value = search;

    getProductions(page, search);
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/quality-assurance/audit/${query}`);
    getProductions(page, search);
  }

  const getProductions = async (page, search) => {
    let res = await production.getProductions({
      search: search,
      page: page,
      length: DISPLSAY_PER_PAGE
    });

    // TODO: Error management
    setData({ count: res.data.count, productions: res.data.productions, page: page });
  }

  const deleteProcedure = async id => {
    await production.deleteProcedure(id);

    onChange(data.page, null);
  }

  let displayProd = (prod, idx) => {
    let status = <span className="badge bg-warning me-1">IN PROGRESS</span>;
    if (prod.status === 'failed') {
      status = <span className="badge bg-danger me-1">FAILED</span>;
    } else if (prod.status === 'passed') {
      status = <span className="badge bg-success me-1">PASSED</span>;
    }

    return <tr key={idx} >
      <th scope="row">{prod.serialNumber}</th>
      <td>{prod.procedure}</td>
      <td>{prod?.user?.email || 'N/A'}</td>
      <td>{status}</td>
      <td className="text-right">
        <button className="btn btn-danger btn-sm me-2" onClick={() => setDeleteAlert({id: prod._id}) } >
          Delete
        </button>
        <Link to={`/quality-assurance/production/${prod.procedure}/${prod._id}/summary`}>
          <button className="btn btn-primary btn-sm" >
            View
          </button>
        </Link>
      </td>
    </tr>;
  }

  return (
    <div className="page-content">

      {deleteAlert ? (
        <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
                deleteProcedure(deleteAlert.id)
                setDeleteAlert(null)
                setConfirmationAlert({
                  title: 'Deleted',
                  description: 'The requested row has been deleted successfully.'
                })
                // TODO: Error management
            }}
            onCancel={() => {
              setDeleteAlert(null)
              setConfirmationAlert({
                title: 'Cancelled',
                description: 'The row has not been deleted.'
              })
            }}
        >
            You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {confirmationAlert ? (
          <SweetAlert
              success
              title={confirmationAlert.title}
              onConfirm={() => {
                  setConfirmationAlert(null)
              }}
          >
              {confirmationAlert.description}
          </SweetAlert>
      ) : null}

      <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Audit</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, null) }} className="app-search d-none d-lg-block">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Procedure</th>
                        <th>Executed By</th>
                        <th>Status</th>
                        <th style={{ width: '150px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.productions.map((prod, idx) => (
                        displayProd(prod, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="5" className='text-center' >Aucune donnée n'est disponible...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AuditPage;
