import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import { production } from './../../../actions/index'

import Procedures from './procedures.data'
import ReactApexChart from 'react-apexcharts'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'

var options = {

  chart: {
    height: 350,
    type: 'scatter',
    toolbar: {
      show: !1,
    },
    zoom: {
      enabled: !0,
      type: 'xy',
    },
  },
  xaxis: {
    tickAmount: 6,
    labels: {
      formatter: function (val) {
        return parseFloat(val).toFixed(1);
      },
    },
  },
  yaxis: {
    tickAmount: 6,
  }
};

const ReportPage = () => {
  const params = useParams(),
    history = useHistory(),
    [procedure, setProcedure] = useState('None'),
    [from, setFrom] = useState(''),
    [to, setTo] = useState(''),
    [stats, setStats] = useState({
      passed: [],
      failed: [],
      inProgress: [],
      retry: [],
      steps: {}
    });

  useEffect(async () => {
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        setProcedure(decoded.procedure);
        setFrom(decoded.from);
        setTo(decoded.to);

        delete decoded.iat;
        getProductionStats(decoded);
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }
  }, [])

  const onRun = async e => {
    e.preventDefault();
    let data = {
      procedure: procedure,
      from: from,
      to: to
    };
    let query = JWT.sign(data, 'WHAT_EVER_AS_IF_IT_BOTHERS');

    history.push(`/quality-assurance/report/${query}`);
    getProductionStats(data);
  }

  const getProductionStats = async payload => {
    if (!payload) return ;
    payload.from = payload.from.split('T')[0];
    payload.to = payload.to.split('T')[0];
    let res = await production.getReport(payload);

    // TODO: Error management
    if (!res.errors) {

       // Generate stats
      let passed = [], inProgress = [], failed = [], retry = [], steps = {};
      for (const item of res.data) {

        // Steps stats
        for (const step of item?.tests) {
          if (step.name === 'skipped') {
            continue;
          }

          // First iteration
          if (!steps[step.name]) {
            steps[step.name] = []
          }

          // Add stats
          steps[step.name].push(step);
        }

        // Retry?
        if ( passed.find(o => o.serialNumber === item.serialNumber) ||
          failed.find(o => o.serialNumber === item.serialNumber) ||
          inProgress.find(o => o.serialNumber === item.serialNumber) ) {

          retry.push(item);
          continue;
        }

        // Overall stats
        if (item.status === 'passed') {
          passed.push(item);
        } else if (item.status === 'failed') {
          failed.push(item);
        } else if (item.status === 'inProgress') {
          inProgress.push(item);
        }
      };

      setStats({
        passed: passed,
        inProgress: inProgress,
        failed: failed,
        retry: retry,
        steps: steps
      });
    }
  }

  let displayUnit = (data, idx) => {
    return <tr key={idx} >
      <th scope="row">{data.serialNumber}</th>
      <td>{data?.user?.email || 'N/A'}</td>
      <td>{data?.createdAt.split('T')[0]}</td>
      <td className="text-right">
        <Link to={`/quality-assurance/production/${data.procedure}/${data._id}/summary`}>
          <button className="btn btn-primary btn-sm" >
            View
          </button>
        </Link>
      </td>
    </tr>;
  }

  let displayProcedure = (data, idx) => {
    if (!data.distributionGroupBy) {
      return ;
    }

    let mean = 0, min = 0, max = 0, passed = 0, failed = 0, passedSerie = [], failedSerie = [];
    if (stats?.steps && stats?.steps[data.name]) {

      min = Number.POSITIVE_INFINITY;
      max = Number.NEGATIVE_INFINITY;
      for (const i of stats.steps[data.name]) {

        // Mean, min, max
        mean += i.value;
        if (i.value < min) min = i.value;
        if (i.value > max) max = i.value;


        // Generate graph data
        let groupId = Math.floor(i.value / data.distributionGroupBy);
        if (groupId > passedSerie.length - 1) {
          for (let j = passedSerie.length - 1; j < groupId; j++) {
            passedSerie.push([passedSerie.length * data.distributionGroupBy, null]);
            failedSerie.push([failedSerie.length * data.distributionGroupBy, null]);
          }
        }

        // Pass failed stats
        if (i.status === 'passed') {
          passed++;
          passedSerie[groupId][1]++;
        }
        if (i.status === 'failed' && i.value != null) {
          failed++;
          failedSerie[groupId][1]++;
        }
      }

      mean /= stats.steps[data.name].length;
    }


    return <Row key={idx} >
      <Col lg={6} >
        <Card>
          <CardBody>
            <Row>
              <Col lg={12} >
                <h5 className="mb-1"> {data.title}</h5>
                <p className="text-muted">{data.description}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6}>
                  <p className="text-muted mb-1">Expected</p>
                  <h5 className="mt-0">{data.expected}</h5>
              </Col>

              <Col lg={6}>
                  <p className="text-muted mb-1">Mean</p>
                  <h5 className="mt-0">{mean.toFixed(2)}</h5>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col lg={6}>
                  <p className="text-muted mb-1">Minimum</p>
                  <h5 className="mt-0">{(min || 0).toFixed(2)}</h5>
              </Col>

              <Col lg={6}>
                  <p className="text-muted mb-1">Maximum</p>
                  <h5 className="mt-0">{max.toFixed(2)}</h5>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col lg={6}>
                  <p className="text-muted mb-1">Passed</p>
                  <h5 className="mt-0">{passed} <span className="badge badge-soft-success pcnt-value">{(passed / (passed + failed) * 100 || 0).toFixed(2)}%</span></h5>
              </Col>

              <Col lg={6}>
                  <p className="text-muted mb-1">Failed</p>
                  <h5 className="mt-0">{failed} <span className="badge badge-soft-danger pcnt-value">{(failed / (passed + failed) * 100 || 0).toFixed(2)}%</span></h5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6} >
        <Card>
          <CardBody>
            <ReactApexChart
              options={options}
              series={[
                {
                  name: 'PASSED',
                  type: 'column',
                  color: '#2cb57e',
                  data: passedSerie,
                },
                {
                  name: 'FAILED',
                  type: 'column',
                  color: '#C61F4C',
                  data: failedSerie,
                }
              ]}
              type="scatter"
              height="275"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Report</h4>
                <Form onSubmit={onRun}>
                  <Row className="mt-3" >
                    <Col lg={4} >
                      <div className="mb-3">
                        <label className="form-label">Procedure</label>
                        <select className="form-select"
                          value={procedure}
                          onChange={(e) => setProcedure(e.target.value)}
                        >
                          <option value="None">Select a procedure</option>

                          {Object.keys(Procedures).map((key, idx) => (
                            <option value={key} key={idx} >{Procedures[key].title}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">From</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="YYYY-MM-DD"
                          options={{
                            altInput: true,
                            altFormat: "Y-m-d",
                            dateFormat: "YYYY-MM-DD",
                          }}
                          value={new Date(from)}
                          onChange={e => setFrom(new Date(e).toISOString()) }
                        />
                      </div>
                    </Col>
                    <Col lg={4} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">To</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="YYYY-MM-DD"
                          options={{
                            altInput: true,
                            altFormat: "Y-m-d",
                            dateFormat: "YYYY-MM-DD"
                          }}

                          value={new Date(to)}
                          onChange={e => setTo(new Date(e).toISOString()) }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success waves-effect waves-light" >
                        Run query
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={3} >
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mt-2">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className="fas fa-check text-success"></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold">Passed</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value"> {stats?.passed?.length} </span>
                    <span className="badge badge-soft-success pcnt-value">{(stats?.passed?.length / (stats?.passed?.length + stats?.failed?.length + stats?.inProgress?.length) * 100 || 0).toFixed(2)}%</span>
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} >
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mt-2">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className="fas fa-times text-danger"></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold">Failed</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value"> {stats?.failed?.length} </span>
                    <span className="badge badge-soft-danger pcnt-value">{(stats?.failed?.length / (stats?.passed?.length + stats?.failed?.length + stats?.inProgress?.length) * 100 || 0).toFixed(2)}%</span>
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} >
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mt-2">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className="fas fa-clock text-info"></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold">In Progress</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value"> {stats?.inProgress?.length} </span>
                    <span className="badge badge-soft-info pcnt-value">{(stats?.inProgress?.length / (stats?.passed?.length + stats?.failed?.length + stats?.inProgress?.length) * 100 || 0).toFixed(2)}%</span>
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} >
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mt-2">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className="fas fa-redo text-warning"></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold">Retry</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value"> {stats?.retry?.length} </span>
                    <span className="badge badge-soft-warning pcnt-value">{(stats?.retry?.length / (stats?.passed?.length + stats?.failed?.length + stats?.inProgress?.length) * 100 || 0).toFixed(2)}%</span>
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {Procedures[procedure]?.steps.map((key, idx) => (
          displayProcedure(key, idx)
        ))}

        <Row className="mt-3" >
          <Col lg={6} >
            <Card>
              <CardBody>
              <h5 className="mb-1 text-center text-success"> PASSED</h5>

              <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Executed By</th>
                        <th>Created</th>
                        <th style={{ width: '75px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.passed?.map((prod, idx) => (
                        displayUnit(prod, idx)
                      ))}

                      {stats.passed?.length === 0 && <tr>
                        <td colSpan="4" className='text-center text-muted' >No passed device...</td>
                      </tr>}
                    </tbody>
                  </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} >
            <Card>
              <CardBody>
              <h5 className="mb-1 text-center text-danger"> FAILED</h5>

              <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Executed By</th>
                        <th>Created</th>
                        <th style={{ width: '75px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.failed?.map((prod, idx) => (
                        displayUnit(prod, idx)
                      ))}

                      {stats.failed?.length === 0 && <tr>
                        <td colSpan="4" className='text-center text-muted' >No failed device...</td>
                      </tr>}
                    </tbody>
                  </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default ReportPage;
