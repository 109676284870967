import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { auth } from './../../actions/index'

const Middleware = () => {

  useEffect(async () => {

    // Send a keep alive request every 5 minutes
    setInterval(async  () => {
      await auth.keepAlive();
    }, 30000);
  }, [])

  // Redirect if protected route and user not connected
  if (!auth.token()) {
    return (<Redirect to={{ pathname: '/auth/login' }} />)
  }

  return null;
}

export default Middleware;
