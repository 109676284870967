import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams } from 'react-router-dom'

import { production } from '../../../actions/index'

import Procedures from './procedures.data'

const ProcedurePage = () => {
  const params = useParams(),
    [skip, setSkip] = useState(false),
    [success, setSuccess] = useState(false),
    [errors, setErrors] = useState([]);


  // procedureCompletedRes = production.procedureCompletedRes(params.id);

  let inputs = {};
  const onSubmit = async e => {
    e.preventDefault();

    let data = { skipped: skip };
    for (const key in inputs) {
      if (inputs[key].skip) continue;

      // If specified, force string type
      // Note: scanCOSensor/serialNumber patch to pass number as an alphaNumeric string
      let val = inputs[key].val.value;
      if (inputs[key].type === 'string') {
        data[key] = val;
        continue ;
      }

      // Auto type detection (Float, Int, String)
      if (!isNaN(val) && val.indexOf('.') !== -1) {
        data[key] = parseFloat(val);
      } else if (!isNaN(val)) {
        data[key] = parseInt(val);
      } else {
        data[key] = val;
      }
    }

    // Send data
    let res = await production.onProcedureCompleted(params.id, data);
    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      return setErrors(err);
    }

    setSuccess(true);
  }

  const generateStep = (step, idx) => {
    // Skip first step (serial number already done)
    if (idx === 0) return ;

    // Display each step
    return <Row className="g-0 border-bottom" key={idx} >
      <Col lg={{ size: 6, order: 1 }} className="order-2">
        <div className="p-lg-5 p-4">
          <h5>{step.title}</h5>
          <p className="font-size-15 text-muted">
            {step.description} <br />
            {step.expected && `Expected ${step.expected}`}
          </p>

          <div className={`input-section mt-5 ${step.label === null && 'd-none'}`} >
            <Row >
              <Col>
                <div className="position-relative">
                  <label className="fw-semibold">{step.label} <small className="text-danger" >{errors[step.name] && `● ${errors[step.name]}`}</small></label>
                  <input type="text"
                    className={`form-control ${errors[step.name] && 'is-invalid'}`}
                    placeholder={`${step.label}...`}
                    ref={input => (inputs[step.name] = { skip: skip && step?.optional , val: input, type: step?.type })}
                    disabled={skip && step.optional} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col lg={6} className="order-1 order-lg-2">
        <div className="p-lg-5 p-4 bg h-100">

          <div className="comingsoon-img">
            <img src={step.image} alt="" className="img-fluid mx-auto d-block" />
          </div>
        </div>
      </Col>
    </Row>;
  }

  const skipTest = procedure => {
    if (!procedure.optional) {
      return ;
    }

    return <Row className="g-0 border-bottom" >
      <Col lg={{ size: 6, order: 1 }} className="order-2">
        <div className="p-lg-5 p-4">
          <h5>Skip procedure</h5>
          <p className="font-size-15 text-muted">
            Some tests of this procedure can be skipped. <br />
            Make sure you have permission before skipping this procedure.
          </p>
        </div>
      </Col>
      <Col lg={6} className="order-1 order-lg-2">
        <div className="d-flex align-items-center h-100 p-lg-5 p-4">
          <div className="form-check form-switch form-switch-lg mb-lg-3" >
            <input className="form-check-input" type="checkbox" id="SwitchCheckSizelg" onChange={() => setSkip(!skip)} />
          </div>
        </div>
      </Col>
    </Row>;
  }

  // Get the procedure
  const procedure = Procedures[params.procedure];
  if (!procedure) {
    console.log('TODO: Error management..')
  }

  // TODO: Preflight call to validate the ID?

  if (success) {
    return ( <Redirect to={{ pathname: `/quality-assurance/production/${params.procedure}/${params.id}/summary` }} /> )
  }

  return (
    <div className="page-content">
      <Container fluid>

        <div className="d-flex justify-content-center align-self-center mt-2 mb-3">
          <div className="d-flex" >
            <div className="me-3">
              <i className={`${procedure.image} h1 text-primary`} ></i>
            </div>
            <div className="flex-1">
              <h5 className="mb-1"> {procedure.title}</h5>
              <p className="text-muted">{procedure.description}</p>
            </div>
          </div>
        </div>
          <Card>
            <CardBody>
              <Form onSubmit={onSubmit}>

                {/* Skip option (if optional) */}
                { skipTest(procedure) }

                {/* Steps */}
                {procedure.steps.map((data, idx) => (
                  generateStep(data, idx)
                ))}

                <Row className="mt-3" >
                  <Col lg={12} className="text-center" >
                    <button
                      type="submit"
                      className="btn btn-success waves-effect waves-light" >
                      Next
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            </Card>
      </Container>
    </div>
  )
}

export default ProcedurePage;
