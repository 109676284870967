import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Link, useParams, useHistory } from 'react-router-dom'

import { auth, core } from './../../actions/index'

const ProfileMenu = () => {
  const params = useParams(),
    history = useHistory();

  const [isOpen, setIsOpen] = useState(false),
    [user, setUser] = useState({});


  useEffect(() => {
    let ref = core.sub('me', user => {
      setUser(user);
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  const onLogout = async () => {
    await auth.onLogout();
    history.push(`/auth/login`);
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <span className="ms-2 me-1" data-letters={`${user?.firstName?.charAt(0).toUpperCase() || 'N'}${user?.lastName?.charAt(0).toUpperCase() || 'A'}`} >{user?.firstName} {user?.lastName}</span>
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag={Link} to="/settings/profile"
          className={params?.section === 'profile' ? 'active' : null} >

          <i className="far fa-user me-1"></i> Profile
        </DropdownItem>
        <DropdownItem tag={Link} to="/settings/sessions"
          className={params?.section === 'sessions' ? 'active' : null} >

          <i className="fas fa-history me-1"></i> Sessions
        </DropdownItem>
        <div className="dropdown-divider" />
        <a onClick={onLogout} className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          Logout
        </a>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProfileMenu;
