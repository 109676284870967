import React, { useState, useCallback, useEffect, useRef } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'



import { devices, sites } from '../../../../actions/index'


const DISPLSAY_PER_PAGE = 25;
const AJAX_INPUT_TIMEOUT = 650;

const DeviceEditPage = () => {
  const params = useParams(),
    history = useHistory(),
    [inputSite, setInputSite] = useState({
      timer: null,
      selected: {},
      list: []
    }),
    [device, setDevices] = useState({}),
    [errors, setErrors] = useState({});

  useEffect(async () => {
    let res = await devices.get(params.id);
    setDevices(res.data);
  }, [])

  const onSubmit = async e => {
    e.preventDefault();

    if (inputSite?.selected?.value?._id) {
      let res = await devices.update(device.serialNumber, { siteId: inputSite.selected.value._id });
      // TODO: Error management
      console.log(res)
    }

    history.push(`/technician/device/${device._id}`);
  }

  const onSiteSearch = val => {
    if (inputSite.timer) {
      clearTimeout(inputSite.timer)
      inputSite.timer = null;
    }

    if (val.trim() !== '') {
      inputSite.timer = setTimeout(() => {
        getSites(val);
      }, AJAX_INPUT_TIMEOUT);
    }

    setInputSite({ 
      timer: inputSite.timer, 
      selected: inputSite.selected, 
      list: []
    });
  }

  const onSiteSelect = val => {
    setInputSite({ 
      timer: null, 
      selected: val, 
      list: inputSite.list
    });
  }

  const getSites = async search => {
    let res = await sites.getSites({
      search: search,
      page: 1,
      length: DISPLSAY_PER_PAGE
    });

    // TODO: Error management
    setInputSite({ 
      timer: null, 
      selected: inputSite.selected, 
      list: res.data.sites.map(val => { 
        return { value: val, label: val.familyId} 
      })
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Form onSubmit={onSubmit}>
                <CardBody>
                  <h4 className="card-title">Edit device information</h4>

                  <Row className="mt-3">
                    <Col md="6">
                      <div className="position-relative">
                        <label className="fw-semibold">Serial Number</label>
                        <input type="text" className="form-control"
                          defaultValue={device?.serialNumber}
                          disabled={true}
                        />
                      </div>


                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label className="form-label">Site ID (family ID)</label>
                        <Select
                          isMulti={false}
                          onInputChange={onSiteSearch}
                          onChange={onSiteSelect}
                          isSearchable={true}
                          options={inputSite.list}
                          placeholder="Select a new site..."
                          classNamePrefix="react-select"
                          isLoading={inputSite.timer != null}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              boxShadow: 'none'
                            })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4" >
                    <Col lg={12} className="text-center" >
                      <button
                        type="submit"
                        className="btn btn-success waves-effect waves-light" >
                        Save
                      </button>
                    </Col>
                  </Row>

                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default DeviceEditPage;
