
export default class Devices {
  constructor(core) {
    this.core = core;
  }

  async get(id) {
    return await this.core.apiCall('get', `/device/${id}`);
  }

  async update(serialNumber, data) {
    return await this.core.apiCall('put', `/device/${serialNumber}`, data);
  }

  async getDevices(data) {
    return await this.core.apiCall('post', `/device/search`, data);
  }

  async getStats(id, data) {
    return await this.core.apiCall('post', `/device/${id}/stats`, data);
  }

  async getEvents(id, data) {
    return await this.core.apiCall('post', `/device/${id}/events`, data)
  }

  async getAnalytic(data) {
    return await this.core.apiCall('post', `/device/analytic`, data)
  }
}
