import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import { sites } from './../../../../actions/index'

import Loader from './../../../../components/Common/Loader'
import Pagination from './../../../../components/Pagination/pagination.component'

const DISPLSAY_PER_PAGE = 25;

const SitesPage = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, sort: { createdAt: 1 }, sites: [] }),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' });

  useEffect(async () => {
    let search = null, page = 1, sort = { createdAt: 1 };
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : data.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    getSites(page, sort, search);
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/technician/sites/${query}`);
    getSites(page, sort, search);
  }


  const getSites = async (page, sort, search) => {

    // Start loading
    setIsLoading(true);

    let res = await sites.getSites({
      search: search,
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, sites: res.data.sites, page: page, sort: sort });

    // Stop loading
    setIsLoading(false);
  }

  const onNewSite = async e => {
    e.preventDefault();

    // TODO: Error management
    let res = await sites.create();
    history.push(`/technician/site/${res.data._id}/owner`);
  };

  let displaySite = (site, idx) => {

    // Disabled management
    let silent = <span className="badge bg-success me-1">Non</span>;
    if (site?.silent) {
      silent = <span className="badge bg-danger me-1">Oui</span>;
    }

    let noFireman = <span className="badge bg-success me-1">Non</span>;
    if (site?.noFireman) {
      noFireman = <span className="badge bg-danger me-1">Oui</span>;
    }

    return <tr key={idx} >
      <th scope="row">{site.familyId}</th>
      <td>{site?.owner?.account}</td>
      <td>{site?.owner?.clientType == 0 ? '' : sites.CLIENT_TYPE[site?.owner?.clientType]}</td>
      <td>{site?.owner?.clientName}</td>
      <td>{site?.owner?.billingName}</td>
      <td>{site?.address?.city}</td>
      <td>{site?.devices?.length}</td>
      <td>{ site?.organization == 'risc'? 'RISC' : 'Default' }</td>
      <td>{silent}</td>
      <td>{noFireman}</td>
      <td className="text-right">
        <Link to={`/technician/site/${site._id}/owner`}>
          <button className="btn btn-primary btn-sm" >
            Ouvrir
          </button>
        </Link>
      </td>
    </tr>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <span className="float-right" >
                  <Link to="#" onClick={onNewSite}>
                    <button className="btn btn-success btn-sm waves-effect waves-light" >
                      Nouveau site
                    </button>
                  </Link>
                </span>
                <h4 className="card-title">Sélection du site</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, data.sort) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chercher..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { familyId: data.sort.hasOwnProperty('familyId') ? data.sort.familyId * -1 : -1 }) }} >
                            Family ID
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { 'owner.account': data.sort.hasOwnProperty('owner.account') ? data.sort['owner.account'] * -1 : -1 }) }} >
                            No de compte
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { 'owner.clientType': data.sort.hasOwnProperty('owner.clientType') ? data.sort['owner.clientType'] * -1 : -1 }) }} >
                            Type de client
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { 'owner.clientName': data.sort.hasOwnProperty('owner.clientName') ? data.sort['owner.clientName'] * -1 : -1 }) }} >
                            Citoyen ou commerce
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { 'owner.billingName': data.sort.hasOwnProperty('owner.billingName') ? data.sort['owner.billingName'] * -1 : -1 }) }} >
                            Facturation
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { 'owner.clientCity': data.sort.hasOwnProperty('owner.clientCity') ? data.sort['owner.clientCity'] * -1 : -1 }) }} >
                            Ville
                          </Link>
                        </th>
                        <th>Qté Pentagones</th>
                        <th>Type</th>
                        <th style={{ width: '100px' }} >
                          <Link to="#" onClick={() => { onChange(data.page, { silent: data.sort.hasOwnProperty('silent') ? data.sort.silent * -1 : -1 }) }} >
                            Silencieux
                          </Link>
                        </th>
                        <th style={{ width: '150px' }} >
                          <Link to="#" onClick={() => { onChange(data.page, { noFireman: data.sort.hasOwnProperty('noFireman') ? data.sort.noFireman * -1 : -1 }) }} >
                            Pas de pompiers
                          </Link>
                        </th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.sites.map((site, idx) => (
                        displaySite(site, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="10" className='text-center' >Aucune donnée n'est disponible...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default SitesPage;
