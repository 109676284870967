import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useHistory } from 'react-router-dom'
import moment from 'moment'

import { auth, core } from './../../../actions/index'


const ProfileComponent = () => {
  const [user, setUser] = useState({}),
    [errors, setErrors] = useState({}),
    [timezones, setTimezones] = useState([]),
    [selectedTimezone, setSelectedTimezone] = useState('');


  useEffect(() => {
    setTimezones(moment.tz.names());

    let ref = core.sub('me', user => {
      setUser(user);
      setSelectedTimezone(user.timezone);
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  let inputs = {};
  const onSubmit = async e => {
    e.preventDefault();

    // Get data
    let data = {}
    for (const key in inputs) {
      data[key] = inputs[key].value;
    }

    // Reset errors and password
    setErrors({})
    inputs.password.value = "";
    inputs.newPassword.value = "";

    let res = await auth.onEditProfile(data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }
  }

  return (

    <Card>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row className="mb-3" >
            <Col lg={12} >
              <h4 className="card-title">Profile</h4>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">First Name <small className="text-danger" >{errors.firstName && `● ${errors.firstName}`}</small></Label>
                <input className={`form-control ${errors.firstName && 'is-invalid'}`}
                  type="text"
                  placeholder="First name.."
                  defaultValue={user?.firstName}
                  ref={input => (inputs.firstName = input)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">Last Name <small className="text-danger" >{errors.lastName && `● ${errors.lastName}`}</small></Label>
                <input className={`form-control ${errors.firstName && 'is-invalid'}`}
                  type="text"
                  placeholder="Last name.."
                  defaultValue={user?.lastName}
                  ref={input => (inputs.lastName = input)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">Email <small className="text-danger" >{errors.email && `● ${errors.email}`}</small></Label>
                <input className={`form-control ${errors.email && 'is-invalid'}`}
                  type="text"
                  placeholder="Email.."
                  defaultValue={user?.email}
                  ref={input => (inputs.email = input)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">Phone Number <small className="text-danger" >{errors.phoneNumber && `● ${errors.phoneNumber}`}</small></Label>
                <input className={`form-control ${errors.phoneNumber && 'is-invalid'}`}
                  type="text"
                  placeholder="Phone number.."
                  defaultValue={user?.phoneNumber}
                  ref={input => (inputs.phoneNumber = input)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">Current Password <small className="text-danger" >{errors.password && `● ${errors.password}`}</small></Label>
                <input className={`form-control ${errors.password && 'is-invalid'}`}
                  type="password"
                  placeholder="Current password.."
                  ref={input => (inputs.password = input)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label for="example-text-input" className="form-label">New Password <small className="text-danger" >{errors.newPassword && `● ${errors.newPassword}`}</small></Label>
                <input className={`form-control ${errors.newPassword && 'is-invalid'}`}
                  type="password"
                  placeholder="New password.."
                  ref={input => (inputs.newPassword = input)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <label className="form-label">Fuseau horaire</label>
                <select className="form-select"
                  value={selectedTimezone}
                  onChange={e => setSelectedTimezone(e.target.value)}
                  ref={input => (inputs.timezone = input)} >
                  {timezones.map((timezone) => (
                    <option key={timezone} value={timezone}>{timezone}</option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg={6}>

            </Col>
          </Row>

          <Row className="mt-3" >
            <Col lg={12} className="text-right" >
              <button
                type="submit"
                className="btn btn-success waves-effect waves-light" >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default ProfileComponent;
