import { sites } from "."

export default class Sites {

  CLIENT_TYPE = {
    0: '',
    1: 'Résidentiel',
    2: 'Multilogement',
    3: 'Commercial',
    4: 'Industriel',
    5: 'Institutionnel',
    6: 'Municipale',
    7: 'Militaire',
    8: 'Autre'
  }

  BUILDING_USAGE = {
    0: '',
    1: 'Domicile',
    2: 'RPA',
    3: 'Restaurant',
    4: 'Commerce',
    5: 'Bureaux',
    6: 'Entrepôt',
    7: 'Autre'
  }

  LANGUAGES = {
    0: '',
    1: 'Français',
    2: 'Anglais',
    3: 'Espagnol',
    4: 'Arabe',
    5: 'Chinois',
    6: 'Other',
  }

  DISTANCE_AWAY = {
    0: '',
    1: 'Moins de 50m',
    2: '50m à 100m',
    3: '100m à 150m',
    4: '150m à 1km',
    5: '1km à 10km',
    6: 'Plus de 10km'
  }

  ANIMALS = {
    0: '',
    1: 'Chien',
    2: 'Chat',
    3: 'Cheval',
    4: 'Oiseau',
    5: 'Petit mamifère',
    6: 'Animaux de ferme',
    7: 'Reptile',
    8: 'Autre'
  }

  PHONE_TYPES = {
    0: '',
    1: 'Cell avec SMS',
    2: 'Cell sans SMS',
    3: 'Ligne fixe résidentiel',
    4: 'Ligne fixe bureau',
    5: 'Autre'
  }

  DEVICE_TYPES = {
    0: '',
    1: 'XTa 120V',
    2: 'XTb Batt.',
    3: 'ESa 120V',
    4: 'ESb Batt.'
  }

  COMMUNICATION = {
    0: '',
    1: 'Cellulaire',
    2: 'WiFi',
    3: 'Wirepas'
  }

  ROOM = {
    0: '',
    1: 'Corridor',
    2: 'Escalier',
    3: 'Ch. Princ.',
    4: 'Chambre',
    5: 'Entrée',
    6: 'Cuisine',
    7: 'Salon',
    8: 'S-de-jeux',
    9: 'Bureau',
    10: 'Atelier',
    11: 'Rangem.',
    12: 'Garage',
    13: 'Cabanon'
  }

  POSITION = {
    0: '',
    1: 'Plafond',
    2: 'Mur',
    3: 'Incliné'
  }

  GENDER = {
    0: '',
    1: 'Monsieur',
    2: 'Madame',
    3: 'Non-Genré'
  };

  RELATIONSHIP = {
    0: '',
    1: 'Responsable',
    2: 'Conjoint(e)',
    3: 'Père/Mère',
    4: 'Beau-Parent',
    5: 'Sœur/Frère',
    6: 'B-Sœur/B-Frère',
    7: 'Enfant',
    8: 'Petit-enfant',
    9: 'Colocataire',
    10: 'Ami(e)',
    11: 'Voisin(e)',
    12: 'Autre'
  }

  DISABILITIES = {
    0: '',
    1: 'Aucune',
    2: 'Auditive légère',
    3: 'Auditive moy.',
    4: 'Auditive sévère',
    5: 'Physique légère',
    6: 'Physique moy.',
    7: 'Physique sévère',
    8: 'Cognitive légère',
    9: 'Cognitive moy.',
    10: 'Cognitive sévère',
    11: 'Autre',
    12: 'Confidentielle'
  }

  FLOOR = {
    0: '',
    1: 'Sous-Sol',
    2: 'Rez-de-jardin',
    3: 'R-C',
    4: '2e étage',
    5: '3e étage',
    6: '4e étage',
    7: '5e étage',
    8: 'Mézzanine',
    9: 'Grenier',
    10: 'Extérieur'
  }

  SECTOR = {
    0: '',
    1: 'Centre',
    2: 'A',
    3: 'AB',
    4: 'B',
    5: 'BC',
    6: 'C',
    7: 'CD',
    8: 'D',
    9: 'DA'
  }

  constructor(core) {
    this.core = core;
  }

  async create() {
    return await this.core.apiCall('post', `/site`);
  }

  async delete(id) {
    return await this.core.apiCall('delete', `/site/${id}`)
  }

  async update(id, data) {
    return await this.core.apiCall('put', `/site/${id}`, data)
  }

  async getSite(id) {
    let site = await this.core.apiCall('get', `/site/${id}`);
    let devices = await this.core.apiCall('post', `/site/${id}/devices/search`, {
      search: null,
      page: 1,
      length: 100
    });

    if (site && site.data) {
      site.data.devices = devices.data.devices;
    }

    return site;
  }

  async getSites(data) {
    return await this.core.apiCall('post', `/site/search`, data);
  }

  async getEvents(id, data) {
    return await this.core.apiCall('post', `/site/${id}/events/search`, data);
  }

  async getDevices(id, data) {
    return await this.core.apiCall('post', `/site/${id}/devices/search`, data);
  }

  async getCommunications(id) {
    return await this.core.apiCall('get', `/site/${id}/stats/communication`);
  }

  async createOccupant(id, data) {
    return await this.core.apiCall('post', `/site/${id}/occupants`, data);
  }

  async updateOccupant(siteId, occupantId, data) {
    return await this.core.apiCall('put', `/site/${siteId}/occupant/${occupantId}`, data);
  }

  async deleteOccupant(siteId, occupantId) {
    return await this.core.apiCall('delete', `/site/${siteId}/occupant/${occupantId}`);
  }

  async updateOwner(siteId, data) {
    return await this.core.apiCall('put', `/site/${siteId}/owner`, data);
  }

  async updateBuilding(siteId, data) {
    return await this.core.apiCall('put', `/site/${siteId}/building`, data);
  }

  async addDevice(siteId, data) {
    return await this.core.apiCall('post', `/site/${siteId}/device`, data);
  }

  async deleteDevice(siteId, deviceId) {
    return await this.core.apiCall('delete', `/site/${siteId}/device/${deviceId}`);
  }

  async updateDevice(siteId, deviceId, data) {
    return await this.core.apiCall('put', `/site/${siteId}/device/${deviceId}`, data);
  }

  async addContact(siteId, data) {
    return await this.core.apiCall('post', `/site/${siteId}/contacts`, data);
  }

  async deleteContact(siteId, contactId) {
    return await this.core.apiCall('delete', `/site/${siteId}/contact/${contactId}`);
  }

  async addAnimal(siteId, data) {
    return await this.core.apiCall('post', `/site/${siteId}/animal`, data);
  }

  async deleteAnimal(siteId, animalId) {
    return await this.core.apiCall('delete', `/site/${siteId}/animal/${animalId}`);
  }

  async updateAnimal(siteId, animalId, data) {
    return await this.core.apiCall('put', `/site/${siteId}/animal/${animalId}`, data);
  }
}
