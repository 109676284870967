import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ReactApexChart from "react-apexcharts";

const CommunicationGraph = props => {

  let x = [], s = [
    { name: 'INFO', data: [] },
    { name: 'HIGH', data: [] },
    { name: 'MEDIUM', data: [] },
    { name: 'LOW', data: [] }
  ];

  props.data.forEach(item => {
    if (!x.includes(item.date)) {
      x.push(item.date);

      // Push "0" everywhere
      s.forEach(arr => arr.data.push(0));
    }

    // Correct the desired counter
    let idx = [1, 2, 3].includes(item.priority)? item.priority : 0;
    s[idx].data[s[idx].data.length - 1] = item.count;
  });

  // Pad days without data
  let i = 0;
  for (var d = new Date(x[x.length-1]); d <= new Date(x[0]); d.setDate(d.getDate() + 1)) {
    let date = new Date(d).toISOString().split('T')[0]
    if (!x.includes(date)) {
      x.splice(i, 0, date);
      s.forEach(arr => arr.data.splice(i, 0, 0));
    }
    i++;
  }

  var options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    xaxis: {
      type: 'datetime',
      categories: x,
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#2cb57e', '#C61F4C', '#F36D40', '#47bce8'],
    legend: {
      show: true
    },
    fill: {
      opacity: 0.75
    },
    tooltip: {
      enabled: false
    }
  };

return (
  <ReactApexChart
    options={options}
    series={s}
    type="bar"
    height="230"
    className="apex-charts"
    dir="ltr"
  />
);
};

export default CommunicationGraph;
