import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { COffcanvas, COffcanvasBody, COffcanvasHeader, COffcanvasTitle   } from '@coreui/bootstrap-react'
import JWT from 'jsonwebtoken'

import { serialnumbers } from './../../../actions/index'

const DISPLAYS_PER_PAGE = 25;

const SerialNumbersPage = () => {
    const params = useParams(),
          history = useHistory(),
          [data, setData] = useState({count: 0, page: 1, serialnumbers: []});

    const [canvasVisible, setVisible] = useState(false)
    
    useEffect(async () => {
        let search = null, page = 1;
        if (params.query) {
            try {
                let decoded = JWT.decode(params.query);
                search = decoded.search? decoded.search : null;
                page = decoded.page? decoded.page : 1;
            } catch (err) {
                console.log(err); // TODO: Error management
            }
        }
        
        // Set search input's default value
        inputs.search.value = search;
        
        getSerialNumberBatches(page, search);
    }, [])
        
    let inputs = {};
    const onChange = page => {
      let search = null;
      if (inputs.search) {
        search = inputs.search.value;
      }
  
      let query = JWT.sign({page: page, search: search}, 'WHAT_EVER_AS_IF_IT_BOTHERS');
      history.push(`/admin/serialnumbers/${query}`);
      getSerialNumberBatches(page, search);
    }

    const getSerialNumberBatches = async (page, search) => {
      let res = await serialnumbers.getSerialNumberBatches({
        search: search,
        page: page,
        length: DISPLAYS_PER_PAGE
      });
  
      // TODO: Error management
      setData({ count: res.data.count, serialnumbers: res.data.batches, page: page });
  }

  const getSerialNumbers = async (page, search) => {
        let res = await serialnumbers.getPcbSerialNumbers({
          search: search,
          page: page,
          length: DISPLAYS_PER_PAGE
        });
    
        // TODO: Error management
        setData({ count: res.data.count, serialnumbers: res.data.serialnumbers, page: page });
    }

    let displaySerialNumber = (serialnumber, idx) => {

        // Assignment
        //let role = <span className="badge bg-info me-1">PRODUCER</span>;
        //if (user.role === 'admin') {
        //  role = <span className="badge bg-warning me-1">ADMIN</span>;
        //}
    
        // Status management
        //let status = <span className="badge bg-success me-1">ENABLED</span>;
        //if (user.status === 'disabled') {
        //  status = <span className="badge bg-danger me-1">DISABLED</span>;
        //}
    
        return <tr key={idx} >
          <th scope="row">{serialnumber.batchId}</th>
          <td>{serialnumber.target}</td>
          <td>{serialnumber.total}</td>
          <td>{serialnumber.createdAt}</td>
          <td>
            <span>0</span>
            <span><Progress value={0} /></span>
            <span>100%</span>
          </td>
          <td className="text-right">
            <span>
            <Link to={`/admin/serialnumber/${serialnumber._id}`}>
              <button className="btn btn-primary btn-sm" >
                View
              </button>
            </Link>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
              </svg>
            </span>
          </td>
        </tr>;
    }
    
    let displayPage = idx => {
        let page = idx+1;
        return <li className={`page-item ${data.page === page && 'active'}`} key={idx} >
                 <Link className="page-link" to="#" onClick={() => { onChange(page) }}>
                   {page}
                 </Link>
                </li>
    }
    
    let pageCount = Math.max(1, Math.ceil(data.count/DISPLAYS_PER_PAGE));
    return (
        <div className="page-content">
            <COffcanvas placement="end" visible={canvasVisible} onHide={() => setVisible(false)}>
              <COffcanvasHeader>
                <COffcanvasTitle>Batch creation</COffcanvasTitle>
                <Link onClick={() => setVisible(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
</svg>
                </Link>
              </COffcanvasHeader>
              <COffcanvasBody>
                <Form>
                  <FormGroup>
                    <Label for="targetElement">
                      Which component the batch is meant for?
                    </Label>
                    <Input id="targetElement" name="target" type="select">
                      <option>PCB</option>
                      <option>Pentagone</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="snTotal">How many numbers you want to create?</Label>
                    <Input id="snTotal" name="totalSN" type="number" placeholder="Number of serial numbers to create" />
                  </FormGroup>
                  <button className="btn btn-success waves-effect waves-light">Create ...</button>
                </Form>
              </COffcanvasBody>
            </COffcanvas>
            <Container fluid>
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">Serial Number batch management</h4>

                      <Row className="mt-3" >
                        <Col lg={4} >
                          <form onSubmit={e => { e.preventDefault(); onChange(1) }} className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                ref={input => (inputs.search = input)}
                              />
                              <span className="mdi mdi-magnify" />
                            </div>
                          </form>
                        </Col>
                        <Col lg={8} className="d-flex align-items-center justify-content-end" >
                          <button onClick={() => setVisible(true)} className="btn btn-success waves-effect waves-light" >
                              New batch
                            </button>
                        </Col>
                      </Row>
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Batch #</th>
                              <th>Batch for</th>
                              <th>Total</th>
                              <th>Created</th>
                              <th style={{ width: '100px'}} >Usage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.serialnumbers.map((serialnumber, idx) => (
                              displaySerialNumber(serialnumber, idx)
                            ))}

                            {data.count === 0 && <tr>
                              <td colSpan="6" className='text-center' >No batch created yet ...</td>
                            </tr>}

                            {/* TODO: No result management */}
                          </tbody>
                        </Table>
                      </div>
                      <Row className="mt-4 mb-2" >
                        <Col lg={12} className="d-flex justify-content-center" >
                          <nav aria-label="Page navigation example">
                            <ul className="pagination pagination-rounded mb-0">
                              <li className={`page-item ${data.page === 1 && 'disabled'}`} >
                                <Link
                                    className="page-link"
                                    onClick={() => { onChange(data.page - 1) }}
                                    to="#"
                                    aria-label="Previous"
                                >
                                    <i className="mdi mdi-chevron-left"></i>
                                </Link>
                              </li>
                              {Array(pageCount).fill(null).map((_, idx) => (
                                displayPage(idx)
                              ))}
                              <li className={`page-item ${data.page === pageCount && 'disabled'}`}>
                                <Link
                                    className="page-link"
                                    onClick={() => { onChange(data.page + 1) }}
                                    to="#"
                                    aria-label="Next" >
                                    <i className="mdi mdi-chevron-right"></i>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        </div>
    )
}
export default SerialNumbersPage;