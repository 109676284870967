import React from "react";
import ReactApexChart from "react-apexcharts";

const RemainingLife = props => {

  let calculatePct = val => {
    return Math.round(val / 120 * 100);
  }

  const series = [
    calculatePct(props.data.life),
    calculatePct(props.data.battery)
  ]

  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Month",
            formatter: function (w) {
              console.log('Inside formatted')
              console.log(props.data)

              return Math.max(props.data.life, props.data.battery)
            },
          },
        },
      },
    },

    labels: ["Product", "Battery"],
    colors: ["#f1b44c", "#f46a6a"],
    tooltip: {
      enabled: false
    }
  }

  return (
    <ReactApexChart
      options={(props?.data?.life == undefined)? {}: options}
      series={series}
      type="radialBar"
      height="250"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default RemainingLife;
