import { data } from 'jquery';
import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { sites } from './../../../../actions/index'

const SiteOccupants = props => {
  const params = useParams();
  let [errors, setErrors] = useState({}),
    [selected, setSelected] = useState(null),
    [disabilityOtherDisabled, setDisabilityOtherDisabled] = useState(true),
    [relationOtherDisabled, setRelationOtherDisabled] = useState(true),
    occupant = {
      gender: useRef(null),
      firstName: useRef(null),
      lastName: useRef(null),
      age: useRef(null),
      relationShip: useRef(null),
      relationShipOther: useRef(null),
      disability: useRef(null),
      disabilityOther: useRef(null),
      roomFloor: useRef(null),
      roomSector: useRef(null),
      language: useRef(null),
      firstPhone: useRef(null),
      firstPhoneType: useRef(null),
      secondPhone: useRef(null),
      secondPhoneType: useRef(null),
      email: useRef(null)
    };


  const onSubmit = async e => {
    e.preventDefault();

    var data = {
      firstName: occupant.firstName.current?.value,
      lastName: occupant.lastName.current?.value,
      gender: parseInt(occupant.gender.current?.value),
      age: parseInt(occupant.age.current?.value),
      roomFloor: parseInt(occupant.roomFloor.current?.value),
      roomSector: parseInt(occupant.roomSector.current?.value),
      disability: parseInt(occupant.disability?.current?.value),
      disabilityOther: occupant.disabilityOther?.current?.value,
      relationShip: parseInt(occupant.relationShip?.current?.value),
      relationShipOther: occupant.relationShipOther?.current?.value,
      language: parseInt(occupant.language?.current?.value),
      channels: []
    };

    // Channels mnagement
    if (occupant.firstPhone?.current?.value) {
      data.channels.push({
        "id": 1,
        "type": sites.PHONE_TYPES[occupant.firstPhoneType?.current?.value],
        "value": occupant.firstPhone?.current?.value
      });
    }

    if (occupant.secondPhone?.current?.value) {
      data.channels.push({
        "id": 2,
        "type": sites.PHONE_TYPES[occupant.secondPhoneType?.current?.value],
        "value": occupant.secondPhone?.current?.value
      });
    }

    if (occupant.email?.current?.value) {
      data.channels.push({
        "id": 3,
        "type": 'email',
        "value": occupant.email?.current?.value
      });
    }

    // TODO: Error management
    let res = null;
    if (selected) {
      res = await sites.updateOccupant(params.id, selected, data);
    } else {
      res = await sites.createOccupant(params.id, [data]);
    }

    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  const onDelete = async (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet occupant?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {

        // TODO: Error management
        await sites.deleteOccupant(params.id, id);
        window.location.reload(true);
      }
    })
  }

  const onEdit = async (e, data, idx) => {
    e.preventDefault();

    data.channels?.forEach(chn => {
      if (chn.id == 1) {
        occupant.firstPhone.current.value = chn.value;
        occupant.firstPhoneType.current.value = Object.keys(sites.PHONE_TYPES).find(k => sites.PHONE_TYPES[k] === chn.type);
      } else if (chn.id == 2) {
        occupant.secondPhone.current.value = chn.value;
        occupant.secondPhoneType.current.value = Object.keys(sites.PHONE_TYPES).find(k => sites.PHONE_TYPES[k] === chn.type);
      } else if (chn.id == 3) {
        occupant.email.current.value = chn.value;
      }

      if (occupant.secondPhone?.current?.value) {
        data.channels.push({
          "id": 2,
          "type": sites.PHONE_TYPES[occupant.secondPhoneType?.current?.value],
          "value": occupant.secondPhone?.current?.value
        });
      }

      if (occupant.email?.current?.value) {
        data.channels.push({
          "id": 3,
          "type": 'email',
          "value": occupant.email?.current?.value
        });
      }
    });

    occupant.firstName.current.value = data.firstName;
    occupant.lastName.current.value = data.lastName;
    occupant.gender.current.value = data.gender;
    occupant.age.current.value = data.age;
    occupant.roomFloor.current.value = data.roomFloor;
    occupant.roomSector.current.value = data.roomSector;
    occupant.disability.current.value = data.disability;
    occupant.disabilityOther.current.value = data.disabilityOther;
    occupant.relationShip.current.value = data.relationShip;
    occupant.relationShipOther.current.value = data.relationShipOther;
    occupant.language.current.value = data.language;

    setSelected(data._id);
    setRelationOtherDisabled(data.relationShip != 12);
    setDisabilityOtherDisabled(data.disability != 11);
  }

  const onCancel = e => {
    e.preventDefault();

    occupant.firstName.current.value = '';
    occupant.lastName.current.value = '';
    occupant.gender.current.value = '0';
    occupant.age.current.value = '0';
    occupant.roomFloor.current.value = '0';
    occupant.roomSector.current.value = '0';
    occupant.disability.current.value = '0';
    occupant.disabilityOther.current.value = '';
    occupant.relationShip.current.value = '0';
    occupant.relationShipOther.current.value = '';
    occupant.language.current.value = '0';
    occupant.firstPhone.current.value = '';
    occupant.firstPhoneType.current.value = '0';
    occupant.secondPhone.current.value = '';
    occupant.secondPhoneType.current.value = '0';
    occupant.email.current.value = '';

    setSelected(null);
    setRelationOtherDisabled(true);
    setDisabilityOtherDisabled(true);
  }


  const displayRow = (row, idx) => {

    let firstPhone = '', secondPhone = '';
    if (row.channels && row.channels[0]?.id == 1) {
      firstPhone = row.channels[0]?.value;
    }
    if (row.channels && row.channels[1]?.id == 2) {
      secondPhone = row.channels[1]?.value;
    }

    return <tr key={idx} >
      <td>{row.firstName}</td>
      <td>{row.lastName}</td>
      <td>{sites.GENDER[row.gender]}</td>
      <td>{row.age}</td>
      <td>{firstPhone}</td>
      <td>{secondPhone}</td>
      <td>{sites.RELATIONSHIP[row.relationShip]}</td>
      <td>{sites.DISABILITIES[row.disability]}</td>
      <td>{sites.FLOOR[row.roomFloor]}</td>
      <td>{sites.SECTOR[row.roomSector]}</td>
      <td className="text-right">
      <span style={{ width: '100px', display: 'inline-block' }} >
        <button className="me-2 btn btn-primary btn-sm"
          onClick={(e) => onEdit(e, row, idx)} >
          <i className='fas fa-pencil-alt' />
        </button>
        <button className="btn btn-danger btn-sm"
          onClick={(e) => onDelete(e, row._id)} >
          <i className='fas fa-trash' />
        </button>
        </span>
      </td>
    </tr>;
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h4 className="text-center"><strong>Occupant du bâtiment protégé</strong></h4>

          <div className="table-responsive mt-4 mb-3">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Genre</th>
                  <th>Âge</th>
                  <th>1er Téléphone</th>
                  <th>2e Téléphone</th>
                  <th>Relation</th>
                  <th>Incapacité</th>
                  <th>Étage Chambre</th>
                  <th>Secteur Chambre</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.site?.occupants?.map((row, idx) => (
                  displayRow(row, idx)
                ))}

                {props.site?.occupants?.length === 0 && <tr>
                  <td colSpan="12" className='text-center' >Aucun occupant...</td>
                </tr>}
              </tbody>
            </Table>
          </div>

          <Row>
            <Col md={12}>
              <hr className="mt-4" />
            </Col>
          </Row>

          <Form onSubmit={onSubmit}>
            <Row className="mt-2" >
              <Col lg={12} className="text-right" >
                <button
                  onClick={onCancel}
                  className="me-3 btn btn-danger btn-sm waves-effect waves-light" >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-success btn-sm waves-effect waves-light" >
                  Sauvegarder
                </button>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Prénom <small className="text-danger" >{errors?.firstName && `● ${errors?.firstName}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.firstName}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Nom <small className="text-danger" >{errors?.lastName && `● ${errors?.lastName}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.lastName}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Genre <small className="text-danger" >{errors?.gender && `● ${errors?.gender}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.gender.current = input)} >
                    {Object.keys(sites.GENDER).map((key, idx) => (
                      <option key={idx} value={key} > {sites.GENDER[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Âge <small className="text-danger" >{errors?.age && `● ${errors?.age}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.age.current = input)} >
                    <option value={0}></option>
                    <option value="-1">-1 an</option>
                    <option value="1">1 an</option>
                    {[...Array(98).keys()].map(val => <option key={val + 2} value={val + 2}>{`${val + 2} ans`}</option>)}
                    <option value="confidential">Confid.</option>
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Relation <small className="text-danger" >{errors?.relationShip && `● ${errors?.relationShip}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.relationShip.current = input)}
                    onChange={(e) => { setRelationOtherDisabled(e.target.value != 12) }}
                  >
                    {Object.keys(sites.RELATIONSHIP).map((key, idx) => (
                      <option key={idx} value={key} > {sites.RELATIONSHIP[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Relation (autre)</label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.relationShipOther}
                    disabled={relationOtherDisabled}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Incapacité <small className="text-danger" >{errors?.disability && `● ${errors?.disability}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.disability.current = input)}
                    onChange={(e) => { setDisabilityOtherDisabled(e.target.value != 11) }}
                  >
                    {Object.keys(sites.DISABILITIES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.DISABILITIES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Incapacité (autre)</label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.disabilityOther}
                    disabled={disabilityOtherDisabled}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Étage Chambre <small className="text-danger" >{errors?.roomFloor && `● ${errors?.roomFloor}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.roomFloor.current = input)} >
                    {Object.keys(sites.FLOOR).map((key, idx) => (
                      <option key={idx} value={key} > {sites.FLOOR[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Secteur Chambre <small className="text-danger" >{errors?.roomSector && `● ${errors?.roomSector}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.roomSector.current = input)} >
                    {Object.keys(sites.SECTOR).map((key, idx) => (
                      <option key={idx} value={key} > {sites.SECTOR[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Langue <small className="text-danger" >{errors?.language && `● ${errors?.language}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.language.current = input)}
                  >
                    {Object.keys(sites.LANGUAGES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.LANGUAGES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Courriel <small className="text-danger" >{errors?.email && `● ${errors?.email}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.email}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">1er Téléphone <small className="text-danger" >{errors?.firstPhone && `● ${errors?.firstPhone}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.firstPhone}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.firstPhoneType && `● ${errors?.firstPhoneType}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.firstPhoneType.current = input)}
                  >
                    {Object.keys(sites.PHONE_TYPES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.PHONE_TYPES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">2e Téléphone <small className="text-danger" >{errors?.secondPhone && `● ${errors?.secondPhone}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={occupant.secondPhone}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.secondPhoneType && `● ${errors?.secondPhoneType}`}</small></label>
                  <select className="form-select"
                    ref={input => (occupant.secondPhoneType.current = input)}
                  >
                    {Object.keys(sites.PHONE_TYPES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.PHONE_TYPES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}



export default SiteOccupants;