import React, { useRef, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Card, Col, Container, Form, Input, Row } from 'reactstrap'


import logo from './../../../assets/images/logo-full.png'

import { core, auth  } from './../../../actions/index'

const LoginPage = () => {
  const history = useHistory(),
    inputs = {email: useRef(null), password: useRef(null) },
    [errors, setErrors] = useState([]);


  const onSubmit = async e => {
    e.preventDefault();

    // Login query
    let res = await auth.onLogin({
      email: inputs.email.current?.value.toLowerCase(),
      password: inputs.password.current?.value
    });

    // Error management
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      return setErrors(err);
    }

    // Success management
    history.push(`/`);
  }

  if (auth.token()) {
    return ( <Redirect to={{ pathname: '/' }} /> )
  }

  return (

    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="text-center mb-5">
              <img src={logo} alt="" height="40" className="auth-logo-dark" />
              <p className="font-size-15 text-muted mt-3">Revolutionizing the residential <b>fire safety</b></p>
            </div>
            <Card className="overflow-hidden">
              <Row className="g-0">
                <Col lg={6}>
                  <div className="p-lg-5 p-4">
                    <div>
                      <h5><i className="fas fa-heartbeat text-danger"></i> Technical Portal</h5>
                    </div>

                    <div className="mt-4 pt-2">
                      <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <label className="fw-semibold">Email <small className="text-danger" >{errors.email && `● ${errors.email}`}</small></label>
                          <input type="text" className={`form-control ${errors.email && 'is-invalid'}`}
                            placeholder="Enter email..."
                            ref={inputs.email} />
                        </div>

                        <div className="mb-3 mb-4">
                          <label className="fw-semibold">Password <small className="text-danger" >{errors.password && `● ${errors.password}`}</small></label>
                          <input type="password" className={`form-control ${errors.password && 'is-invalid'}`} id="userpassword"
                            placeholder="Enter password"
                            ref={inputs.password} />
                        </div>

                        <div className="row align-items-center">
                          <Col xs={6}>
                            {/* <Link to="/"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link> */}
                          </Col>
                          <Col xs={6}>
                            <div className="text-end">
                              <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                            </div>
                          </Col>
                        </div>

                      </Form>
                    </div>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                    <div className="bg-overlay"></div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;
