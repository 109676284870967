import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
// import HouseOverview from '../../../components/Dashboard/houseOverview.component'
// import SmokeGraph from '../../../components/Dashboard/smokeGraph.component'
// import AudioPlayer from '../../../components/Dashboard/audioPlayer.component'
// import DeviceInfo from '../../../components/Dashboard/deviceInfo.component'
// import NetworkInfo from '../../../components/Dashboard/networkInfo.component'

// import ProductLife from '../../../components/Dashboard/productLife.component'
// import BatteryLife from '../../../components/Dashboard/batteryLife.component'
// import CommunicationGraph from '../../../components/Dashboard/communicationGraph.component'
// import CommunicationHeatMap from '../../../components/Dashboard/communicationHeatMap.component'
// import BatteryVoltage from '../../../components/Dashboard/batteryVoltage.component'
// import FireDangerIndex from '../../../components/Dashboard/fireDangerIndex.component'
// import HumidityGraph from '../../../components/Dashboard/humidityGraph.component'
// import TemperatureGraph from '../../../components/Dashboard/temperatureGraph.component'
// import COLevelGraph from '../../../components/Dashboard/carbonMonoxideGraph.component'
// import EventsTable from '../../../components/Dashboard/eventsTable.component'

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container
} from 'reactstrap'

import { core } from './../../../actions/index'

const HomePage = () => {

  useEffect(() => {
    let ref = core.sub('me', user => {

      // If operator, redirect to operator page
      if(user.role === 'operator') {
        window.location.replace("/operator/alerts");
      }
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  return (
    <div className="page-content">
      {/* <Container fluid>
        <Row>
          <Col lg={2}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-danger" >47.2%</h3>
                  Fumée <br />
                  <small>Niveau de fumée</small>
                </CardTitle>
                <SmokeGraph />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-danger" >2.7</h3>
                  NICA <br />
                  <small>NICA description</small>
                </CardTitle>
                <SmokeGraph />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <HouseOverview />
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Activities
                </CardTitle>
                <Row className="activity danger" >
                  <Col className="text-end" lg={6}>
                    <h2>-02:01</h2>
                    <p>03:38am</p>
                  </Col>
                  <Col lg={6}>
                    <h2>9.5%</h2>
                    <p>Fumée</p>
                    <p>polyuréthane</p>
                  </Col>
                </Row>

                <Row className="activity danger" >
                  <Col className="text-end" lg={6}>
                    <h2>-02:01</h2>
                    <p>03:38am</p>
                  </Col>
                  <Col lg={6}>
                    <h2>9.5%</h2>
                    <p>Fumée</p>
                    <p>polyuréthane</p>
                  </Col>
                </Row>

                <Row className="activity warning" >
                  <Col className="text-end" lg={6}>
                    <h2>-02:01</h2>
                    <p>03:38am</p>
                  </Col>
                  <Col lg={6}>
                    <h2>9.5%</h2>
                    <p>Fumée</p>
                    <p>polyuréthane</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Audio
                </CardTitle>
                <Row className="audio-player-wrapper" >
                  <Col className="text-center" >
                    <AudioPlayer />
                  </Col>
                  <Col>
                    <p><a href="#">RDC - 0:27</a></p>
                    <p><a href="#">Sous-Sol - 0:07</a></p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Presence
                </CardTitle>
                <Row>
                  <Col lg={6}>
                    <i className="fas fa-male fa-3x me-2"></i>
                    <i className="fas fa-female fa-3x text-danger me-2"></i>
                    <i className="fas fa-child fa-3x me-2"></i>
                    <i className="fas fa-child fa-3x text-danger me-2"></i>
                    <i className="fas fa-baby fa-3x text-danger me-2"></i>
                  </Col>
                  <Col lg={6}>
                    <h4 className="text-red mt-2"><strong>3 personnes</strong></h4>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6} className="d-table" >
                    <span className="d-table-cell align-middle" >
                      <i className="fas fa-female fa-3x text-danger me-2"></i>
                      <i className="fas fa-baby fa-3x text-danger"></i>
                    </span>
                  </Col>
                  <Col lg={6}>
                    <h4 className="text-danger" ><strong>-00:02:04</strong></h4>
                    <p>
                      Last confirmation
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6} className="d-table" >
                  <i className="fas fa-child fa-3x text-danger d-table-cell align-middle"></i>
                  </Col>
                  <Col lg={6}>
                    <h4 className="text-danger" ><strong>-00:03:47</strong></h4>
                    <p>
                      Last confirmation
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6} className="d-table" >
                  <i className="fas fa-child fa-3x d-table-cell align-middle"></i>
                  </Col>
                  <Col lg={6}>
                    <h4><strong>-02:10:29</strong></h4>
                    <p>
                      Last confirmation
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={6} className="d-table">
                  <i className="fas fa-male ms-2 fa-3x d-table-cell align-middle ps-2 ms-1"></i>
                  </Col>
                  <Col lg={6}>
                    <h4><strong>-05:41:39</strong></h4>
                    <p>
                      Last confirmation
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Device Info
                </CardTitle>
                <DeviceInfo />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Network Info
                </CardTitle>
                <NetworkInfo />
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Product Lifetime
                </CardTitle>
                <ProductLife />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Battery Lifetime
                </CardTitle>
                <BatteryLife />
              </CardBody>
            </Card>
          </Col>
          <Col lg={7}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Communication
                </CardTitle>
                <CommunicationGraph />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 text-center">
                  Events
                </CardTitle>
                <CommunicationHeatMap />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-success" >3.33V - 53 mΩ</h3>
                  Battery Status <br />
                  <small>Voltage & Resistance</small>
                </CardTitle>
                <BatteryVoltage />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-warning" >29°C</h3>
                  Temperature <br />
                  <small>Temperature in celsius</small>
                </CardTitle>
                <TemperatureGraph />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-danger" >110 ppm</h3>
                  CO Levels <br />
                  <small>Carbon Monoxide Levels</small>
                </CardTitle>
                <COLevelGraph />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-danger" >5.5</h3>
                  Fire Danger Index <br />
                  <small>Estimated potential for fire</small>
                </CardTitle>
                <FireDangerIndex />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-success" >57%</h3>
                  Humidity <br />
                  <small>Relative humidity</small>
                </CardTitle>
                <HumidityGraph />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-warning" >21</h3>
                  Events & Faults<br />
                  <small>3 months history</small>
                </CardTitle>
                <EventsTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
}

export default HomePage;
