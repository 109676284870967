import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'
import moment from 'moment'

import { alerts, sites, core } from './../../../actions/index'
import Loader from './../../../components/Common/Loader'
import Pagination from './../../../components/Pagination/pagination.component'

const DISPLSAY_PER_PAGE = 25;

const AlarmsPage = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, sort: { createdAt: -1 }, alarms: [] }),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' }),
    [myTimezone, setMyTimezone] = useState('');


  useEffect(async () => {
    let search = null, page = 1, sort = { createdAt: -1 };
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : data.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    core.sub('me', async user => {
      setMyTimezone(user.timezone);
      getAlarms(page, sort, search);
    });
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/operator/alerts/${query}`);
    getAlarms(page, sort, search);
  }

  const getAlarms = async (page, sort, search) => {

    // Start loading
    setIsLoading(true);

    let res = await alerts.getAlarms({
      search: search,
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, alarms: res.data.alarms, page: page, sort: sort });

    // Stop loading
    setIsLoading(false);
  }

  let displayAlarm = (alert, idx) => {

    // State management
    let state = <span className="badge bg-danger me-1">DÉCLENCHÉ</span>;
    if (alert.state === 'pending_trig') {
      state = <span className="badge bg-warning me-1">DÉCLENCHEMENT EN ATTENTE</span>;
    } else if (alert.state === 'pending_ok') {
      state = <span className="badge bg-warning me-1">RESTAURATION EN ATTENTE</span>;
    } else if (alert.state === 'ok') {
      state = <span className="badge bg-success me-1">RESTAURÉE</span>;
    }

    // Reference number is present when the alert is relayed to the emergency services
    let refnum = alert.refNum === undefined ? "-" : alert.refNum;
    return <tr key={idx} >
      <th scope="row">
        {moment.tz(new Date(alert.triggedAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}
      </th>
      <td>{refnum}</td>
      <td>{alert.site?.owner?.clientName}</td>
      <th>{alert.site?.address?.street}</th>
      <th>{alert.site?.address?.city}</th>
      <th>{sites.ROOM[alert.device.room]}</th>
      <th>{alert.name}</th>
      <th>{state}</th>
      <th className="text-right">
        <Link to={`/operator/alert/${alert._id}`}>
          <button className="btn btn-primary btn-sm" >
            Ouvrir
          </button>
        </Link>
      </th>
    </tr>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Alarmes récentes</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, data.sort) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chercher..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { triggedAt: data.sort.hasOwnProperty('triggedAt') ? data.sort.triggedAt * -1 : -1 }) }} >
                            Heure
                          </Link>
                        </th>
                        <th>
                          # Événement
                        </th>
                        <th>
                          Client
                        </th>
                        <th>
                          Address
                        </th>
                        <th>
                          Ville
                        </th>
                        <th>
                          Pièce
                        </th>
                        <th style={{ width: '200px' }} >
                          Titre de l'alarme
                        </th>
                        <th style={{ width: '100px' }} >
                          <Link to="#" onClick={() => { onChange(data.page, { state: data.sort.hasOwnProperty('state') ? data.sort.state * -1 : -1 }) }} >
                            État
                          </Link>
                        </th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.alarms.map((alarm, idx) => (
                        displayAlarm(alarm, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="9" className='text-center' >Aucune donnée n'est disponible..</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default AlarmsPage;
