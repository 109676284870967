
export default class Users {
  constructor(core) {
    this.core = core;
  }

  async onCreateUser(data) {
    return await this.core.apiCall('post', `/user`, data);
  }

  async onEditUser(id, data) {
    return await this.core.apiCall('put', `/user/${id}`, data);
  }

  async getUser(id) {
    return await this.core.apiCall('get', `/user/${id}`);
  }

  async getUsers(data) {
    return await this.core.apiCall('post', `/user/search`, data);
  }
}
