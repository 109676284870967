import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import Loader from './../../../components/Common/Loader'
import { users } from './../../../actions/index'

const DISPLSAY_PER_PAGE = 25;

const UsersPage = () => {
  const params = useParams(),
    history = useHistory(),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' }),
    [data, setData] = useState({ count: 0, page: 1, users: [] });


  useEffect(async () => {
    let search = null, page = 1;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    getUsers(page, search);
  }, [])

  let inputs = {};
  const onChange = page => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/admin/users/${query}`);
    getUsers(page, search);
  }


  const getUsers = async (page, search) => {

    // Start loading
    setIsLoading(true);

    let res = await users.getUsers({
      search: search,
      page: page,
      length: DISPLSAY_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, users: res.data.users, page: page });

    // Stop loading
    setIsLoading(false);
  }

  let displayUser = (user, idx) => {

    // Role management
    let role = <span className="badge bg-info me-1">PRODUCER</span>;
    if (user.role === 'admin') {
      role = <span className="badge bg-warning me-1">ADMIN</span>;
    } else if (user.role === 'operator') {
      role = <span className="badge bg-success me-1">OP. DE CENTRALE</span>;
    }

    // Status management
    let status = <span className="badge bg-success me-1">ENABLED</span>;
    if (user.status === 'disabled') {
      status = <span className="badge bg-danger me-1">DISABLED</span>;
    }

    return <tr key={idx} >
      <th scope="row">{user.firstName} {user.lastName}</th>
      <td>{user.email}</td>
      <td>{user.phoneNumber}</td>
      <td>{role}</td>
      <td>{status}</td>
      <td className="text-right">
        <Link to={`/admin/user/${user._id}`}>
          <button className="btn btn-primary btn-sm" >
            Edit
          </button>
        </Link>
      </td>
    </tr>;
  }

  let displayPage = idx => {
    let page = idx + 1;
    return <li className={`page-item ${data.page === page && 'active'}`} key={idx} >
      <Link className="page-link" to="#" onClick={() => { onChange(page) }}>
        {page}
      </Link>
    </li>
  }

  let pageCount = Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE));
  return (
    <div className="page-content">

      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <span className="float-right" >
                  <Link to="/admin/user/add" >
                    <button className="btn btn-success btn-sm waves-effect waves-light" >
                    New user
                    </button>
                  </Link>
                </span>
                <h4 className="card-title">User management</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone number</th>
                        <th style={{ width: '100px' }} >Role</th>
                        <th style={{ width: '100px' }} >Status</th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.users.map((user, idx) => (
                        displayUser(user, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="6" className='text-center' >Aucune donnée n'est disponible...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>
                <Row className="mt-4 mb-2" >
                  <Col lg={12} className="d-flex justify-content-center" >
                    <nav aria-label="Page navigation example">
                      <ul className="pagination pagination-rounded mb-0">
                        <li className={`page-item ${data.page === 1 && 'disabled'}`} >
                          <Link
                            className="page-link"
                            onClick={() => { onChange(data.page - 1) }}
                            to="#"
                            aria-label="Previous"
                          >
                            <i className="mdi mdi-chevron-left"></i>
                          </Link>
                        </li>
                        {Array(pageCount).fill(null).map((_, idx) => (
                          displayPage(idx)
                        ))}
                        <li className={`page-item ${data.page === pageCount && 'disabled'}`}>
                          <Link
                            className="page-link"
                            onClick={() => { onChange(data.page + 1) }}
                            to="#"
                            aria-label="Next" >
                            <i className="mdi mdi-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default UsersPage;
