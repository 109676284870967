import Alerts from './alerts.action'
import Auth from './auth.action'
import Core from './_core.action'
import Devices from './devices.action'
import Production from './production.action'
import SerialNumbers from './serialnumbers.action'
import Sites from './sites.action'
import Users from './users.action'
import Events from './events.action'
import Centers from './centers.action'
import Municipalities from './municipalities.action'

export let core = new Core();
export let auth = new Auth(core);
export let production = new Production(core);
export let users = new Users(core);
export let sites = new Sites(core);
export let serialnumbers = new SerialNumbers(core);
export let devices = new Devices(core);
export let alerts = new Alerts(core);
export let events = new Events(core);
export let centers = new Centers(core);
export let municipalities = new Municipalities(core);
