import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

const MAX_DISPLAYED = 9;

const Pagination = props => {

  let displayPagination = () => {
    let currentPage = props.data.page;
    let totalPages = props.data.pageCount;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage = 0, endPage = 0;
    if (totalPages <= MAX_DISPLAYED) { // Total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else { // Total pages more than max so calculate start and end pages

      let maxPagesBeforeCurrentPage = Math.floor(MAX_DISPLAYED / 2);
      let maxPagesAfterCurrentPage = Math.ceil(MAX_DISPLAYED / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) { // Current page near the start
        startPage = 1;
        endPage = MAX_DISPLAYED;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) { // Current page near the end
        startPage = totalPages - MAX_DISPLAYED + 1;
        endPage = totalPages;
      } else { // Current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // Create an array of pages to ng-repeat in the pager control
    let res = [], pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    for (const page of pages) {
      res.push(<li className={`page-item ${props.data.page === page && 'active'}`} key={page} >
        <Link className="page-link" to="#" onClick={() => { props.onChange(page, props.data.sort) }}>
          {page}
        </Link>
      </li>);
    }
    return res;
  }

  return (
    <Row className="mt-4 mb-2" >
      <Col lg={12} className="d-flex justify-content-center" >
        <nav aria-label="Page navigation example">
          <ul className="pagination pagination-rounded mb-0">
            <li className={`page-item ${props.data.page === 1 && 'disabled'}`} >
              <Link
                className="page-link"
                onClick={() => { props.onChange(props.data.page - 1, props.data.sort) }}
                to="#"
                aria-label="Previous"
              >
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>

            {displayPagination()}

            <li className={`page-item ${props.data.page === props.data.pageCount && 'disabled'}`}>
              <Link
                className="page-link"
                onClick={() => { props.onChange(props.data.page + 1, props.data.sort) }}
                to="#"
                aria-label="Next" >
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </Col>
    </Row>
  );
}



export default Pagination;