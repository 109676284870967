
export default class Alerts {
  constructor(core) {
    this.core = core;
  }

  async getAlerts(data) {
    return await this.core.apiCall('post', `/alert/search`, data);
  }

  async getAlert(id) {
    return await this.core.apiCall('get', `/alert/${id}`);
  }

  async getAlarms(data) {
    return await this.core.apiCall('post', `/alert/alarm/search`, data);
  }

  async getAlertComments(id) {
    return await this.core.apiCall('get', `/alert/${id}/comments`);
  }

  async updateAlert(id, data) {
    return await this.core.apiCall('put', `/alert/${id}`, data);
  }

  async addComment(id, data) {
    return await this.core.apiCall('post', `/alert/${id}/comment`, data);
  }

  async getComments(id) {
    return await this.core.apiCall('get', `/alert/${id}/comments`);
  } 
}
