export default class Events {
    constructor(core) {
      this.core = core;
    }
  
    async onCreateEvent(data) {
      return await this.core.apiCall('post', `/event`, data);
    }
  
    async onEditEvent(id, data) {
      return await this.core.apiCall('put', `/event/${id}`, data);
    }
  
    async getEvent(id) {
      return await this.core.apiCall('get', `/event/${id}`);
    }
  
    async getEvents(data) {
      return await this.core.apiCall('post', `/event/search`, data);
    }
  }