import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'

import Select from 'react-select'
import ReactApexChart from 'react-apexcharts'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

import { devices, core } from './../../../../actions/index'

const DISPLSAY_PER_PAGE = 25;
const AJAX_INPUT_TIMEOUT = 650;

const AnalyticPage = () => {
  const params = useParams(),
    history = useHistory(),
    [series, setSeries] = useState({ meta: [], data: [] }),
    [chart, setChart] = useState({ options: {}, data: [] }),
    [from, setFrom] = useState(''),
    [to, setTo] = useState(''),
    [browserTime, setBrowserTime] = useState('1440'),
    [refreshInterval, setRefreshInterval] = useState('0'),
    [intervalFunc, setIntervalFunc] = useState(null),
    [inputDevices, setInputDevices] = useState({
      timer: null,
      selected: {},
      list: []
    }),
    [inputMetrics, setInputMetrics] = useState({
      timer: null,
      selected: [],
      list: []
    }),
    [myTimezone, setMyTimezone] = useState('');

  useEffect(async () => {
    if (params.query) {
      // TODO: Save query to URL
    }

    core.sub('me', async user => {
      setMyTimezone(user.timezone);
    });
  }, [])

  const inputDevicesOnSearch = val => {
    if (inputDevices.timer) {
      clearTimeout(inputDevices.timer)
      inputDevices.timer = null;
    }

    if (val.trim() !== '') {
      inputDevices.timer = setTimeout(() => {
        getDevices(val);
      }, AJAX_INPUT_TIMEOUT);
    }

    setInputDevices({ 
      timer: inputDevices.timer, 
      selected: inputDevices.selected, 
      list: []
    });
  }

  const inputDevicesOnSelect = val => {
    setInputDevices({ 
      timer: null, 
      selected: val, 
      list: inputDevices.list
    });

    getMetrics(val.value._id);
  }

  const getDevices = async search => {
    let res = await devices.getDevices({
      search: search,
      page: 1,
      length: DISPLSAY_PER_PAGE
    });

    // TODO: Error management
    setInputDevices({ 
      timer: null, 
      selected: inputDevices.selected, 
      list: res.data.devices.map(val => { 
        return { value: val, label: val.serialNumber} 
      })
    });
  }

  const inputMetricsOnSelect = val => {
    setInputMetrics({ 
      timer: null, 
      selected: val, 
      list: inputMetrics.list
    });
  }

  const getMetrics = async id => {
    setInputMetrics({ 
      timer: true, 
      selected: [], 
      list: []
    });

    // TODO: Error management
    let res = await devices.getEvents(id, {
      search: null,
      page: 1,
      length: 1
    });

    let list = [];
    if (res.data?.events[0]) {
      list = Object.keys(res.data.events[0]);
    }

    setInputMetrics({ 
      timer: null, 
      selected: [], 
      list: list.map(val => { 
        return { value: val, label: val } 
      })
    });
  } 

  const onRun = async e => {
    e.preventDefault();

    // Refresh interval management
    if (intervalFunc) {
      clearTimeout(intervalFunc);
      setIntervalFunc(null);
    }

    // Fetch and plot
    await fetchAndPlot({
      device:  inputDevices?.selected?.value?.serialNumber,
      metrics: inputMetrics.selected,
      from: from,
      to: to,
      browserTime: browserTime,
      refreshInterval: refreshInterval
    });
  }

  const fetchAndPlot = async cached => {
    if (!cached.device) {
      return ;
    }

    // Time management
    let queryFrom = cached.from, queryTo = cached.to;
    if (cached.browserTime != 'custom') {
      let now = new Date();
      queryTo = now.toISOString();
      now.setMinutes(now.getMinutes() - parseInt(cached.browserTime));
      queryFrom = now.toISOString();
    }

    let res = await devices.getAnalytic({
      devices: [cached.device],
      from: queryFrom,
      to: queryTo
    });

    // Filters on selected metrics
    series.data = [];
    cached.metrics.forEach(metric => {
      series.data.push(res.data.events.map(event => event[metric.value]));
    });

    // Last serie "converted epoch" and "created at"
    series.data.push(res.data.events.map(event => moment.tz(new Date(event.epoch * 1000), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')));
    series.data.push(res.data.events.map(event => moment.tz(new Date(event.createdAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')));

    await setSeries({
      meta: cached.metrics,
      data: series.data
    });

    generateChartSeries();

    if (cached.refreshInterval != 0) {
      setIntervalFunc(setTimeout(function() {
        fetchAndPlot(cached)
      }, parseInt(cached.refreshInterval) * 1000));
    }
  }

  const formatValue = val => {
    if (typeof val === 'number' || typeof val === 'string') {
      return val;
    } else if (typeof val === 'boolean') {
      return val === false ? 'False' : 'True'
    }
    return 'N/A';
  }

  const generateChartSeries = () => {

    let fmt = val => {
      if (typeof val === 'number') {
        return val;
      } else if (typeof val === 'boolean') {
        return !val? 0 : 1;
      }

      return null;
    }

    let res = [];
    for (let i = 0; i < series.data.length-1; i++) {
      if (i >= series.data.length - 2 || fmt(series.data[i][0]) === null) continue;

      res.push({
        name: inputMetrics.selected[i].value,
        data: JSON.parse(JSON.stringify(series.data[i])).reverse().map(val => { return fmt(val) })
      });
    }

    // X axis management
    let x = [];
    JSON.parse(JSON.stringify(series.data[series.data.length-2])).reverse().forEach(datetime => {
      x.push(datetime);
    });

    setChart({
      options: {
        chart: {
          type: 'line',
          height: 750,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'datetime',
          categories: x,
          tickAmount: 6,
          tooltip: {
            enabled: false
          },
          labels: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#2cb57e', '#C61F4C', '#F36D40', '#47bce8'],
        legend: {
          show: true
        }
      },
      data: res
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Report</h4>
                <Form onSubmit={onRun} >
                  <Row className="mt-3" >
                    <Col lg={2} >
                      <div className="mb-3">
                        <label className="form-label">Devices</label>
                        <Select
                          isMulti={false}
                          onInputChange={inputDevicesOnSearch}
                          onChange={inputDevicesOnSelect}
                          isSearchable={true}
                          options={inputDevices.list}
                          classNamePrefix="react-select"
                          isLoading={inputDevices.timer != null}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              boxShadow: 'none'
                            })
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={3} >
                      <div className="mb-3">
                        <label className="form-label">Metrics</label>
                        <Select
                          isMulti={true}
                          onChange={inputMetricsOnSelect}
                          value={inputMetrics.selected}
                          isSearchable={true}
                          options={inputMetrics.list}
                          classNamePrefix="react-select"
                          isLoading={inputMetrics.timer != null}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              boxShadow: 'none'
                            })
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={2} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Browser Time</Label>
                        <select className="form-select"
                          defaultValue={browserTime}
                          onChange={e => setBrowserTime(e.target.value) }
                          >
                          <option value="custom">Custom time range</option>
                          <option value="5">Last 5 minutes</option>
                          <option value="15">Last 15 minutes</option>
                          <option value="30">Last 30 minutes</option>
                          <option value="60">Last 1 hour</option>
                          <option value="120">Last 3 hours</option>
                          <option value="360">Last 6 hours</option>
                          <option value="720">Last 12 hours</option>
                          <option value="1440">Last 24 hours</option>
                          <option value="2880">Last 2 days</option>
                          <option value="10080">Last 7 days</option>
                          <option value="20160">Last 14 days</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">From</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="YYYY-MM-DD"
                          options={{
                            altInput: true,
                            altFormat: "Y-m-d H:i",
                            dateFormat: "YYYY-MM-DD H:i",
                            enableTime: true,
                            time_24hr: true
                          }}
                          value={new Date(from)}
                          onChange={e => setFrom(new Date(e).toISOString()) }
                          disabled={browserTime != 'custom'}
                          key={browserTime}
                        />
                      </div>
                    </Col>
                    <Col lg={2} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">To</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="YYYY-MM-DD"
                          options={{
                            altInput: true,
                            altFormat: "Y-m-d H:i",
                            dateFormat: "YYYY-MM-DD H:i",
                            enableTime: true,
                            time_24hr: true
                          }}

                          value={new Date(to)}
                          onChange={e => setTo(new Date(e).toISOString()) }
                          disabled={browserTime !== 'custom'}
                          key={browserTime}
                        />
                      </div>
                    </Col>
                    <Col lg={1} >
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Refresh</Label>
                        <select className="form-select"
                          defaultValue={refreshInterval}
                          onChange={e => setRefreshInterval(e.target.value) }
                          >
                          <option value="0">Off</option>
                          <option value="15">15s</option>
                          <option value="30">30s</option>
                          <option value="60">1m</option>
                          <option value="300">5m</option>
                          <option value="900">15m</option>
                          <option value="1800">30m</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success waves-effect waves-light" >
                        Run query
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <ReactApexChart
                  options={chart.options}
                  series={chart.data}
                  type="line"
                  height="500"
                />
              </CardBody>           
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Table className="table table-striped mb-0">
                    {series.meta.length > 0 && <thead>
                      <tr>
                        {series.meta.map((val, idx) => (
                          <th key={idx}>
                            {val.value}
                          </th>
                        ))}
                        <th>Converted Device Time</th>
                        <th>Received at</th>
                      </tr>
                    </thead>}

                    <tbody>
                      {series.meta.length === 0 && <tr>
                        <td className='text-center' >No metric selected...</td>
                      </tr>}

                      {series?.data[0]?.length <= 1 && <tr>
                        <td colSpan={series.meta.length + 2} className='text-center' >No data available...</td>
                      </tr>}

                      {series.data.length >= 1 && 
                        series.data[0].map((row, idRow) => (
                          <tr key={idRow}>
                            {series.data.map((col, idCol) => (
                              <td key={idCol}>
                                {formatValue(series.data[idCol][idRow])}
                              </td>
                            ))}
                          </tr>
                        ))
                      }


                    </tbody>
                  </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AnalyticPage;
