import React from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useHistory } from 'react-router-dom'


const SessionsComponent = () => {
  return (

    <Card>
      <CardBody>
        <Form onSubmit={() => { console.log('Submit'); }}>
          <Row className="mb-3" >
            <Col lg={12} >
              <h4 className="card-title">Sessions</h4>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="text-center mb-4">
                Coming soon..
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default SessionsComponent;
