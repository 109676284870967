import PlatformLayout from './platform.layout'

import Home from './Home/home.page'
import Settings from './Settings/settings.page'

// Quality Assurance
import Audit from './QualityAssurance/audit.page'
import NewProcedure from './QualityAssurance/newProcedure.page'
import Procedure from './QualityAssurance/procedure.page'
import Production from './QualityAssurance/production.page'
import ProcedureSummary from './QualityAssurance/procedureSummary.page'
import Report from './QualityAssurance/report.page'

// Admin
import UsersPage from './Admin/users.page'
import UserPage from './Admin/user.page'
import SerialNumbersPage from './Admin/serialnumbers.page'
import SerialNumberPage from './Admin/serialnumber.page'
import EventsPage from './Admin/events.page'
import EventPage from './Admin/event.page'
import CenterPage from './Admin/center.page'
import CentersPage from './Admin/centers.page'
import MunicipalityPage from './Admin/municipality.page'
import MunicipalitiesPage from './Admin/municipalities.page'

// Technician
import AnalyticPage from './Technician/Analytic/analytic.page'
import Alerts from './Technician/Alerts/alerts.page'
import Alert from './Technician/Alerts/alert.page'
import DevicePage from './Technician/Devices/device.page'
import DeviceEditPage from './Technician/Devices/deviceEdit.page'
import DevicesPage from './Technician/Devices/devices.page'
import SitesPage from './Technician/Sites/sites.page'
import SitePage from './Technician/Sites/site.page'

// Operator
import AlarmPage from './Operator/alarm.page'
import AlarmsPage from './/Operator/alarms.page'

const PlatformRoutes = [
  { path: '/', layout: PlatformLayout, component: Home },
  { path: '/admin/users/:query?', layout: PlatformLayout, component: UsersPage },
  { path: '/admin/user/:id', layout: PlatformLayout, component: UserPage },
  { path: '/admin/serialnumbers/:query?', layout: PlatformLayout, component: SerialNumbersPage },
  { path: '/admin/serialnumber/:id', layout: PlatformLayout, component: SerialNumberPage },
  { path: '/admin/events/:query?', layout: PlatformLayout, component: EventsPage },
  { path: '/admin/event/:id', layout: PlatformLayout, component: EventPage },

  { path: '/admin/centers/:query?', layout: PlatformLayout, component: CentersPage },
  { path: '/admin/center/:id', layout: PlatformLayout, component: CenterPage },
  { path: '/admin/municipalities/:query?', layout: PlatformLayout, component: MunicipalitiesPage },
  { path: '/admin/municipality/:id', layout: PlatformLayout, component: MunicipalityPage },

  { path: '/technician/alerts/:query?', layout: PlatformLayout, component: Alerts },
  { path: '/technician/alert/:id', layout: PlatformLayout, component: Alert },
  { path: '/technician/analytic/:query?', layout: PlatformLayout, component: AnalyticPage },
  { path: '/technician/devices/:query?', layout: PlatformLayout, component: DevicesPage },
  { path: '/technician/device/edit/:id', layout: PlatformLayout, component: DeviceEditPage },
  { path: '/technician/device/:id/:query?', layout: PlatformLayout, component: DevicePage },
  { path: '/technician/sites/:query?', layout: PlatformLayout, component: SitesPage },
  { path: '/technician/site/:id/:section', layout: PlatformLayout, component: SitePage },
  { path: '/technician/site/:id/:section/:query?', layout: PlatformLayout, component: SitePage },

  { path: '/operator/alerts/:query?', layout: PlatformLayout, component: AlarmsPage },
  { path: '/operator/alert/:id?', layout: PlatformLayout, component: AlarmPage },

  { path: '/quality-assurance/audit/:query?', layout: PlatformLayout, component: Audit },
  { path: '/quality-assurance/production', layout: PlatformLayout, component: Production },
  { path: '/quality-assurance/production/:procedure', layout: PlatformLayout, component: NewProcedure },
  { path: '/quality-assurance/production/:procedure/:id', layout: PlatformLayout, component: Procedure },
  { path: '/quality-assurance/production/:procedure/:id/summary', layout: PlatformLayout, component: ProcedureSummary },
  { path: '/quality-assurance/report/:query?', layout: PlatformLayout, component: Report },

  { path: '/settings/:section', layout: PlatformLayout, component: Settings }
]

export default PlatformRoutes;