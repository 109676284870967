import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form } from 'reactstrap'
import { useParams } from 'react-router-dom'

import { sites } from './../../../../actions/index'

const SiteBuilding = props => {
  const params = useParams();
  let [errors, setErrors] = useState({}),
  [isFormChanged, setIsFormChanged] = useState(false),
    building = {
      infoBuildingType: useRef(null),
      infoBuildingFloors: useRef(null),
      infoBuildingUse: useRef(null),
      infoBuildingAdjoiningNeighbor: useRef(null),
      infoMainDoor: useRef(null),
      infoStreetFacing: useRef(null),
      infoAttachedGarage: useRef(null),
      infoFirePlace: useRef(null),
      infoGasStove: useRef(null),
      infoEmergencyExitByFloor: useRef(null),
      yearConstructionOrRenovation: useRef(null),
      addressVisibleAtNightWinter: useRef(null),

      riskPropane: useRef(null),
      riskDog: useRef(null),
      riskDangerousProduct: useRef(null),
      riskFoam: useRef(null),
      riskElectricCar: useRef(null),
      riskNeighboringStore: useRef(null)
    }

  const defaultValues = {
      infoBuildingType: props.site?.building?.infoBuildingType,
      infoBuildingFloors: props.site?.building?.infoBuildingFloors,
      infoBuildingUse: props.site?.building?.infoBuildingUse,
      infoBuildingAdjoiningNeighbor: props.site?.building?.infoBuildingAdjoiningNeighbor,
      infoMainDoor: props.site?.building?.infoMainDoor,
      infoStreetFacing: props.site?.building?.infoStreetFacing,
      yearConstructionOrRenovation: props.site?.building?.yearConstructionOrRenovation,
      infoAttachedGarage: props.site?.building?.infoAttachedGarage,
      infoFirePlace: props.site?.building?.infoFirePlace,
      infoGasStove: props.site?.building?.infoGasStove,
      infoEmergencyExitByFloor: props.site?.building?.infoEmergencyExitByFloor,
      addressVisibleAtNightWinter: props.site?.building?.addressVisibleAtNightWinter,

      riskPropane: props.site?.building?.riskPropane,
      riskDog: props.site?.building?.riskDog,
      riskDangerousProduct: props.site?.building?.riskDangerousProduct,
      riskFoam: props.site?.building?.riskFoam,
      riskElectricCar: props.site?.building?.riskElectricCar,
      riskNeighboringStore: props.site?.building?.riskNeighboringStore
  }

  const _getData = () => {
    return {
      infoBuildingType: building.infoBuildingType.current?.value,
      infoBuildingFloors: building.infoBuildingFloors.current?.value,
      infoBuildingUse: building.infoBuildingUse.current?.value,
      infoBuildingAdjoiningNeighbor: building.infoBuildingAdjoiningNeighbor.current?.value,
      infoMainDoor: building.infoMainDoor.current?.value,
      infoStreetFacing: building.infoStreetFacing.current?.value,
      yearConstructionOrRenovation: building.yearConstructionOrRenovation.current?.value,
      infoAttachedGarage: building.infoAttachedGarage.current?.checked,
      infoFirePlace: building.infoFirePlace.current?.checked,
      infoGasStove: building.infoGasStove.current?.checked,
      infoEmergencyExitByFloor: building.infoEmergencyExitByFloor.current?.checked,
      addressVisibleAtNightWinter: building.addressVisibleAtNightWinter.current?.checked,

      riskPropane: building.riskPropane.current?.checked,
      riskDog: building.riskDog.current?.checked,
      riskDangerousProduct: building.riskDangerousProduct.current?.checked,
      riskFoam: building.riskFoam.current?.checked,
      riskElectricCar: building.riskElectricCar.current?.checked,
      riskNeighboringStore: building.riskNeighboringStore.current?.checked
    }
  }

  const onFormChange = () => {
    let data = _getData();

    if (JSON.stringify(data) != JSON.stringify(defaultValues)) {
      setIsFormChanged(true);
      return ;
    }
    setIsFormChanged(false);
  }

  const onSubmit = async e => {
    e.preventDefault();

    var data = _getData();

    let res = await sites.updateBuilding(params.id, data);
    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <Form onSubmit={onSubmit}>
            <button
              type="submit"
              className="btn btn-sm btn-success waves-effect waves-light float-right" 
              disabled={!isFormChanged} >
              Sauvegarder
            </button>
            <h4 className="text-center"><strong>Bâtiment</strong></h4>


            <Row className="mt-4">
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type de batiment</label>
                  <select className="form-select"
                    defaultValue={defaultValues.infoBuildingType}
                    ref={input => (building.infoBuildingType.current = input)} 
                    onChange={onFormChange} >
                    <option value={null}></option>
                    <option value="single-family">Maison unifamilliale</option>
                    <option value="twin">Maison jumellée</option>
                    <option value="townhouse">Maison en rangée</option>
                    <option value="duplex">Duplex</option>
                    <option value="triplexOrMore">Triplex et plus</option>
                    <option value="apartment">Appartement</option>
                    <option value="condo">Condo</option>
                    <option value="garage">Garage</option>
                    <option value="other">Autre</option>
                  </select>
                </div>
              </Col>
              <Col lg="12" xl="6">
                <div className="position-relative">
                  <label className="fw-semibold">Année de construction / Réno majeur</label>
                  <select className="form-select"
                    defaultValue={defaultValues.yearConstructionOrRenovation}
                    ref={input => (building.yearConstructionOrRenovation.current = input)}
                    onChange={onFormChange} >
                    <option value={null}></option>
                    <option value="before-1990">Avant 1990</option>
                    <option value="1991-2015">1991-2015</option>
                    <option value="after-2016">Après 2016</option>
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12" xl="6">
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Étages (Incluant S-Sol)</label>
                      <select className="form-select"
                        defaultValue={defaultValues.infoBuildingFloors}
                        ref={input => (building.infoBuildingFloors.current = input)} 
                        onChange={onFormChange} >
                        <option value={null}></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Porte Principale dans le secteur pompier</label>
                      <select className="form-select"
                        defaultValue={defaultValues.infoMainDoor}
                        ref={input => (building.infoMainDoor.current = input)} 
                        onChange={onFormChange} >
                        <option value={null}></option>
                        <option value="a">A</option>
                        <option value="ad">AD</option>
                        <option value="d">D</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12" xl="6">
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Usage principal</label>
                      <select className="form-select"
                        defaultValue={defaultValues.infoBuildingUse}
                        ref={input => (building.infoBuildingUse.current = input)} 
                        onChange={onFormChange} >
                        {Object.keys(sites.BUILDING_USAGE).map((key, idx) => (
                          <option key={idx} value={key} > {sites.BUILDING_USAGE[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Rue est face au secteur pompier</label>
                      <select className="form-select"
                        defaultValue={defaultValues.infoStreetFacing}
                        ref={input => (building.infoStreetFacing.current = input)} 
                        onChange={onFormChange} >
                        <option value={null}></option>
                        <option value="ad">AD</option>
                        <option value="ab">AB</option>
                        <option value="bc">BC</option>
                        <option value="cd">CD</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-3" >
              <Col lg="12" xl="5">
                <div className="position-relative">
                  <label className="fw-semibold">Cloison mitoyenne avec voisin</label>
                  <select className="form-select"
                    defaultValue={defaultValues.infoBuildingAdjoiningNeighbor}
                    ref={input => (building.infoBuildingAdjoiningNeighbor.current = input)} 
                    onChange={onFormChange} >
                    <option value={null}></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className='mt-4'>
                <h4 className='card-title text-center text-white'>Secteurs pompiers</h4>
                <img src="/sector.png" className="mx-auto d-block" />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.infoAttachedGarage}
                    ref={building.infoAttachedGarage} 
                    onChange={onFormChange} />

                  <label className="fw-semibold pt-1">Garage Attaché <small className="text-danger" >{errors?.infoAttachedGarage && `● ${errors?.infoAttachedGarage}`}</small></label>
                </div>
              </Col>
              <Col md="4" className='ps-5'>
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.infoGasStove}
                    ref={building.infoGasStove} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Cuisinière au gaz <small className="text-danger" >{errors?.infoGasStove && `● ${errors?.infoGasStove}`}</small></label>

                </div>
              </Col>
              <Col md="4" className='ps-5'>
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.infoFirePlace}
                    ref={building.infoFirePlace} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Foyer <small className="text-danger" >{errors?.infoFirePlace && `● ${errors?.infoFirePlace}`}</small></label>

                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="4" className='ps-5'>
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.infoEmergencyExitByFloor}
                    ref={building.infoEmergencyExitByFloor} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Pièce(s) sans 2e sortie de secours dégagée <small className="text-danger" >{errors?.infoEmergencyExitByFloor && `● ${errors?.infoEmergencyExitByFloor}`}</small></label>

                </div>
              </Col>
              <Col md="4" className='ps-5'>
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.addressVisibleAtNightWinter}
                    ref={building.addressVisibleAtNightWinter} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">No civique non visible le soir en hiver <small className="text-danger" >{errors?.addressVisibleAtNightWinter && `● ${errors?.addressVisibleAtNightWinter}`}</small></label>

                </div>
              </Col>
            </Row>

            <Row className="mt-3 mb-4">
              <Col md="12">
                <h4 className='card-title text-left text-white'>Risques particuliers</h4>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskPropane}
                    ref={building.riskPropane} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">400 lb de propane <small className="text-danger" >{errors?.riskPropane && `● ${errors?.riskPropane}`}</small></label>

                </div>
              </Col>
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskDog}
                    ref={building.riskDog}
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Chien dangeureux <small className="text-danger" >{errors?.riskDog && `● ${errors?.riskDog}`}</small></label>

                </div>
              </Col>
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskDangerousProduct}
                    ref={building.riskDangerousProduct} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Qté produit dangeureux <small className="text-danger" >{errors?.riskDangerousProduct && `● ${errors?.riskDangerousProduct}`}</small></label>

                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskFoam}
                    ref={building.riskFoam} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Foam non-couvert <small className="text-danger" >{errors?.riskFoam && `● ${errors?.riskFoam}`}</small></label>

                </div>
              </Col>
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskElectricCar}
                    ref={building.riskElectricCar}
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Auto Élect. à l'intérieur <small className="text-danger" >{errors?.riskElectricCar && `● ${errors?.riskElectricCar}`}</small></label>

                </div>
              </Col>
              <Col md="4" className="ps-5" >
                <div className="form-check form-switch form-switch-lg" >
                  <input className="form-check-input" type="checkbox"
                    defaultChecked={defaultValues.riskNeighboringStore}
                    ref={building.riskNeighboringStore} 
                    onChange={onFormChange} />
                  <label className="fw-semibold pt-1">Commerce voisin <small className="text-danger" >{errors?.riskNeighboringStore && `● ${errors?.riskNeighboringStore}`}</small></label>

                </div>
              </Col>
            </Row>
          </Form>

        </CardBody>
      </Card>
    </Col>
  );
}



export default SiteBuilding;