import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'

import Loader from './../../../components/Common/Loader'
import { centers, users } from './../../../actions/index'


const UserPage = () => {
  const params = useParams(),
    history = useHistory(),
    [user, setUser] = useState({}),
    [centersList, setCentersList] = useState([]),
    [errors, setErrors] = useState({}),
    [centralDisabled, setCentralDisabled] = useState(false),
    [timezones, setTimezones] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [selectedTimezone, setSelectedTimezone] = useState('');

  useEffect(async () => {
    setTimezones(moment.tz.names());
    setSelectedTimezone(moment.tz.guess());

    let tmp = await centers.search({
      search: null,
      page: 1,
      sort: null,
      length: 250
    });

    setCentersList(tmp.data.centers);

    // TODO: Error management
    if (params.id !== 'add') {
      let res = await users.getUser(params.id);
      setUser(res.data);
      setCentralDisabled(res.data.role != "operator");
      setSelectedTimezone(res.data.timezone);
    }

    // Stop loading
    setIsLoading(false);
  }, [])


  let inputs = {};
  const onSubmit = async e => {
    e.preventDefault();

    // Get data
    let data = {}
    for (const key in inputs) {
      data[key] = inputs[key].value;
    }

    // Reset error
    setErrors({})

    if (params.id === 'add') {
      return await onAdd(data);
    }
    return await onEdit(data);
  }

  const onAdd = async data => {
    data.timezone = selectedTimezone;
    let res = await users.onCreateUser(data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    history.push(`/admin/user/${res.data._id}`);
    setUser(res.data);
  }

  const onEdit = async data => {
    data.timezone = selectedTimezone;

    // Optional password management
    if (data.password.trim() === '') data.password = undefined;

    let res = await users.onEditUser(params.id, data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    setUser(res.data);
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row className="mb-3" >
                    <Col lg={12} >
                      <h4 className="card-title">{params.id === 'add' ? 'New user' : `Edit ${user?.firstName} ${user?.lastName}`}</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">First Name <small className="text-danger" >{errors.firstName && `● ${errors.firstName}`}</small></Label>
                        <input className={`form-control ${errors.firstName && 'is-invalid'}`}
                          type="text"
                          placeholder="First name.."
                          defaultValue={user?.firstName}
                          ref={input => (inputs.firstName = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Last Name <small className="text-danger" >{errors.lastName && `● ${errors.lastName}`}</small></Label>
                        <input className={`form-control ${errors.firstName && 'is-invalid'}`}
                          type="text"
                          placeholder="Last name.."
                          defaultValue={user?.lastName}
                          ref={input => (inputs.lastName = input)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Email <small className="text-danger" >{errors.email && `● ${errors.email}`}</small></Label>
                        <input className={`form-control ${errors.email && 'is-invalid'}`}
                          type="text"
                          placeholder="Email.."
                          defaultValue={user?.email}
                          ref={input => (inputs.email = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Phone Number <small className="text-danger" >{errors.phoneNumber && `● ${errors.phoneNumber}`}</small></Label>
                        <input className={`form-control ${errors.phoneNumber && 'is-invalid'}`}
                          type="text"
                          placeholder="Phone number.."
                          defaultValue={user?.phoneNumber}
                          ref={input => (inputs.phoneNumber = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Role</label>
                        <select className="form-select"
                          defaultValue={user?.role}
                          ref={input => (inputs.role = input)}
                          onChange={(e) => { setCentralDisabled(e.target.value != "operator") }} >
                          <option value="admin">Admin</option>
                          <option value="producer">Producer</option>
                          <option value="operator">Opérateur de centrale</option>
                          <option value="risc">RISC</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Centrale (Opérateur de centrale seulement)</label>
                        <select className="form-select"
                          defaultValue={user?.central}
                          ref={input => (inputs.central = input)}
                          disabled={centralDisabled} >
                          <option value={null} > </option>
                          {Object.keys(centersList).map((key, idx) => (
                            <option key={idx} value={centersList[key]._id} > {centersList[key].name} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Password <small className="text-danger" >{errors.password && `● ${errors.password}`}</small></Label>
                        <input className={`form-control ${errors.password && 'is-invalid'}`}
                          type="password"
                          placeholder="Password.."
                          ref={input => (inputs.password = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Status</label>
                        <select className="form-select"
                          defaultValue={user?.status}
                          ref={input => (inputs.status = input)} >
                          <option value="disabled" >Disabled</option>
                          <option value="enabled" >Enabled</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Fuseau horaire</label>
                        <select className="form-select"
                          value={selectedTimezone}
                          onChange={e => setSelectedTimezone(e.target.value)}
                          ref={input => (inputs.timezone = input)} >
                          {timezones.map((timezone) => (
                            <option key={timezone} value={timezone}>{timezone}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>

                    </Col>
                  </Row>

                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-success waves-effect waves-light" >
                        {params.id === 'add' ? 'Add' : 'Save'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default UserPage;
