import React, { useState, useEffect } from 'react'
import { Card, Button } from 'reactstrap'
import { Link, useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { core, sites } from './../../../../actions/index'

const SiteSidebar = () => {
  const params = useParams(), history = useHistory(),
  [user, setUser] = useState({});

  useEffect(() => {
    let ref = core.sub('me', user => {
      setUser(user);
    });

    return () => {
      core.unSub(ref);
    }
  }, [])

  const onDelete = e => {
    e.preventDefault();

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce site?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {

        // TODO: Error management
        await sites.delete(params.id);
        history.push(`/technician/sites`);
      }
    })
  }

  return (
    <Card className="email-leftbar me-3">

      <div className="mail-list mt-4">
        <Link to={`/technician/site/${params?.id}/owner`} className={params?.section === 'owner' ? 'active' : null} >
          <i className="fas fa-user me-2"></i> Citoyen
        </Link>
        <Link to={`/technician/site/${params?.id}/building`} className={params?.section === 'building' ? 'active' : null} >
          <i className="fas fa-home me-2"></i> Bâtiment
        </Link>
        <Link to={`/technician/site/${params?.id}/devices`} className={params?.section === 'devices' ? 'active' : null} >
          <svg xmlns="http://www.w3.org/2000/svg" className="pentagone-icon site" viewBox="0 0 16 16"> <path d="m8 1.288 6.842 5.56L12.267 15H3.733L1.158 6.847 8 1.288zM16 6.5 8 0 0 6.5 3 16h10l3-9.5z"/> </svg> Pentagones
        </Link>
        <Link to={`/technician/site/${params?.id}/occupants`} className={params?.section === 'occupants' ? 'active' : null} >
          <i className="fas fa-users me-2"></i> Occupants
        </Link>
        <Link to={`/technician/site/${params?.id}/mayday`} className={params?.section === 'mayday' ? 'active' : null} >
          <i className="fas fa-fire me-2"></i> Voisins
        </Link>

        <Link to={`/technician/site/${params?.id}/contacts`} className={params?.section === 'contacts' ? 'active' : null} >
          <div className="d-flex align-items-center">
            <i className="fas fa-phone me-2"></i>
            <span className="text-wrap">Personnes <br/>ressources</span>
          </div>
        </Link>
        <Link to={`/technician/site/${params?.id}/animals`} className={params?.section === 'animals' ? 'active' : null} >
          <i className="fas fa-dog me-2"></i> Animaux
        </Link>
        
        { user?.role != 'risc' && <Link to={`/technician/site/${params?.id}/history`} className={params?.section === 'history' ? 'active' : null} >
          <i className="fas fa-history me-2"></i> Historique
        </Link> }
        {/* <Link to={`/technician/site/${params?.id}/contract`} className={params?.section === 'contract' ? 'active' : null} >
            <i className="fas fa-file-contract me-2"></i> Contrat
          </Link> */}
      </div>

      { user?.role != 'risc' && <Button type="button"
        className="btn btn-danger btn-block waves-effect waves-light fw-semibold mt-3 mb-2"
        onClick={onDelete} >
        Supprimer le site
      </Button> }

    </Card>
  );
}

export default SiteSidebar;
