import NO_IMG from './../../../assets/images/coming-soon-img.png'

import IMG_CELL_1 from './../../../assets/images/production/CELL-1.png'
import IMG_CELL_2 from './../../../assets/images/production/CELL-2.png'
import IMG_CELL_3 from './../../../assets/images/production/CELL-3.png'

import IMG_HEAD_1 from './../../../assets/images/production/HEAD-1.png'
import IMG_HEAD_2 from './../../../assets/images/production/HEAD-2.png'
import IMG_HEAD_3 from './../../../assets/images/production/HEAD-3.png'
import IMG_HEAD_4 from './../../../assets/images/production/HEAD-4.png' 
import IMG_HEAD_5 from './../../../assets/images/production/HEAD-5.png' 
import IMG_HEAD_6 from './../../../assets/images/production/HEAD-6.png' 
import IMG_HEAD_7 from './../../../assets/images/production/HEAD-7.png'

import IMG_SUPERCAP_1 from './../../../assets/images/production/SUPERCAP-1.png'
import IMG_SUPERCAP_2 from './../../../assets/images/production/SUPERCAP-2.png'

import IMG_WIFI_1 from './../../../assets/images/production/WIFI-1.png'
import IMG_WIFI_2 from './../../../assets/images/production/WIFI-2.png'
import IMG_WIFI_3 from './../../../assets/images/production/WIFI-3.png'
import IMG_WIFI_4 from './../../../assets/images/production/WIFI-4.png'

import IMG_110V_1 from './../../../assets/images/production/110V-1.png'
import IMG_110V_2 from './../../../assets/images/production/110V-2.png'
import IMG_110V_3 from './../../../assets/images/production/110V-3.png'
import IMG_110V_4 from './../../../assets/images/production/110V-4.png'
import IMG_110V_5 from './../../../assets/images/production/110V-5.png'

import IMG_MAIN_1 from './../../../assets/images/production/MAIN-1.png'
import IMG_MAIN_2 from './../../../assets/images/production/MAIN-2.png'
import IMG_MAIN_3 from './../../../assets/images/production/MAIN-3.png'
import IMG_MAIN_4 from './../../../assets/images/production/MAIN-4.png'
import IMG_MAIN_5 from './../../../assets/images/production/MAIN-5.png'

import IMG_SWITCH_1 from './../../../assets/images/production/SWITCH-1.png'
import IMG_SWITCH_2 from './../../../assets/images/production/SWITCH-2.png'
import IMG_SWITCH_3 from './../../../assets/images/production/SWITCH-3.png'
import IMG_SWITCH_4 from './../../../assets/images/production/SWITCH-4.png'
import IMG_SWITCH_5 from './../../../assets/images/production/SWITCH-5.png'
import IMG_SWITCH_6 from './../../../assets/images/production/SWITCH-6.png'
import IMG_SWITCH_7 from './../../../assets/images/production/SWITCH-7.png'
import IMG_SWITCH_8 from './../../../assets/images/production/SWITCH-8.png'
import IMG_SWITCH_9 from './../../../assets/images/production/SWITCH-9.png'
import IMG_SWITCH_10 from './../../../assets/images/production/SWITCH-10.png'
import IMG_SWITCH_11 from './../../../assets/images/production/SWITCH-11.png'

import IMG_POWER_UP_2 from './../../../assets/images/production/POWER_UP-2.jpg'
import IMG_POWER_UP_3 from './../../../assets/images/production/POWER_UP-3.jpg'
import IMG_POWER_UP_4 from './../../../assets/images/production/POWER_UP-4.jpg'
import IMG_POWER_UP_5 from './../../../assets/images/production/POWER_UP-5.jpg'
import IMG_POWER_UP_6 from './../../../assets/images/production/POWER_UP-6.jpg'
import IMG_POWER_UP_7 from './../../../assets/images/production/POWER_UP-7.jpg'
import IMG_POWER_UP_8 from './../../../assets/images/production/POWER_UP-8.jpg'
import IMG_POWER_UP_9 from './../../../assets/images/production/POWER_UP-9.jpg'
import IMG_POWER_UP_10 from './../../../assets/images/production/POWER_UP-10.jpg'

const data = {
  mainXTResistance: {
    title: 'A-1 MAIN XT BAT',
    description: 'Main XT impedance and short',
    name: 'mainXTResistance',
    image: 'fas fa-car-battery',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_MAIN_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'Battery +/- resistance',
      description: 'Measure resistance between + and – battery pad. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'batteryResistance',
      image: IMG_MAIN_2,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 675
    }, {
      id: '03',
      title: 'TPJ11/Battery - resistance',
      description: 'Measure resistance between TPJ11 and – battery pad. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'TPJ11Resistance',
      image: IMG_MAIN_3,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 70
    }, {
      id: '04',
      title: 'TPJ15/Battery - resistance',
      description: 'Measure resistance between TPJ15 and – battery pad.',
      name: 'TPJ15Resistance',
      image: IMG_MAIN_4,
      label: 'Resistance (in kΩ)',
      expected: '> 30 kΩ',
      distributionGroupBy: 10
    }, {
      id: '05',
      title: 'TPJ17/Battery - resistance',
      description: 'Measure resistance between TPJ17 and – battery pad.',
      name: 'TPJ17Resistance',
      image: IMG_MAIN_5,
      label: 'Resistance (in kΩ)',
      expected: '> 30 kΩ',
      distributionGroupBy: 10
    }]
  },
  mainXTPowerUp: {
    title: 'A-2 MAIN XT BAT POWER UP',
    description: 'Main XT power up',
    name: 'mainXTPowerUp',
    image: 'fas fa-power-off',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Enter PCB label sticker number.',
      name: 'serialNumber',
      image: NO_IMG,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'Set power supply',
      description: 'Set power supply to 3.3V and 0.100 A.',
      name: 'setPowerSupply',
      image: IMG_POWER_UP_2,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '03',
      title: 'Wire multimeter',
      description: 'Wire multimeter and powersupply.',
      name: 'wireMultimeterAndPowersupply',
      image: IMG_POWER_UP_3,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '04',
      title: 'Wire RED clip',
      description: 'Put RED (+) clip to master disconnect part.',
      name: 'wireRedClip',
      image: IMG_POWER_UP_4,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '05',
      title: 'Wire BLACK clip',
      description: 'Put BLACK (-) clip to metal ground plate finger connector.',
      name: 'wireBlackClip',
      image: IMG_POWER_UP_5,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '06',
      title: 'Measure current',
      description: 'Put multimeter in uA range, turn power ON, measure current, and after, turn power OFF.',
      name: 'current',
      image: IMG_POWER_UP_6,
      label: 'Current (in uA)',
      expected: '< 2 uA',
      distributionGroupBy: 0.025
    }, {
      id: '07',
      title: 'J11.2/J11.3 jumper',
      description: 'Put a jumper wire between J11.2 and J11.3.',
      name: 'J2_J3Jumper',
      image: IMG_POWER_UP_7,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '08',
      title: 'Measure current with J11.2/J11.3 jumper',
      description: 'Put multimeter in mA range, turn power ON, measure current, and after, turn power OFF.',
      name: 'currentJ2_J3Jumper',
      image: IMG_POWER_UP_8,
      label: 'Current (in mA)',
      expected: '1 mA - 10 mA',
      distributionGroupBy: 0.37
    }, {
      id: '09',
      title: 'J11.2/J11.4 jumper',
      description: 'Put a jumper wire between J11.2 and J11.4.',
      name: 'J2_J4Jumper',
      image: IMG_POWER_UP_9,
      label: null,
      expected: null,
      distributionGroupBy: null
    }, {
      id: '10',
      title: 'Measure current with J11.2/J11.4 jumper',
      description: 'Put multimeter in mA range, turn power ON, measure current and after, turn power OFF.',
      name: 'currentJ2_J4Jumper',
      image: IMG_POWER_UP_10,
      label: 'Current (in mA)',
      expected: '15 mA - 30 mA',
      distributionGroupBy: 1.5
    }]
  },
  headXTResistance: {
    title: 'B HEAD XT',
    description: 'Head XT impedance and short',
    name: 'headXTResistance',
    image: 'fas fa-microchip',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_HEAD_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'CO Sensor',
      description: 'Scan barcode on CO sensor.',
      name: 'scanCOSensor',
      image: IMG_HEAD_2,
      label: 'Barcode',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '03',
      title: 'TPJ1/TP1 resistance',
      description: 'Measure resistance between TPJ1 and TP1. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'TPJ1Resistance',
      image: IMG_HEAD_3,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 3000
    }, {
      id: '04',
      title: 'TPJ2/TP1 resistance',
      description: 'Measure resistance between TPJ2 and TP1. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'TPJ2Resistance',
      image: IMG_HEAD_4,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 500
    }, {
      id: '05',
      title: 'TPJ3/TP1 resistance',
      description: 'Measure resistance between TPJ3 and TP1. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'TPJ3Resistance',
      image: IMG_HEAD_5,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 700
    }, {
      id: '06',
      title: 'TPJ4/TP1 resistance',
      description: 'Measure resistance between TPJ4 and TP1.',
      name: 'TPJ4Resistance',
      image: IMG_HEAD_6,
      label: 'Resistance (in kΩ)',
      expected: '> 200 kΩ',
      distributionGroupBy: 20
    }, {
      id: '07',
      title: 'TPJ11/TP1 resistance',
      description: 'Measure resistance between TPJ11 and TP1. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'TPJ11Resistance',
      image: IMG_HEAD_7,
      label: 'Resistance (in kΩ)',
      expected: '> 100 kΩ',
      distributionGroupBy: 800
    }]
  }, 
  wifiResistance: {
    title: 'C WIFI',
    description: 'Wifi impedance and short',
    name: 'wifiResistance',
    image: 'fas fa-wifi',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_WIFI_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'J1.1/J1.8 resistance',
      description: 'Measure resistance between red probe J1.1 and black probe J1.8. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'J1_1Resistance',
      image: IMG_WIFI_2,
      label: 'Resistance (in MΩ)',
      expected: '> 5 MΩ',
      distributionGroupBy: 0.5
    }, {
      id: '04',
      title: 'J1.4/J1.8 resistance',
      description: 'Measure resistance between red probe J1.4 and black probe J1.8. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'J1_4Resistance',
      image: IMG_WIFI_4,
      label: 'Resistance (in MΩ)',
      expected: '> 10 MΩ',
      distributionGroupBy: 2
    }, {
      id: '03',
      title: 'R13/J1.8 resistance',
      description: 'Measure resistance between red probe R13 and black probe J1.8. If measure fluctuates, write the displayed measure after 1 second. If Open Circuit, enter 9999.',
      name: 'R13Resistance',
      image: IMG_WIFI_3,
      label: 'Resistance (in kΩ)',
      expected: '> 80 kΩ',
      distributionGroupBy: 5
    }]
  },
  switchResistance: {
    title: 'D SWITCH',
    description: 'Switch impedance and short',
    name: 'switchResistance',
    image: 'fas fa-network-wired',
    optional: true,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_SWITCH_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'J1.1/J1.2 resistance',
      description: 'Hook the red probe on J1.1, the black probe on J1.2 and measure resistance.',
      name: 'J1_J2Resistance',
      image: IMG_SWITCH_2,
      label: 'Resistance (in MΩ)',
      expected: '> 1 MΩ',
      optional: false,
      distributionGroupBy: 0.1
    }, {
      id: '03',
      title: 'Press SW1 and J1.1/J1.2 resistance',
      description: 'Press SW1 (lever) and measure resistance J1.1/J1.2.',
      name: 'SW1_J1_J2Resistance',
      image: IMG_SWITCH_3,
      label: 'Resistance (in Ω)',
      expected: '< 1 Ω',
      optional: true,
      distributionGroupBy: 0.025
    }, {
      id: '04',
      title: 'Press SW2 and J1.1/J1.2 resistance',
      description: 'Press SW2 (black) and measure resistance J1.1/J1.2.',
      name: 'SW2_J1_J2Resistance',
      image: IMG_SWITCH_4,
      label: 'Resistance (in kΩ)',
      expected: '479 kΩ - 519 kΩ',
      optional: true,
      distributionGroupBy: 40
    }, {
      id: '05',
      title: 'Press SW3 and J1.1/J1.2 resistance',
      description: 'Press SW3 (white) and measure resistance J1.1/J1.2.',
      name: 'SW3_J1_J2Resistance',
      image: IMG_SWITCH_5,
      label: 'Resistance (in kΩ)',
      expected: '239 kΩ - 259 kΩ',
      optional: true,
      distributionGroupBy: 20
    }, {
      id: '06',
      title: 'Press SW4 and J1.1/J1.2 resistance',
      description: 'Press SW4 (grey) and measure resistance J1.1/J1.2.',
      name: 'SW4_J1_J2Resistance',
      image: IMG_SWITCH_6,
      label: 'Resistance (in kΩ)',
      expected: '119 kΩ - 129 kΩ',
      optional: true,
      distributionGroupBy: 10
    }, {
      id: '07',
      title: 'Press SW5 and J1.1/J1.2 resistance',
      description: 'Press SW5 (blue) and measure resistance J1.1/J1.2.',
      name: 'SW5_J1_J2Resistance',
      image: IMG_SWITCH_7,
      label: 'Resistance (in kΩ)',
      expected: '59 kΩ - 64 kΩ',
      optional: true,
      distributionGroupBy: 5
    }, {
      id: '08',
      title: 'J1.3/J1.2 resistance',
      description: 'Hook the red probe on J1.3, the black probe on J1.2 and measure resistance.',
      name: 'J3_J2Resistance',
      image: IMG_SWITCH_8,
      label: 'Resistance (in MΩ)',
      expected: '> 1 MΩ',
      optional: false,
      distributionGroupBy: 0.1
    }, {
      id: '09',
      title: 'Press SW6 and J1.3/J1.2 resistance',
      description: 'Press SW6 (on) and measure resistance J1.3/J1.2.',
      name: 'SW6_J3_J2Resistance',
      image: IMG_SWITCH_9,
      label: 'Resistance (in Ω)',
      expected: '< 1 Ω',
      optional: true,
      distributionGroupBy: 0.025
    }, {
      id: '10',
      title: 'J1.4/J1.2 resistance',
      description: 'Hook the red probe on J1.4, the black probe on J1.2 and measure resistance.',
      name: 'J4_J2Resistance',
      image: IMG_SWITCH_10,
      label: 'Resistance (in MΩ)',
      expected: '> 1 MΩ',
      optional: false,
      distributionGroupBy: 0.1
    }, {
      id: '11',
      title: 'Press SW7 and J1.4/J1.2 resistance',
      description: 'Press SW7 (off) and measure resistance J1.4/J1.2.',
      name: 'SW7_J4_J2Resistance',
      image: IMG_SWITCH_11,
      label: 'Resistance (in Ω)',
      expected: '< 1 Ω',
      optional: true,
      distributionGroupBy: 0.025
    }]
  },
  powerResistance: {
    title: 'E 110V',
    description: 'Power impedance and short',
    name: 'powerResistance',
    image: 'fas fa-plug',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_110V_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'J6.1/D1 resistance',
      description: 'Measure resistance between J6.1 and D1.',
      name: 'J61_D1Resistance',
      image: IMG_110V_2,
      label: 'Resistance (in Ω)',
      expected: '3869 Ω - 4191 Ω',
      distributionGroupBy: 350
    }, {
      id: '03',
      title: 'J6.2/D1 resistance',
      description: 'Measure resistance between red probe on J6.2 and black probe on D1.',
      name: 'J62_D1Resistance',
      image: IMG_110V_3,
      label: 'Resistance (in MΩ)',
      expected: '1.92 MΩ - 2.08 MΩ',
      distributionGroupBy: 0.05
    }, {
      id: '04',
      title: 'J6.2/J6.4 resistance',
      description: 'Measure resistance between J6.2 and J6.4.',
      name: 'J62_J64Resistance',
      image: IMG_110V_4,
      label: 'Resistance (in kΩ)',
      expected: '141 kΩ - 153 kΩ',
      distributionGroupBy: 15
    }, {
      id: '05',
      title: 'J10/J6.4 resistance',
      description: 'Measure resistance between red probe on J10 and black probe on J6.4.',
      name: 'J10_J64Resistance',
      image: IMG_110V_5,
      label: 'Resistance (in MΩ) - Enter 9999 if Open circuit',
      expected: '> 2 MΩ',
      distributionGroupBy: 250
    }]
  },
  cellResistance: {
    title: 'G CELL',
    description: 'Cell impedance and short',
    name: 'cellResistance',
    image: 'fas fa-broadcast-tower',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_CELL_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'J3.1/J3.8 resistance',
      description: 'Measure resistance between J3.1 and J3.8.',
      name: 'J3_1Resistance',
      image: IMG_CELL_2,
      label: 'Resistance (in kΩ)',
      expected: '> 300 kΩ',
      distributionGroupBy: 3000
    }, {
      id: '03',
      title: 'J3.4/J3.8 resistance',
      description: 'Measure resistance between J3.4 and J3.8.',
      name: 'J3_4Resistance',
      image: IMG_CELL_3,
      label: 'Resistance (in kΩ)',
      expected: '> 300 kΩ',
      distributionGroupBy: 3500
    }]
  },
  supercapResistance: {
    title: 'H SUPERCAP',
    description: 'Supercap impedance and short.',
    name: 'supercapResistance',
    image: 'fas fa-microscope',
    optional: false,
    steps: [{
      id: '01',
      title: 'Serial Number',
      description: 'Write the serial number and apply label sticker to PCB.',
      name: 'serialNumber',
      image: IMG_SUPERCAP_1,
      label: 'Serial Number',
      type: 'string',
      expected: null,
      distributionGroupBy: null
    }, {
      id: '02',
      title: 'J1.1/J1.3 capacitance',
      description: 'Measure capacitance between J1.1 and J1.3.',
      name: 'J1_J3Capacitance',
      image: IMG_SUPERCAP_2,
      label: 'Capacitance (in F)',
      expected: '6.6 F - 13.2 F',
      distributionGroupBy: 0.5
    }]
  }
}

export default data;
