import axios from 'axios'

export default class Core {
  constructor(api) {
    this.subscribers = {};
    this.state = {};
    this.storage = {};

    // API initialization
    this.api = axios.create({
      baseURL: 'https://ingress-db.incendia.ca/v1/' // 
    });

    // Set API token
    this.setAuthToken(this.get('token'));

    // API generic error management
    this.apiGenericError = [{
      code: null,
      field: null,
      reason: null,
      detail: 'An error occurred while processing your request. Please try again later or contact support.'
    }];
  }

  // 
  // API call management
  // 
  setAuthToken(val) {
    this.api.defaults.headers.common['Authorization'] = val;
  }

  async apiCall(method, url, data = null) {
    let res = { data: null, errors: null }
    try {
      let query = await this.api[method](url, data);
      res.data = query.data.data;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.status === 400) {
        res.errors = err.response.data.errors;
      } else if (err.response?.data?.status === 403) {
        // Session ended or trying to access a forbidden section
        this.delete('token');
        this.setAuthToken(undefined);
        window.location.reload(true); // Hard refresh
      } else {
        res.errors = this.apiGenericError;
      }
    }

    return res;
  }

  // 
  // Pub/Sub
  // 
  sub(name, func) {
    let caller = (new Error()).stack.split('\n')[2].trim().split(' ')[1];

    // Sub callback mechanism
    if (!this.subscribers[name]) {
      this.subscribers[name] = {};
    }
    this.subscribers[name][caller] = func;

    // Return default "state" value
    if (this.state[name]) {
      this.subscribers[name][caller](this.state[name]);
    }

    // Return reference
    return caller;
  }

  unSub(ref) {

    // Find and delete
    for (const name in this.subscribers) {
      for (const caller in this.subscribers[name]) {
        if (caller === ref) {
          return delete this.subscribers[name][caller];
        }
      }
    }
  }

  pub(name, data) {
    this.state[name] = data;

    if (this.subscribers[name]) {
      for (const caller in this.subscribers[name]) {
        this.subscribers[name][caller](data);
      }
    }
  }

  // 
  // Storage 
  //
  delete(name) {
    localStorage.removeItem(name);
  }

  get(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  set(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  }
}
