import React, { useState, useRef, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form,
  Table
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import moment from 'moment'

import Loader from './../../../components/Common/Loader'
import { alerts, sites, core } from './../../../actions/index'


const AlarmPage = () => {
  const params = useParams(),
    history = useHistory(),
    [comments, setComments] = useState({}),
    [alert, setAlert] = useState({}),
    comment = useRef(null),
    [isLoading, setIsLoading] = useState(true),
    [myTimezone, setMyTimezone] = useState('');


  useEffect(async () => {

    core.sub('me', async user => {
      setMyTimezone(user.timezone);

      // TODO: Error management
      let res = await alerts.getAlert(params.id);
      setAlert(res.data);

      res = await alerts.getComments(params.id);
      setComments(res.data);

      // Stop loading
      setIsLoading(false);
    });
  }, [])

  const getPriority = (priority) => {
    switch (priority) {
      case 'warning':
        return <span className="badge bg-info me-1">AVERTISSEMENT</span>;
      case 'error':
        return <span className="badge bg-secondary me-1">ERREUR</span>;
      case 'major':
        return <span className="badge bg-warning me-1">MAJEURE</span>;
      case 'critical':
        return <span className="badge bg-danger me-1">CRITIQUE</span>;
      default:
        return <span className="badge bg-success me-1">LOG</span>;
    }
  }

  const getState = (state) => {
    switch (state) {
      case 'pending_trig':
        return <span className="badge bg-warning me-1">DÉCLENCHEMENT EN ATTENTE</span>;
      case 'pending_ok':
        return <span className="badge bg-warning me-1">RESTAURATION EN ATTENTE</span>;
      case 'ok':
        return <span className="badge bg-success me-1">RESTAURÉE</span>;
      default:
        return <span className="badge bg-danger me-1">DÉCLENCHÉ</span>;
    }
  }

  const onSubmitComment = async e => {
    e.preventDefault();

    // TODO: Error management
    await alerts.addComment(alert._id, {
      comment: comment.current?.value
    });
    window.location.reload(true);
  }

  const displayComment = (comment, idx) => {
    return <Row key={idx} >
      <Col lg={12}>
        <hr />
        <Row>
          <Col md={6}>
            <small><strong>Utilisateur:</strong> {comment.userInfo[0].firstName} {comment.userInfo[0].lastName}</small>
          </Col>
          <Col md={6} className="text-right">
            <small><strong>Heure:</strong> {moment.tz(new Date(comment.createdAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}</small>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={12}>
            <p> {comment.comment} </p>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  const displayContact = (row, idx) => {
    return <tr key={idx} >
      <td>{row.firstName}</td>
      <td>{row.lastName}</td>
      <td>{row.channels && row.channels[0].value || ''}</td>
      <td>{row.presence && 'Pas disponible' || idx == 0 && 'Pas sur place' || 'Pas répondu'}</td>
    </tr>;
  }

  const setPhoneView = (phone) => {
    if (phone === undefined || phone === "") {
      return "-"
    } else {
      if (phone.length === 10) {
        return "(" + phone.substring(0,3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6,10)
      } else if (phone.length === 11 && phone.substring(0,1) === "1") {
        return "(" + phone.substring(1,4) + ") " + phone.substring(4, 7) + "-" + phone.substring(7,11)
      } else {
        return phone
      }
    }
  }

  // TODO: Implement error and loader..
  if (!alert?._id) {
    return <div className="page-content"></div>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col md="5">
            <Card>
              <CardBody>
                <h4 className="card-title">Détails</h4>


                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <tbody>
                      <tr>
                        <th>Titre</th>
                        <td>
                          {alert?.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>
                          {alert?.description}
                        </td>
                      </tr>
                      <tr>
                        <th>Pentagone</th>
                        <td>
                          {alert?.serialNumber}
                        </td>
                      </tr>
                      <tr>
                        <th>Type</th>
                        <td>
                          {alert?.type.toUpperCase()}
                        </td>
                      </tr>
                      <tr>
                        <th>Heure</th>
                        <td>
                          {moment.tz(new Date(alert?.triggedAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}
                        </td>
                      </tr>
                      <tr>
                        <th>Priorité</th>
                        <td>
                          {getPriority(alert?.priority)}
                        </td>
                      </tr>
                      <tr>
                        <th>État</th>
                        <td> {getState(alert?.state)} </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card className="card-emphasis">
              <CardBody>
              <h4 className="card-title">Téléphone - Services d'urgence</h4>
              <div className="table-responsive">
              <Table className="table table-striped mb-0 table-emergency">
                <tbody>
                  <tr>
                    <th>Caserne pompier</th>
                    <th>Poste de police</th>
                    <th>Paramédique</th>
                  </tr>
                  <tr>
                    <td className="firephone">{setPhoneView(alert.municipality?.fireStationPhone)}</td>
                    <td className="policephone">{setPhoneView(alert.municipality?.policeStationPhone)}</td>
                    <td className="medicphone">{setPhoneView(alert.municipality?.paramedicPhone)}</td>
                  </tr>
                </tbody>
              </Table>
              </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title">Commentaires</h4>

                {comments?.length > 0 && comments?.map((comment, idx) => (
                  displayComment(comment, idx)
                ))}

                <Form onSubmit={onSubmitComment}>
                  <Row className='mt-4'>
                    <Col lg={12}>
                      <div className="mb-3">
                        <textarea className="form-control"
                          type="text"
                          placeholder="Commentaire"
                          rows={5}
                          ref={comment}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3" >
                    <Col lg={12} className="text-right" >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success waves-effect waves-light" >
                        Ajouter
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="7">
            <Card>
              <CardBody>
                <h4 className="card-title">Localisation</h4>

                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <tbody>
                      <tr>
                        <th>Client</th>
                        <td>
                          {alert.site?.owner?.clientName}
                        </td>
                      </tr>
                      <tr>
                        <th>Adresse</th>
                        <td>
                          {alert.site?.address?.street}
                        </td>
                      </tr>
                      <tr>
                        <th>Ville</th>
                        <td>
                          {alert.site?.address?.city}
                        </td>
                      </tr>
                      <tr>
                        <th>Pièce</th>
                        <td>
                          {sites.ROOM[alert.device.room]}
                        </td>
                      </tr>
                      <tr>
                        <th>Étage</th>
                        <td>
                          {sites.FLOOR[alert.device.floor]}
                        </td>
                      </tr>
                      <tr>
                        <th>Secteur</th>
                        <td>
                          {sites.SECTOR[alert.device.sector]}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title">Occupant</h4>

                <div className="table-responsive mt-4 mb-3">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Prénom</th>
                        <th style={{ width: '250px' }} >Nom</th>
                        <th style={{ width: '150px' }} >Téléphone</th>
                        <th style={{ width: '150px' }} >Statut OVI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alert.site?.occupants?.map((row, idx) => (
                        displayContact(row, idx)
                      ))}

                      {alert.site?.occupants?.length === 0 && <tr>
                        <td colSpan="4" className='text-center' >Aucun occupant...</td>
                      </tr>}
                    </tbody>
                  </Table>
                </div>

              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title">Voisins</h4>

                <div className="table-responsive mt-4 mb-3">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Prénom</th>
                        <th style={{ width: '250px' }} >Nom</th>
                        <th style={{ width: '150px' }} >Téléphone</th>
                        <th style={{ width: '150px' }} >Statut OVI</th>
                      </tr>
                    </thead>
                    <tbody>
                    {alert.site?.contacts?.filter(e => { return e.presence == 'mayday' })?.map((row, idx) => (
                        displayContact(row, idx)
                      ))}

                      {alert.site?.contacts?.filter(e => { return e.presence == 'mayday' })?.length === 0 && <tr>
                        <td colSpan="4" className='text-center' >Aucun voisin ...</td>
                      </tr>}
                    </tbody>
                  </Table>
                </div>

              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title">Personnes ressource</h4>

                <div className="table-responsive mt-4 mb-3">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Prénom</th>
                        <th style={{ width: '250px' }} >Nom</th>
                        <th style={{ width: '150px' }} >Téléphone</th>
                        <th style={{ width: '150px' }} >Statut OVI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alert.site?.contacts?.filter(e => { return e.presence == 'resource' })?.map((row, idx) => (
                        displayContact(row, idx)
                      ))}

                      {alert.site?.contacts?.filter(e => { return e.presence == 'resource' })?.length === 0 && <tr>
                        <td colSpan="4" className='text-center' >Aucune personne ressource...</td>
                      </tr>}
                    </tbody>
                  </Table>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default AlarmPage;
