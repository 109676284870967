import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ReactApexChart from "react-apexcharts";

import { sites } from './../../../../actions/index'

import Loader from './../../../../components/Common/Loader'

const SiteCommunicationStats = () => {

  const params = useParams(),
    [data, setData] = useState({ series: [], xaxis: [] }),
    [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    getSite();
  }, [])

  const getSite = async () => {

    setIsLoading(true);
    let res = await sites.getCommunications(params.id);

    // TODO: Error management
    let x = [], s = [
      { name: 'INFO', data: [] },
      { name: 'HAUTE', data: [] },
      { name: 'MOYENNE', data: [] },
      { name: 'BASE', data: [] }
    ];

    // Create series based on received data
    res.data.forEach(item => {
      if (!x.includes(item._id.date)) {
        x.push(item._id.date);

        // Push "0" everywhere
        s.forEach(arr => arr.data.push(0));
      }

      // Correct the desired counter 
      let idx = [1, 2, 3].includes(item._id.priority) ? item._id.priority : 0;
      s[idx].data[s[idx].data.length - 1] = item.count;
    });

    // Pad days without data
    let i = 0;
    for (var d = new Date(x[x.length - 1]); d <= new Date(x[0]); d.setDate(d.getDate() + 1)) {
      let date = new Date(d).toISOString().split('T')[0]
      if (!x.includes(date)) {
        x.splice(i, 0, date);
        s.forEach(arr => arr.data.splice(i, 0, 0));
      }

      i++;
    }

    setData({ xaxis: x, series: s });
    setIsLoading(false);
  }

  var options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    xaxis: {
      type: 'datetime',
      categories: data.xaxis,
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#2cb57e', '#C61F4C', '#F36D40', '#47bce8'],
    legend: {
      show: true
    },
    fill: {
      opacity: 0.75
    },
    tooltip: {
      enabled: false
    }
  };

  return (
    <div>
      {isLoading && <Loader height={'300px'} />}

      {!isLoading && <ReactApexChart
        options={options}
        series={data.series}
        type="bar"
        height="300"
        className="apex-charts"
        dir="ltr"
      />}
    </div>
  );
};

export default SiteCommunicationStats;
