import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form } from 'reactstrap'
import { useParams } from 'react-router-dom'

import { sites } from './../../../../actions/index'

const SiteOwner = props => {
  const params = useParams();
  let [billingDisabled, setBillingDisabled] = useState(false),
    [clientRepresentedByDisabled, setClientRepresentedByDisabled] = useState(false),
    [errors, setErrors] = useState({}),
    [isFormChanged, setIsFormChanged] = useState(false),
    owner = {
      account: useRef(null),
      startDate: useRef(null),

      billingSame: useRef(null),

      billingName: useRef(null),
      billingRepresentedBy: useRef(null),
      billingAddress: useRef(null),
      billingCity: useRef(null),
      billingProvince: useRef(null),
      billingPostalCode: useRef(null),
      billingEmail: useRef(null),
      billingPhone: useRef(null),

      clientName: useRef(null),
      clientRepresentedBy: useRef(null),
      clientFunction: useRef(null),
      clientAddress: useRef(null),
      clientCity: useRef(null),
      clientProvince: useRef(null),
      clientPostalCode: useRef(null),
      clientEmail: useRef(null),
      clientPhone: useRef(null),
      clientType: useRef(null)
    };

  const defaultValues = {
    account: props.site?.owner?.account,
    startDate: props.site?.owner?.startDate,

    clientName: props.site?.owner?.clientName,
    clientRepresentedBy: props.site?.owner?.clientRepresentedBy,
    clientFunction: props.site?.owner?.clientFunction,
    clientAddress: props.site?.owner?.clientAddress,
    clientCity: props.site?.owner?.clientCity,
    clientProvince: props.site?.owner?.clientProvince,
    clientPostalCode: props.site?.owner?.clientPostalCode,
    clientEmail: props.site?.owner?.clientEmail,
    clientPhone: props.site?.owner?.clientPhone,
    clientType: parseInt(props.site?.owner?.clientType) || 0,

    billingSame: props.site?.owner?.billingSame,

    billingName: props.site?.owner?.billingName,
    billingRepresentedBy: props.site?.owner?.billingRepresentedBy,
    billingAddress: props.site?.owner?.billingAddress,
    billingCity: props.site?.owner?.billingCity,
    billingProvince: props.site?.owner?.billingProvince,
    billingPostalCode: props.site?.owner?.billingPostalCode,
    billingEmail: props.site?.owner?.billingEmail,
    billingPhone: props.site?.owner?.billingPhone,
  }

  useEffect(async () => {
    setClientRepresentedByDisabled(props.site?.owner?.clientType == 1)
    setBillingDisabled(props.site?.owner?.billingSame)
  }, [])


  const _getData = () => {
    return {
      account: owner.account.current?.value,
      startDate: owner.startDate.current?.value,

      clientName: owner.clientName.current?.value,
      clientRepresentedBy: owner.clientRepresentedBy.current?.value,
      clientFunction: owner.clientFunction.current?.value,
      clientAddress: owner.clientAddress.current?.value,
      clientCity: owner.clientCity.current?.value,
      clientProvince: owner.clientProvince.current?.value,
      clientPostalCode: owner.clientPostalCode.current?.value,
      clientEmail: owner.clientEmail.current?.value,
      clientPhone: owner.clientPhone.current?.value,
      clientType: parseInt(owner.clientType.current?.value),

      billingSame: owner.billingSame.current?.checked,
      billingName: owner.billingName.current?.value,
      billingRepresentedBy: owner.billingRepresentedBy.current?.value,
      billingAddress: owner.billingAddress.current?.value,
      billingCity: owner.billingCity.current?.value,
      billingProvince: owner.billingProvince.current?.value,
      billingPostalCode: owner.billingPostalCode.current?.value,
      billingEmail: owner.billingEmail.current?.value,
      billingPhone: owner.billingPhone.current?.value
    }
  }


  const onFormChange = () => {
    let data = _getData();

    if (JSON.stringify(data) != JSON.stringify(defaultValues)) {
      setIsFormChanged(true);
      return;
    }
    setIsFormChanged(false);
  }

  const onSubmit = async e => {
    e.preventDefault();

    var data = _getData();
    let res = await sites.updateOwner(params.id, data);
    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <Form onSubmit={onSubmit}>
            <button
              type="submit"
              className="btn btn-sm btn-success waves-effect waves-light float-right"
              disabled={!isFormChanged} >
              Sauvegarder
            </button>
            <h4 className="text-center"><strong>Citoyen</strong></h4>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">No de Compte <small className="text-danger" >{errors?.account && `● ${errors?.account}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    defaultValue={defaultValues.account}
                    ref={owner.account}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Date d'ouverture <small className="text-danger" >{errors?.startDate && `● ${errors?.startDate}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    placeholder={`${new Date().toISOString().split('T')[0]}`}
                    defaultValue={defaultValues.startDate || new Date().toISOString().split('T')[0]}
                    ref={owner.startDate}
                    onChange={onFormChange}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">

                <Row className="mt-3" style={{height: '39px'}}>
                  <Col md="12">
                    <h4 className='card-title text-center'>Site Protégé</h4>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Nom du (des) Citoyen(s) ou Commerce <small className="text-danger" >{errors?.clientName && `● ${errors?.clientName}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientName}
                        ref={owner.clientName}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Adresse <small className="text-danger" >{errors?.clientAddress && `● ${errors?.clientAddress}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientAddress}
                        ref={owner.clientAddress}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Ville <small className="text-danger" >{errors?.clientCity && `● ${errors?.clientCity}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientCity}
                        ref={owner.clientCity}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Province <small className="text-danger" >{errors?.clientProvince && `● ${errors?.clientProvince}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientProvince}
                        ref={owner.clientProvince}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Code Postal <small className="text-danger" >{errors?.clientPostalCode && `● ${errors?.clientPostalCode}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientPostalCode}
                        ref={owner.clientPostalCode}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Type de client <small className="text-danger" >{errors?.clientType && `● ${errors?.clientType}`}</small></label>
                      <select className="form-select"
                        ref={input => (owner.clientType.current = input)}
                        defaultValue={defaultValues.clientType}
                        onChange={(e) => {
                          setClientRepresentedByDisabled(e.target.value == 1)
                          onFormChange()
                        }} >
                        {Object.keys(sites.CLIENT_TYPE).map((key, idx) => (
                          <option key={idx} value={key} > {sites.CLIENT_TYPE[key]} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Fonction <small className="text-danger" >{errors?.clientFunction && `● ${errors?.clientFunction}`}</small></label>
                      <select className="form-select"
                        ref={input => (owner.clientFunction.current = input)}
                        defaultValue={defaultValues.clientFunction}
                        onChange={onFormChange} >
                        <option value={null}></option>
                        <option value="owner">Propriétaire</option>
                        <option value="tenant">Locataire</option>
                        <option value="other">Autre</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Nom du Contact Responsable du site <small className="text-danger" >{errors?.clientRepresentedBy && `● ${errors?.clientRepresentedBy}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientRepresentedBy}
                        ref={owner.clientRepresentedBy}
                        disabled={clientRepresentedByDisabled}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Courriel <small className="text-danger" >{errors?.clientEmail && `● ${errors?.clientEmail}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientEmail}
                        ref={owner.clientEmail}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Téléphone <small className="text-danger" >{errors?.clientPhone && `● ${errors?.clientPhone}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.clientPhone}
                        ref={owner.clientPhone}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>




              <Col md="6">
                <Row className="mt-3">
                  <Col md="6">
                    <h4 className='card-title text-center'>Facturation</h4>
                  </Col>
                  <Col md="6">
                    <div className="form-check form-switch form-switch-lg d-flex justify-content-center" >
                      <input className="form-check-input" type="checkbox"
                        defaultChecked={defaultValues.billingSame}
                        onChange={() => {
                          setBillingDisabled(!billingDisabled)
                          onFormChange()
                        }}
                        ref={owner.billingSame}
                        disabled={ props.site.organization == 'risc' }
                      />
                      <label className="fw-semibold">Même que site protégé <small className="text-danger" >{errors?.billingSame && `● ${errors?.billingSame}`}</small></label>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Nom de la Personne ou Entreprise facturée <small className="text-danger" >{errors?.billingName && `● ${errors?.billingName}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingName}
                        ref={owner.billingName}
                        disabled={billingDisabled || props.site.organization == 'risc' }
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Adresse <small className="text-danger" >{errors?.billingAddress && `● ${errors?.billingAddress}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingAddress}
                        ref={owner.billingAddress}
                        disabled={billingDisabled || props.site.organization == 'risc' }
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Ville <small className="text-danger" >{errors?.billingCity && `● ${errors?.billingCity}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        ref={owner.billingCity}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingCity}
                        disabled={billingDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Province <small className="text-danger" >{errors?.billingProvince && `● ${errors?.billingProvince}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        ref={owner.billingProvince}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingProvince}
                        disabled={billingDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Code Postal <small className="text-danger" >{errors?.billingPostalCode && `● ${errors?.billingPostalCode}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingPostalCode}
                        ref={owner.billingPostalCode}
                        disabled={billingDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Courriel <small className="text-danger" >{errors?.billingEmail && `● ${errors?.billingEmail}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        ref={owner.billingEmail}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingEmail}
                        disabled={billingDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 d-none d-md-block">
                  <Col md="12" style={{ height: '67px' }}>

                  </Col>
                </Row>
                <Row className="mt-3 d-none d-md-block">
                  <Col md="12" style={{ height: '66px' }}>

                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Nom du Contact Responsable de la facturation<small className="text-danger" >{errors?.billingRepresentedBy && `● ${errors?.billingRepresentedBy}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        defaultValue={defaultValues.billingRepresentedBy}
                        ref={owner.billingRepresentedBy}
                        disabled={clientRepresentedByDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div className="position-relative">
                      <label className="fw-semibold">Téléphone <small className="text-danger" >{errors?.billingPhone && `● ${errors?.billingPhone}`}</small></label>
                      <input type="text"
                        className={`form-control`}
                        ref={owner.billingPhone}
                        defaultValue={defaultValues.billingSame ? '' : defaultValues.billingPhone}
                        disabled={billingDisabled || props.site.organization == 'risc'}
                        onChange={onFormChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}



export default SiteOwner;