import { data } from 'jquery';
import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, CardBody, Form, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { sites } from './../../../../actions/index'

const SiteContacts = props => {
  const params = useParams();
  let [errors, setErrors] = useState({}),
    [selected, setSelected] = useState(null),
    [relationOtherDisabled, setRelationOtherDisabled] = useState(true),
    contact = {
      gender: useRef(null),
      firstName: useRef(null),
      lastName: useRef(null),
      relationShip: useRef(null),
      relationShipOther: useRef(null),
      firstPhone: useRef(null),
      firstPhoneType: useRef(null),
      secondPhone: useRef(null),
      secondPhoneType: useRef(null),
      language: useRef(null),
      email: useRef(null)
    },
    copy = useRef(null);

  const onSubmit = async e => {
    e.preventDefault();

    var data = {
      firstName: contact.firstName.current?.value,
      lastName: contact.lastName.current?.value,
      gender: parseInt(contact.gender.current?.value),
      relationShip: parseInt(contact.relationShip?.current?.value),
      relationShipOther: contact.relationShipOther?.current?.value,
      language: parseInt(contact.language?.current?.value),
      presence: 'resource',
      channels: []
    };

    // Channels mnagement
    if (contact.firstPhone?.current?.value) {
      data.channels.push({
        "id": 1,
        "type": sites.PHONE_TYPES[contact.firstPhoneType?.current?.value],
        "value": contact.firstPhone?.current?.value
      });
    }

    if (contact.secondPhone?.current?.value) {
      data.channels.push({
        "id": 2,
        "type": sites.PHONE_TYPES[contact.secondPhoneType?.current?.value],
        "value": contact.secondPhone?.current?.value
      });
    }

    if (contact.email?.current?.value) {
      data.channels.push({
        "id": 3,
        "type": 'email',
        "value": contact.email?.current?.value
      });
    }

    // 
    // Since the update does not support channel updates, we delete 
    // and recreate the contact in order to perform an update.
    // TODO: Make a "real" update
    // 

    let res = null;
    if (selected) {
      await sites.deleteContact(params.id, selected)
    }

    res = await sites.addContact(params.id, [data]);
    if (res.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }
      setErrors(err)
    } else {
      window.location.reload(true);
    }
  }

  const onDelete = async (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette personne ressource?',
      html: '<span style="color:#F8BB86"> Cette opération est irréversible!</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {

        // TODO: Error management
        await sites.deleteContact(params.id, id);
        window.location.reload(true);
      }
    })
  }

  const onEdit = async (e, data, idx) => {
    e.preventDefault();

    data.channels?.forEach(chn => {
      if (chn.id == 1) {
        contact.firstPhone.current.value = chn.value;
        contact.firstPhoneType.current.value = Object.keys(sites.PHONE_TYPES).find(k => sites.PHONE_TYPES[k] === chn.type);
      } else if (chn.id == 2) {
        contact.secondPhone.current.value = chn.value;
        contact.secondPhoneType.current.value = Object.keys(sites.PHONE_TYPES).find(k => sites.PHONE_TYPES[k] === chn.type);
      } else if (chn.id == 3) {
        contact.email.current.value = chn.value;
      }

      if (contact.secondPhone?.current?.value) {
        data.channels.push({
          "id": 2,
          "type": sites.PHONE_TYPES[contact.secondPhoneType?.current?.value],
          "value": contact.secondPhone?.current?.value
        });
      }

      if (contact.email?.current?.value) {
        data.channels.push({
          "id": 3,
          "type": 'email',
          "value": contact.email?.current?.value
        });
      }
    });

    contact.firstName.current.value = data.firstName;
    contact.lastName.current.value = data.lastName;
    contact.gender.current.value = data.gender;
    contact.relationShip.current.value = data.relationShip;
    contact.relationShipOther.current.value = data.relationShipOther;
    contact.language.current.value = data.language;

    setSelected(data._id);
    setRelationOtherDisabled(data.relationShip != 12);
  }

  const onCopy = async e => {
    e.preventDefault();

    let id = copy.current.value;
    if (id < 0) return;

    const found = props.site.contacts.find(ele => ele._id == id);

    found._id = null;
    onEdit(e, found);
  }

  const onCancel = e => {
    e.preventDefault();

    contact.firstName.current.value = '';
    contact.lastName.current.value = '';
    contact.gender.current.value = '0';
    contact.relationShip.current.value = '0';
    contact.relationShipOther.current.value = '';
    contact.firstPhone.current.value = '';
    contact.firstPhoneType.current.value = '0';
    contact.secondPhone.current.value = '';
    contact.secondPhoneType.current.value = '0';
    contact.email.current.value = '';
    contact.language.current.value = '0';

    setSelected(null);
    setRelationOtherDisabled(true);
  }

  const displayRow = (row, idx) => {

    let firstPhone = '', firstPhoneType = '', secondPhone = '', secondPhoneType = '', email = '';
    row.channels.forEach(chn => {
      if (chn.id == 1) {
        firstPhone = chn.value;
        firstPhoneType = chn.type;
      } else if (chn.id == 2) {
        secondPhone = chn.value;
        secondPhoneType = chn.type;
      } else if (chn.id == 3) {
        email = chn.value;
      }
    });

    return <tr key={idx} >
      <td>{row.firstName}</td>
      <td>{row.lastName}</td>
      <td>{sites.GENDER[row.gender]}</td>
      <td>{sites.RELATIONSHIP[row.relationShip]}</td>
      <td>{email}</td>
      <td>{firstPhone}</td>
      <td>{firstPhoneType}</td>
      <td>{secondPhone}</td>
      <td>{secondPhoneType}</td>
      <td className="text-right">
        <span style={{ width: '100px', display: 'inline-block' }} >
          <button className="me-2 btn btn-primary btn-sm"
            onClick={(e) => onEdit(e, row, idx)} >
            <i className='fas fa-pencil-alt' />
          </button>
          <button className="btn btn-danger btn-sm"
            onClick={(e) => onDelete(e, row._id)} >
            <i className='fas fa-trash' />
          </button>
        </span>
      </td>
    </tr>;
  }

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h4 className="text-center"><strong>Personnes Ressources à contacter si occupants absents</strong></h4>

          <div className="table-responsive mt-4 mb-3">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Genre</th>
                  <th>Relation</th>
                  <th>Courriel</th>
                  <th>1er Téléphone</th>
                  <th>Type</th>
                  <th>2e Téléphone</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {props.site?.contacts?.filter(e => { return e.presence == 'resource' })?.map((row, idx) => (
                  displayRow(row, idx)
                ))}

                {props.site?.contacts?.filter(e => { return e.presence == 'resource' })?.length === 0 && <tr>
                  <td colSpan="10" className='text-center' >Aucun contact...</td>
                </tr>}
              </tbody>
            </Table>
          </div>

          <Row>
            <Col md={12}>
              <hr className="mt-4" />
            </Col>
          </Row>

          <Form onSubmit={onSubmit}>
            <Row className="mt-2" >
              <Col lg={4} className="text-center" >
                <select className="form-select"
                  ref={input => (copy.current = input)}
                >
                  <option value={-1}> </option>
                  {props.site?.contacts?.filter(e => { return e.presence == 'mayday' })?.map((item, idx) => (
                    <option key={idx} value={item._id} > {item.firstName} {item.lastName} </option>
                  ))}
                </select>
              </Col>
              <Col lg={2}>
                <button
                  onClick={onCopy}
                  className="me-3 btn btn-primary btn-sm waves-effect waves-light mt-1" >
                  Copier
                </button>
              </Col>
              <Col lg={6} className="text-right" >
                <button
                  onClick={onCancel}
                  className="me-3 btn btn-danger btn-sm waves-effect waves-light" >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-success btn-sm waves-effect waves-light" >
                  Sauvegarder
                </button>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Prénom <small className="text-danger" >{errors?.firstName && `● ${errors?.firstName}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.firstName}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Nom <small className="text-danger" >{errors?.lastName && `● ${errors?.lastName}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.lastName}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Genre <small className="text-danger" >{errors?.gender && `● ${errors?.gender}`}</small></label>
                  <select className="form-select"
                    ref={input => (contact.gender.current = input)} >
                    {Object.keys(sites.GENDER).map((key, idx) => (
                      <option key={idx} value={key} > {sites.GENDER[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Courriel <small className="text-danger" >{errors?.email && `● ${errors?.email}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.email}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Relation <small className="text-danger" >{errors?.relationShip && `● ${errors?.relationShip}`}</small></label>
                  <select className="form-select"
                    ref={input => (contact.relationShip.current = input)}
                    onChange={(e) => { setRelationOtherDisabled(e.target.value != 12) }}
                  >
                    {Object.keys(sites.RELATIONSHIP).map((key, idx) => (
                      <option key={idx} value={key} > {sites.RELATIONSHIP[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Relation (autre)</label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.relationShipOther}
                    disabled={relationOtherDisabled}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">1er Téléphone <small className="text-danger" >{errors?.firstPhone && `● ${errors?.firstPhone}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.firstPhone}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.firstPhoneType && `● ${errors?.firstPhoneType}`}</small></label>
                  <select className="form-select"
                    ref={input => (contact.firstPhoneType.current = input)}
                  >
                    {Object.keys(sites.PHONE_TYPES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.PHONE_TYPES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">2e Téléphone <small className="text-danger" >{errors?.secondPhone && `● ${errors?.secondPhone}`}</small></label>
                  <input type="text"
                    className={`form-control`}
                    ref={contact.secondPhone}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Type <small className="text-danger" >{errors?.secondPhoneType && `● ${errors?.secondPhoneType}`}</small></label>
                  <select className="form-select"
                    ref={input => (contact.secondPhoneType.current = input)}
                  >
                    {Object.keys(sites.PHONE_TYPES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.PHONE_TYPES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6">
                <div className="position-relative">
                  <label className="fw-semibold">Langue <small className="text-danger" >{errors?.language && `● ${errors?.language}`}</small></label>
                  <select className="form-select"
                    ref={input => (contact.language.current = input)}
                  >
                    {Object.keys(sites.LANGUAGES).map((key, idx) => (
                      <option key={idx} value={key} > {sites.LANGUAGES[key]} </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}



export default SiteContacts;