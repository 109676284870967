import React, { Component } from 'react'

export default class Layout extends Component {

  render() {
    return <React.Fragment>
      {this.props.children}
    </React.Fragment>
  }
}
