import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Row,
  Col
} from 'reactstrap'

import ReactApexChart from "react-apexcharts";

const NicaGraph = props => {
  const [selected, setSelected] = useState({ idx: null });

  var x  = [], series = [{
    name: "High - 2018",
    data: []
  }];

  for (const item of props.data) {
    series[0].data.push(item.general);
    x.push(item.date)

    if (x.length > 100) break;
  }

  var options = {
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // console.log();
          setSelected({ idx: config.dataPointIndex });
          console.log(props.data[config.dataPointIndex]);
        }
      }
    },
    colors: ['#0576b9', '#0ab39c'],
    stroke: {
      width: [3, 3],
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f1f1'
    },
    markers: {
      style: 'inverted',
      size: 4,
      hover: {
        size: 6
      }
    },
    xaxis: {
      type: 'datetime',
      categories: x,
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false,
      intersect: true,
      shared: false
    }
  };

  const DetailSeries = [
		{
			name: 'Series 1',
			data: [
        props.data[selected?.idx]?.carbonmonoxyde | 0,
        props.data[selected?.idx]?.heat | 0,
        props.data[selected?.idx]?.interconnect | 0,
        props.data[selected?.idx]?.smoke | 0,
        props.data[selected?.idx]?.t3 | 0
      ]
		},
	];

	const DetailsOptions = {

		chart: {
			height: 300,
			type: 'radar',
			toolbar: {
				show: !1,
			},
		},
		colors: ['#0576b9'],
		xaxis: {
			categories: ['Carbon Monoxyde', 'Heat', 'Interconnect', 'Smoke', 'T3'],
		},
    tooltip: {
      enabled: false
    }
	};

return (
  <Row>
    <Col xs="9">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="300"
        className="apex-charts"
        dir="ltr"
      />
    </Col>
    <Col xs="3">
      <ReactApexChart
        options={DetailsOptions}
        series={DetailSeries}
        type="radar"
        height="300"
        className="apex-charts"
        dir="ltr"
      />
    </Col>
  </Row>
);
};

export default NicaGraph;
