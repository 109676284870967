
export default class Auth {
  constructor(core) {
    this.core = core;

    // Get my info
    if (this.token()) {
      this.getUser();
    }
  }

  async onLogin(data) {
    let res = await this.core.apiCall('post', `/auth/login`, data);
    if (res.errors) {
      return res;
    }

    this.core.set('token', res.data.token);
    this.core.setAuthToken(res.data.token);
    await this.getUser();
  }

  async onEditProfile(data) {
    let res = await this.core.apiCall('put', `/auth/user`, data);
    if (res.errors) {
      return res;
    }

    this.core.pub('me', res.data);
  }

  async onLogout(data) {
    await this.core.apiCall('post', '/auth/logout');
    this.core.delete('token');
    this.core.setAuthToken(undefined);
  }

  token() {
    return this.core.get('token');
  }

  async getUser() {
    let res = await this.core.apiCall('get', `/auth/user`);
    this.core.pub('me', res.data);
  }

  async keepAlive() {
    return await this.core.apiCall('get', `/auth/keep-alive`);
  }
}
