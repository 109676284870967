import React from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Container,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Collapse,
  TabContent,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams } from 'react-router-dom'

import ProfileComponent from './profile.component'
import SessionsComponent from './sessions.component'
import SettingsSidebar from './sidebar.component'

const SettingsPage = () => {
  const params = useParams();

  const Settings = () => {
    if (params.section === 'sessions') {
      return <SessionsComponent />
    }
    return <ProfileComponent />
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs="12">

            <SettingsSidebar />
            
            <div className="email-rightbar mb-3">
              <Settings />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SettingsPage;
