import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

// Routes
import AuthRoutes from './views/Auth/auth.route'
import PlatformRoutes from './views/Platform/platform.route'

// Import scss
import './assets/scss/theme.scss'

const App = () => {

  function generateRoute(route, idx) {
    let Layout = route.layout, Component = route.component;

    return (
      <Route
        exact 
        key={idx}
        path={route.path}
        render={props => {

          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }}
      />
    );
  }

  return (
    <Router>
      <Switch>
        {AuthRoutes.map((route, idx) => (
          generateRoute(route, idx)
        ))}

        {PlatformRoutes.map((route, idx) => (
          generateRoute(route, idx)
        ))}
      </Switch>
    </Router>
  );
}

export default App;