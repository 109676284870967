import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Container,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Collapse,
  TabContent,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'

import { production } from '../../../actions/index'



import Procedures from './procedures.data'

const ProcedureSummary = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({});

  let init = async () => {
    if (data?._id === params.id) return;

    let res = await production.getProcedure(params.id);
    // TODO: Error managemnet

    // Get the procedure
    res.data.procedureInfo = Procedures[res.data.procedure];
    // TODO: Error management

    setData(res.data);
  }
  init();

  // procedureCompletedRes = production.procedureCompletedRes(params.id);

  const generateRow = (step, idx) => {

    // Get info on this step
    let info = null;
    for (const tmp of data.procedureInfo.steps) {
      if (step.name === tmp.name) {
        info = tmp;
        break;
      }
    }

    if(!info) return ;

    // Display each step
    return <tr key={idx} >
      <td>{info.id}</td>
      <td>{info.title}</td>
      <td>{info.description}</td>
      <td>{info.expected}</td>
      <td>{step?.value}</td>
      <td className="text-end">

        <button
          type="button"
          style={{pointerEvents: 'none'}}
          className={`btn btn-${step.status === 'passed'? 'success': 'danger'} btn-rounded waves-effect waves-light btn-sm`}>
        {step?.status.toUpperCase()}
        </button>
      </td>
    </tr>;
  }

  const showSkipped = data => {
    if (data?.skipped) {
      return <tr >
      <td colSpan="6" className="text-center text-warning" >Some tests have been skipped!</td>
    </tr>;
    }
  }

  const onTestAgain = async e => {
    e.preventDefault();

    let res = await production.onNewProcedure({
      procedure: data?.procedureInfo?.name,
      serialNumber: data?.serialNumber
    });

    // Error management
    // if (res.errors) {
    //   notifications.push({
    //     type: 'danger',
    //     title: `Procedure creation failed!`,
    //     description: res.errors[0].detail
    //   });
    //   return ;
    // }

    // Success
    // notifications.push({
    //   type: 'success',
    //   title: `New procedure created!`,
    //   description: `New ${data?.procedureInfo?.name} procedure created for the ${data?.serialNumber} board.`
    // });

    history.push(`/quality-assurance/production/${res.data.procedure}/${res.data._id}`);
  }

  const onNewTest = e => {
    e.preventDefault();
    history.push(`/quality-assurance/production/${data?.procedureInfo?.name}`);
  }

  // TODO: Preflight call to validate the ID?

  return (
    <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-center align-self-center mt-2 mb-3">
                <h3 className="">Production Report</h3>
              </div>
              <div className="py-2 mt-3">
                <h3 className="font-size-15 font-weight-bold float-right">Created: {data?.createdAt?.replace('T', ' ')}</h3>
                <h3 className="font-size-15 font-weight-bold float-left">{data?.procedureInfo?.description}</h3>
              </div>
              <div className="table-responsive">
                <table className="table table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th style={{ width: '70px' }}>No.</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Expected</th>
                      <th>Result</th>
                      <th className="text-end">Pass/Fail</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* The first step is always the Serial Number */}
                    <tr>
                      <td>01</td>
                      <td>{data?.procedureInfo?.steps[0].title}</td>
                      <td>{data?.procedureInfo?.steps[0].description}</td>
                      <td></td>
                      <td>{data?.serialNumber}</td>
                      <td className="text-end">
                        <button
                        type="button"
                        style={{pointerEvents: 'none'}}
                        className="btn btn-success btn-rounded waves-effect waves-light btn-sm" >
                          PASSED
                        </button>
                      </td>
                    </tr>

                    {/* Display other steps */}
                    {data?.tests?.map((data, idx) => (
                      generateRow(data, idx)
                    ))}

                    {showSkipped(data)}
                  </tbody>
                </table>
              </div>
              <Row >
                <Col lg={6} >
                  <div className="d-flex justify-content-center align-self-center mt-2 mb-3">
                    <h5 className="mt-3">Final Result: </h5>
                    <button
                    type="button"
                    style={{pointerEvents: 'none'}}
                    className={`ms-4 mt-1 btn btn-${data?.status === 'passed'? 'success': (data?.status === 'failed'? 'danger' : 'warning')} btn-rounded waves-effect waves-light`}>
                      {data?.status?.toUpperCase()}
                    </button>                  
                  </div>
                </Col>
                <Col lg={6} >
                  <div className="d-flex justify-content-center align-self-center mt-2 mb-3">
                    <h5 className="mt-3">Executed by: <strong>{data?.user?.email}</strong></h5>
                  </div>
                </Col>
              </Row>
              <div className="d-print-none">
                <div className="d-flex justify-content-center align-self-center">
                  <button onClick={onTestAgain} className="btn btn-primary w-md waves-effect waves-light"><i className="fas fa-redo"></i> Test Again</button> {" "}
                  <button onClick={onNewTest} className="btn btn-success w-md waves-effect waves-light ms-4"><i className="fas fa-plus"></i> New {data?.procedureInfo?.title} test</button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
  )
}

export default ProcedureSummary;
