import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'
import moment from 'moment'

import { alerts, core } from './../../../../actions/index'
import Loader from './../../../../components/Common/Loader'
import Pagination from './../../../../components/Pagination/pagination.component'

const DISPLSAY_PER_PAGE = 25;

const HomePage = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, sort: { createdAt: -1 }, alerts: [] }),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' }),
    [myTimezone, setMyTimezone] = useState('');


  useEffect(async () => {
    let search = null, page = 1, sort = { createdAt: -1 };
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : data.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    core.sub('me', async user => {
      setMyTimezone(user.timezone);
      getAlerts(page, sort, search);
    });
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/technician/alerts/${query}`);
    getAlerts(page, sort, search);
  }

  const getAlerts = async (page, sort, search) => {

    // Start loading
    setIsLoading(true);

    let res = await alerts.getAlerts({
      search: search,
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, alerts: res.data.alerts, page: page, sort: sort });

    // Stop loading
    setIsLoading(false);
  }

  let displayAlert = (alert, idx) => {

    // Priority management
    let priority = <span className="badge bg-success me-1">LOG</span>;
    if (alert.priority === 'warning') {
      priority = <span className="badge bg-info me-1">AVERTISSEMENT</span>;
    } else if (alert.priority === 'error') {
      priority = <span className="badge bg-secondary me-1">ERREUR</span>;
    } else if (alert.priority === 'major') {
      priority = <span className="badge bg-warning me-1">MAJEURE</span>;
    } else if (alert.priority === 'critical') {
      priority = <span className="badge bg-danger me-1">CRITIQUE</span>;
    }

    // State management
    let state = <span className="badge bg-danger me-1">DÉCLENCHÉ</span>;
    if (alert.state === 'pending_trig') {
      state = <span className="badge bg-warning me-1">DÉCLENCHEMENT EN ATTENTE</span>;
    } else if (alert.state === 'pending_ok') {
      state = <span className="badge bg-warning me-1">RESTAURATION EN ATTENTE</span>;
    } else if (alert.state === 'ok') {
      state = <span className="badge bg-success me-1">RESTAURÉE</span>;
    }

    let status = <span className="badge bg-danger me-1">Ouvert</span>;
    if (alert.status === 'ongoing') {
      status = <span className="badge bg-warning me-1">En cours</span>;
    } else if (alert.status === 'closed') {
      status = <span className="badge bg-success me-1">Fermé</span>;
    }

    return <tr key={idx} >
      <th>
        {moment.tz(new Date(alert.triggedAt), myTimezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z')}
      </th>
      <th scope="row">
        <Link to={`/technician/device/${alert?.serialNumber}/owner`}>
          {alert?.serialNumber}
        </Link>
      </th>
      <td>{alert.site?.owner?.clientName || ''}</td>
      <td>{alert.name}</td>
      <th><strong>{alert.type.toUpperCase()}</strong></th>
      <th>{priority}</th>
      <th>{state}</th>
      <th>{status}</th>
      <th>{alert.assigned && alert.assigned != "false" ? `${alert.assignedUser[0].firstName} ${alert.assignedUser[0].lastName}` : 'Non attribué'}</th>
      <th className="text-right">
        <Link to={`/technician/alert/${alert._id}`}>
          <button className="btn btn-primary btn-sm" >
            Ouvrir
          </button>
        </Link>
      </th>
    </tr>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Gestion des alertes</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, data.sort) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chercher..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: '250px' }} >
                          <Link to="#" onClick={() => { onChange(data.page, { triggedAt: data.sort.hasOwnProperty('triggedAt') ? data.sort.triggedAt * -1 : -1 }) }} >
                            Heure
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { serialNumber: data.sort.hasOwnProperty('serialNumber') ? data.sort.serialNumber * -1 : -1 }) }} >
                            Numéro de série
                          </Link>
                        </th>
                        <th>
                          Client
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { name: data.sort.hasOwnProperty('name') ? data.sort.name * -1 : -1 }) }} >
                            Titre
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { type: data.sort.hasOwnProperty('type') ? data.sort.type * -1 : -1 }) }} >
                            Type
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { priority: data.sort.hasOwnProperty('priority') ? data.sort.priority * -1 : -1 }) }} >
                            Priorité
                          </Link>
                        </th>
                        <th>
                          <Link to="#" onClick={() => { onChange(data.page, { state: data.sort.hasOwnProperty('state') ? data.sort.state * -1 : -1 }) }} >
                            État
                          </Link>
                        </th>

                        <th style={{ width: '100px' }} >
                          <Link to="#" onClick={() => { onChange(data.page, { status: data.sort.hasOwnProperty('status') ? data.sort.status * -1 : -1 }) }} >
                            Statut
                          </Link>
                        </th>
                        <th style={{ width: '200px' }} >Responsable</th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.alerts.map((alert, idx) => (
                        displayAlert(alert, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="10" className='text-center' >Aucune donnée n'est disponible..</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default HomePage;
