import React, { useState, useCallback, useEffect } from 'react'
//import useWebSocket, { ReadyState } from 'react-use-websocket';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'
import moment from 'moment'

import SiteCommunicationStats from './siteCommunications.component'
import EventsTable from './../../../../components/Dashboard/eventsTable.component'

import { sites, core } from './../../../../actions/index'

import Loader from './../../../../components/Common/Loader'

const DISPLSAY_PER_PAGE = 25;
const SiteHistory = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, sort: { createdAt: -1 }, pageCount: 1, events: [] }),
    [myTimezone, setMyTimezone] = useState(''),
    [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    let page = data.page, sort = data.sort;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : data.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    core.sub('me', async user => {
      setMyTimezone(user.timezone);
      getEvents(page, sort, user.timezone);
    });
  }, [])

  const getEvents = async (page, sort, timezone) => {

    // Start loading
    setIsLoading(true);

    let res = await sites.getEvents(params.id, {
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });

    // Format epoch time
    res.data.events.map(event => {
      event.epoch = moment.tz(new Date(event.epoch * 1000), timezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z');
      event.createdAt = moment.tz(new Date(event.createdAt), timezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z');
      return event;
    });

    // TODO: Error management
    setData({
      count: res.data.count,
      events: res.data.events,
      page: page,
      sort: sort,
      pageCount: Math.max(1, Math.ceil(res.data.count / DISPLSAY_PER_PAGE))
    });

    // Stop loading
    setIsLoading(false);
  }

  const onChange = (page, sort) => {
    page = page || data.page;
    sort = sort || data.sort;

    let query = JWT.sign({ page: page, sort: sort }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/technician/site/${params.id}/history/${query}`);
    getEvents(page, sort, myTimezone);
  }

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Communications</h4>
              <SiteCommunicationStats />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle>
                { !isLoading && <h3 className="float-end text-info" >{data.count}</h3> }
                Événements<br />
                <small>3 mois d'historique</small>
              </CardTitle>
        
              { isLoading && <Loader height={'300px'} /> }

              { !isLoading && <EventsTable data={data} onChange={onChange} /> }
            </CardBody>
          </Card>
        </Col>
      </Row>

    </Container>
  )
}

export default SiteHistory;
