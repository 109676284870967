import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import JWT from 'jsonwebtoken'

import { centers } from './../../../actions/index'
import Loader from './../../../components/Common/Loader'
import Pagination from './../../../components/Pagination/pagination.component'

const DISPLSAY_PER_PAGE = 25;

const CentersPage = () => {
  const params = useParams(),
    history = useHistory(),
    [data, setData] = useState({ count: 0, page: 1, sort: { createdAt: 1 }, centers: [] }),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' });


  useEffect(async () => {
    let search = null, page = 1, sort = { createdAt: 1 };
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : data.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    getCenters(page, sort, search);
  }, [])

  let inputs = {};
  const onChange = (page, sort) => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, sort: sort, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/admin/centers/${query}`);
    getCenters(page, sort, search);
  }

  const getCenters = async (page, sort, search) => {

    // Start loading
    setIsLoading(true);

    let res = await centers.search({
      search: search,
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });


    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, centers: res.data.centers, page: page, sort: sort });

    // Stop loading
    setIsLoading(false);
  }

  let displayCenter = (center, idx) => {
    return <tr key={idx} >
      <th scope="row">{center.name}</th>
      <td>{center.address?.city}</td>
      <td>{center.technicalManager}</td>
      <td>{center.adminManager}</td>
      <td>{center?.type == 'default' ? '911' : 'Télésurveillance'}</td>
      <td className="text-right">
        <Link to={`/admin/center/${center._id}`}>
          <button className="btn btn-primary btn-sm" >
            Ouvrir
          </button>
        </Link>
      </td>
    </tr>;
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <span className="float-right" >
                  <Link to="/admin/center/add">
                    <button className="btn btn-success btn-sm waves-effect waves-light" >
                      Ajouter une centrale
                    </button>
                  </Link>
                </span>
                <h4 className="card-title">Gestion des centrales</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1, data.sort) }} className="app-search">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chercher..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th> Nom </th>
                        <th> Ville </th>
                        <th> Responsable technique </th>
                        <th> Responsable administratif </th>
                        <th> Type </th>
                        <th style={{ width: '100px' }} className="text-right" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.centers.map((ceenter, idx) => (
                        displayCenter(ceenter, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="6" className='text-center' >Aucune donnée n'est disponible...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>

                <Pagination data={{
                  page: data.page,
                  sort: data.sort,
                  pageCount: Math.max(1, Math.ceil(data.count / DISPLSAY_PER_PAGE))
                }} onChange={onChange} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}

export default CentersPage;
