import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory} from 'react-router-dom'

import { serialnumbers } from './../../../actions/index'

const SerialNumberPage = () => {
    return (
        <div className="page-content">
            <Container fluid>TODO</Container>
        </div>
    )
}
export default SerialNumberPage;