import React, { useState, useEffect } from 'react'
import JWT from 'jsonwebtoken'

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Container,
  Modal,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

import EventsTable from './../../../../components/Dashboard/eventsTable.component'
import CommunicationGraph from './../../../../components/Dashboard/communicationGraph.component'
import NicaGraph from '../../../../components/Dashboard/nicaGraph.component'
import RemainingLife from '../../../../components/Dashboard/remainingLife.component'

import Loader from './../../../../components/Common/Loader'
import { devices, core } from './../../../../actions/index'

const DISPLSAY_PER_PAGE = 25;
const DevicePage = () => {
  const params = useParams(),
    history = useHistory(),
    [device, setDevices] = useState({}),
    [events, setEvents] = useState({ count: 0, page: 1, sort: { createdAt: -1 }, pageCount: 0, events: [] }),
    [stats, setStats] = useState({ communication: [], nica: [], remaining: {} }),
    [isLoading, setIsLoading] = useState(true),
    [myTimezone, setMyTimezone] = useState('');

  useEffect(async () => {
    let page = events.page, sort = events.sort;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        page = decoded.page ? decoded.page : 1;
        sort = decoded.sort ? decoded.sort : events.sort;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    core.sub('me', async user => {
      setMyTimezone(user.timezone);

      let res = await devices.get(params.id);
      setDevices(res.data);
      await getEvents(page, sort, user.timezone);
      await getStats();

      // Stop loading
      setIsLoading(false);
    });
  }, [])

  const onChange = (page, sort) => {
    page = page || events.page;
    sort = sort || events.sort;

    let query = JWT.sign({ page: page, sort: sort }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/technician/device/${params.id}/${query}`);
    getEvents(page, sort, myTimezone);
  }

  const getEvents = async (page, sort, timezone) => {
    let res = await devices.getEvents(params.id, {
      page: page,
      sort: sort,
      length: DISPLSAY_PER_PAGE
    });

    // Format epoch time
    res.data.events.map(event => {
      event.epoch = moment.tz(new Date(event.epoch * 1000), timezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z');
      event.createdAt = moment.tz(new Date(event.createdAt), timezone).format('YYYY-MM-DD HH:mm:ss [GMT]Z');
      return event;
    });

    // TODO: Error management
    setEvents({
      count: res.data.count,
      events: res.data.events,
      page: page,
      sort: sort,
      pageCount: Math.max(1, Math.ceil(res.data.count / DISPLSAY_PER_PAGE))
    });
  }

  const getStats = async () => {
    let res = await devices.getStats(params.id, null);



    // TODO: Error management
    if (res.data?.stats) setStats(res.data.stats);
  }

  return (
    <div className="page-content">
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>

        <Row>
          <Col md="3">
            <Card>
              <CardBody>
                <Row className="my-3" >
                  <Col lg={7} >
                    <h4 className="card-title">Details</h4>
                  </Col>
                  <Col lg={5} className="d-flex align-items-center justify-content-end" >
                    {/* <Link to={`/technician/device/edit/${params.id}`}>
                      <button className="btn btn-primary waves-effect waves-light btn-sm" >
                        Edit info
                      </button>
                    </Link> */}
                  </Col>
                </Row>

                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <tbody>
                      <tr>
                        <th>Serial Number</th>
                        <td>{device?.serialNumber || 'N/A'}</td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>{device?.model || 'N/A'}</td>
                      </tr>
                      <tr>
                        <th>Family ID</th>
                        <td>
                          <Link to={device?.siteId ? `/technician/site/${device?.siteId}/owner` : '/technician/sites'}>
                            {device?.sites?.length > 0 ? device?.sites[0]?.familyId?.substring(0, 8).toUpperCase() : 'N/A'}
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="9">
            <Card>
              <CardBody>
                <h4 className="card-title">NICA</h4>
                <NicaGraph data={stats.nica} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="3">
            <Card>
              <CardBody>
                <h4 className="card-title">Remaining Time</h4>
                <RemainingLife data={stats.remaining} />
              </CardBody>
            </Card>
          </Col>
          <Col xs="9">
            <Card>
              <CardBody>
                <h4 className="card-title">Communication</h4>
                <CommunicationGraph data={stats.communication} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="float-end text-info" >{events.count}</h3>
                  Events<br />
                  <small>3 months history</small>
                </CardTitle>
                <EventsTable data={events} onChange={onChange} />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>}
    </div>
  )
}

export default DevicePage;
