import React, { useEffect, useState, useRef } from 'react'
import {
  Row, Col, Container
} from 'reactstrap'
import { Link, Redirect, useParams } from 'react-router-dom'

import SiteSidebar from './sidebar.component'
import Loader from './../../../../components/Common/Loader'

import SiteOccupants from './siteOccupants.component'
import SiteOwner from './siteOwner.component'
import SiteBuilding from './siteBuilding.component'
import SiteDevice from './siteDevice.component'
import SiteContacts from './siteContacts.component'
import SiteMayday from './siteMayday.component'
import SiteAnimals from './siteAnimals.component'
import SiteHistory from './siteHistory.component'

import { sites, municipalities } from './../../../../actions/index'

const Site = () => {
  const params = useParams();
  let [site, setSite] = useState({}),
    [isLoading, setIsLoading] = useState(false),
    [allMunicipalities, setAllMunicipalities] = useState([]);

  const Section = () => {
    if (params.section === 'building') {
      return <SiteBuilding site={site} />
    } else if (params.section === 'occupants') {
      return <SiteOccupants site={site} />
    } else if (params.section === 'devices') {
      return <SiteDevice site={site} municipalities={allMunicipalities} />
    } else if (params.section === 'contacts') {
      return <SiteContacts site={site} />
    } else if (params.section === 'mayday') {
      return <SiteMayday site={site} />
    } else if (params.section === 'animals') {
      return <SiteAnimals site={site} />
    } else if (params.section === 'history') {
      return <SiteHistory site={site} />
    }

    return <SiteOwner site={site} />
  }

  useEffect(async () => {

    // TODO: Error management
    if (params.id !== 'new') {

      // Start Loading
      setIsLoading(true); 

      let res = await sites.getSite(params.id);
      setSite(res.data);

      let tmp = await municipalities.search({
        search: null,
        page: 1,
        sort: null,
        length: 250
      });
      setAllMunicipalities(tmp.data.municipalities);

      // Stop Loading
      setIsLoading(false);
    }
  }, [])



  return (
    <div className="page-content">
      {isLoading && <Loader />}

      { !isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <SiteSidebar />

            <div className="email-rightbar mb-3">
              <Section />
            </div>
          </Col>
        </Row>
      </Container> }
    </div>
  )
}

export default Site;
