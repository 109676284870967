import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Form
} from 'reactstrap'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'

import Loader from './../../../components/Common/Loader'
import { centers, municipalities } from './../../../actions/index'


const MunicipalityPage = () => {
  const params = useParams(),
    history = useHistory(),
    [municipality, setMunicipality] = useState({}),
    [defaultCenters, setDefaultCenters] = useState([]),
    [monitoringCenters, setMonitoringCenters] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [errors, setErrors] = useState({});

  useEffect(async () => {

    let tmp = await centers.search({
      search: null,
      page: 1,
      sort: null,
      length: 250
    });

    setDefaultCenters(tmp.data.centers.filter(center => center.type === 'default'));
    setMonitoringCenters(tmp.data.centers.filter(center => center.type === 'monitoring'));

    // TODO: Error management
    if (params.id !== 'add') {
      let res = await municipalities.get(params.id);
      setMunicipality(res.data);
    }

    // Stop loading
    setIsLoading(false);
  }, [])


  let inputs = {}, address = {};
  const onSubmit = async e => {
    e.preventDefault();

    // Manage central data and address
    let data = { address: {} };
    for (const key in inputs) {
      if ([
        'fireManagerPhoneOne',
        'fireManagerPhoneTwo',
        'incendiaManagerPhoneOne',
        'incendiaManagerPhoneTwo'
      ].includes(key)) {
        continue;
      }

      data[key] = inputs[key].value;
    }

    data.fireManagerPhone = [];
    if (inputs.fireManagerPhoneOne.value.trim() != '') data.fireManagerPhone.push(inputs.fireManagerPhoneOne.value);
    if (inputs.fireManagerPhoneTwo.value.trim() != '') data.fireManagerPhone.push(inputs.fireManagerPhoneTwo.value);

    data.incendiaManagerPhone = [];
    if (inputs.incendiaManagerPhoneOne.value.trim() != '') data.incendiaManagerPhone.push(inputs.incendiaManagerPhoneOne.value);
    if (inputs.incendiaManagerPhoneTwo.value.trim() != '') data.incendiaManagerPhone.push(inputs.incendiaManagerPhoneTwo.value);

    for (const key in address) {
      data.address[key] = address[key].value;
    }

    // Reset error
    setErrors({})

    if (params.id === 'add') {
      return await onAdd(data);
    }
    return await onEdit(data);
  }

  const onAdd = async data => {
    let res = await municipalities.create(data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    history.push(`/admin/municipality/${res.data._id}`);
    setMunicipality(res.data);
  }

  const onEdit = async data => {
    let res = await municipalities.update(params.id, data);
    if (res?.errors) {
      let err = {};
      for (const error of res.errors) {
        err[error.field] = error.detail;
      }

      return setErrors(err);
    }

    setMunicipality(res.data);
  }

  return (
    <div className="page-content">
      
      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <h4 className="card-title">{params.id === 'add' ? 'Nouvelle municipalité' : `Modifier ${municipality?.name}`}</h4>
          </Col>
        </Row>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pb-0">
                  <Row>
                    <Col lg={6}>
                      <Card>
                        <CardBody className="pb-0">
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Nom <small className="text-danger" >{errors.name && `● ${errors.name}`}</small></Label>
                                <input className={`form-control ${errors.name && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.name}
                                  ref={input => (inputs.name = input)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Langue <small className="text-danger" >{errors.language && `● ${errors.language}`}</small></Label>
                                <select className="form-select"
                                  defaultValue={municipality?.language}
                                  ref={input => (inputs.language = input)}
                                >
                                  {Object.keys(municipalities.LANGUAGES).map((key, idx) => (
                                    <option key={idx} value={key} > {municipalities.LANGUAGES[key]} </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card>
                        <CardBody className="pb-0">
                          <Row>
                            <Col lg={9}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Adresse <small className="text-danger" >{errors['address.street'] && `● ${errors['address.street']}`}</small></Label>
                                <input className={`form-control ${errors['address.street'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.street}
                                ref={input => (address.street = input)}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Appartement <small className="text-danger" >{errors['address.appt'] && `● ${errors['address.appt']}`}</small></Label>
                                <input className={`form-control ${errors['address.appt'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.appt}
                                  ref={input => (address.appt = input)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={9}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Ville <small className="text-danger" >{errors['address.city'] && `● ${errors['address.city']}`}</small></Label>
                                <input className={`form-control ${errors['address.city'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.city}
                                  ref={input => (address.city = input)}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Province <small className="text-danger" >{errors['address.province'] && `● ${errors['address.province']}`}</small></Label>
                                <input className={`form-control ${errors['address.province'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.province}
                                  ref={input => (address.province = input)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={9}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Pays <small className="text-danger" >{errors['address.country'] && `● ${errors['address.country']}`}</small></Label>
                                <input className={`form-control ${errors['address.country'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.country}
                                  ref={input => (address.country = input)}
                                />
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Code postal <small className="text-danger" >{errors['address.postalCode'] && `● ${errors['address.postalCode']}`}</small></Label>
                                <input className={`form-control ${errors['address.postalCode'] && 'is-invalid'}`}
                                  type="text"
                                  defaultValue={municipality?.address?.postalCode}
                                  ref={input => (address.postalCode = input)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardBody className="pb-0">
                  <h4 className="card-title">Directeur des incendies</h4>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Nom <small className="text-danger" >{errors.fireManager && `● ${errors.fireManager}`}</small></Label>
                        <input className={`form-control ${errors.fireManager && 'is-invalid'}`}
                          type="text"
                          defaultValue={municipality?.fireManager}
                          ref={input => (inputs.fireManager = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone #1<small className="text-danger" >{errors.fireManagerPhone && `● ${errors.fireManagerPhone}`}</small></Label>
                        <input className={`form-control ${errors.fireManagerPhone && 'is-invalid'}`}
                          type="text"
                          defaultValue={municipality?.fireManagerPhone?.length > 0 ? municipality.fireManagerPhone[0] : null}
                          ref={input => (inputs.fireManagerPhoneOne = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone #2 </Label>
                        <input className="form-control"
                          type="text"
                          defaultValue={municipality?.fireManagerPhone?.length > 1 ? municipality.fireManagerPhone[1] : null}
                          ref={input => (inputs.fireManagerPhoneTwo = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody className="pb-0">
                  <h4 className="card-title">Responsable incendie</h4>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Nom <small className="text-danger" >{errors.incendiaManager && `● ${errors.incendiaManager}`}</small></Label>
                        <input className={`form-control ${errors.incendiaManager && 'is-invalid'}`}
                          type="text"
                          defaultValue={municipality?.incendiaManager}
                          ref={input => (inputs.incendiaManager = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone #1 <small className="text-danger" >{errors.incendiaManagerPhone && `● ${errors.incendiaManagerPhone}`}</small></Label>
                        <input className={`form-control ${errors.incendiaManagerPhone && 'is-invalid'}`}
                          type="text"
                          defaultValue={municipality?.incendiaManagerPhone?.length > 0 ? municipality.incendiaManagerPhone[0] : null}
                          ref={input => (inputs.incendiaManagerPhoneOne = input)}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="example-text-input" className="form-label">Téléphone #2</Label>
                        <input className="form-control"
                          type="text"
                          defaultValue={municipality?.incendiaManagerPhone?.length > 1 ? municipality.incendiaManagerPhone[1] : null}
                          ref={input => (inputs.incendiaManagerPhoneTwo = input)}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pb-0">
                  <h4 className="card-title">Services d'urgence</h4>
                  <Row>
                    <Col lg={6}>
                      <Card>
                        <CardBody className="pb-0">
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Centrale 911 <small className="text-danger" >{errors.central911 && `● ${errors.central911}`}</small></Label>
                                <select className="form-select"
                                  defaultValue={municipality?.central911}
                                  ref={input => (inputs.central911 = input)}
                                >
                                  <option value={null} > </option>
                                  {Object.keys(defaultCenters).map((key, idx) => (
                                    <option key={idx} value={defaultCenters[key]._id} > {defaultCenters[key].name} </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Centrale de télésurveillance <small className="text-danger" >{errors.centralRemoteMonitoring && `● ${errors.centralRemoteMonitoring}`}</small></Label>
                                <select className="form-select"
                                  defaultValue={municipality?.centralRemoteMonitoring}
                                  ref={input => (inputs.centralRemoteMonitoring = input)}
                                >
                                  <option value={null} > </option>
                                  {Object.keys(monitoringCenters).map((key, idx) => (
                                    <option key={idx} value={monitoringCenters[key]._id} > {monitoringCenters[key].name} </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card className="card-emphasis">
                        <CardBody className="pb-0">
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Caserne de pompier</Label>
                                <input className="form-control firephone"
                                  type="text"
                                  defaultValue={municipality?.fireStationPhone}
                                  ref={input => (inputs.fireStationPhone = input)}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Poste de police</Label>
                                <input className="form-control policephone"
                                  type="text"
                                  defaultValue={municipality?.policeStationPhone}
                                  ref={input => (inputs.policeStationPhone = input)}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label for="example-text-input" className="form-label">Paramédique</Label>
                                <input className="form-control medicphone"
                                  type="text"
                                  defaultValue={municipality?.paramedicPhone}
                                  ref={input => (inputs.paramedicPhone = input)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3" >
            <Col lg={12} className="text-right" >
              <button
                type="submit"
                className="btn btn-success waves-effect waves-light" >
                {params.id === 'add' ? 'Ajouter' : 'Sauvegarder'}
              </button>
            </Col>
          </Row>
        </Form>
      </Container> }
    </div>
  )
}

export default MunicipalityPage;
