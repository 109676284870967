import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
  Table
} from 'reactstrap'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { COffcanvas, COffcanvasBody, COffcanvasHeader, COffcanvasTitle } from '@coreui/bootstrap-react'
import JWT from 'jsonwebtoken'

import Loader from './../../../components/Common/Loader'
import { events } from './../../../actions/index'

const DISPLAYS_PER_PAGE = 25;

const EventsPage = () => {
  const params = useParams(),
    history = useHistory(),
    [isLoading, setIsLoading] = useState(true),
    [inputsDefaultVal, setInputsDefaultVal] = useState({ search: '' }),
    [data, setData] = useState({ count: 0, page: 1, events: [] });

  const [canvasVisible, setVisible] = useState(false)

  useEffect(async () => {
    let search = null, page = 1;
    if (params.query) {
      try {
        let decoded = JWT.decode(params.query);
        search = decoded.search ? decoded.search : null;
        page = decoded.page ? decoded.page : 1;
      } catch (err) {
        console.log(err); // TODO: Error management
      }
    }

    getEvents(page, search);
  }, [])

  let inputs = {};
  const onChange = page => {
    let search = null;
    if (inputs.search) {
      search = inputs.search.value;
    }

    let query = JWT.sign({ page: page, search: search }, 'WHAT_EVER_AS_IF_IT_BOTHERS');
    history.push(`/admin/events/${query}`);
    getEvents(page, search);
  }

  const getEvents = async (page, search) => {

    // Start loading
    setIsLoading(true);

    let res = await events.getEvents({
      search: search,
      page: page,
      length: DISPLAYS_PER_PAGE
    });

    // Set search default value
    setInputsDefaultVal({ search: search });

    // TODO: Error management
    setData({ count: res.data.count, events: res.data.events, page: page });

    // Stop loading
    setIsLoading(false);
  }

  let displayEvent = (event, idx) => {

    return <tr key={idx} >
      <th scope="row">{event.code}</th>
      <td>{event.descriptions.en}</td>
      <td>{event.priority}</td>
      <td>{event.datavalues.sims !== "" ? <i className="fa fa-check" style={{ color: 'green', fontSize: '24px' }}></i> : ''}</td>
      <td>{event.datavalues.shortmessages.en !== "" ? <i className="fa fa-check" style={{ color: 'green', fontSize: '24px' }}></i> : ''}</td>
      <td className="text-right">
        <span>
          <Link to={`/admin/event/${event._id}`}>
            <button className="btn btn-primary btn-sm" >
              View
            </button>
          </Link>
        </span>
      </td>
    </tr>;
  }

  let displayPage = idx => {
    let page = idx + 1;
    return <li className={`page-item ${data.page === page && 'active'}`} key={idx} >
      <Link className="page-link" to="#" onClick={() => { onChange(page) }}>
        {page}
      </Link>
    </li>
  }

  let pageCount = Math.max(1, Math.ceil(data.count / DISPLAYS_PER_PAGE));
  return (
    <div className="page-content">

      {isLoading && <Loader />}

      {!isLoading && <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Event management</h4>

                <Row className="mt-3" >
                  <Col lg={4} >
                    <form onSubmit={e => { e.preventDefault(); onChange(1) }} className="app-search d-none d-lg-block">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          defaultValue={inputsDefaultVal.search}
                          ref={input => (inputs.search = input)}
                        />
                        <span className="mdi mdi-magnify" />
                      </div>
                    </form>
                  </Col>
                  <Col lg={8} className="d-flex align-items-center justify-content-end" >
                    <Link to="/admin/event/add">
                      <button className="btn btn-success waves-effect waves-light" >
                        Add event
                      </button>
                    </Link>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Event code</th>
                        <th>Description</th>
                        <th>Priority</th>
                        <th>Contact-ID</th>
                        <th>SMS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.events.map((event, idx) => (
                        displayEvent(event, idx)
                      ))}

                      {data.count === 0 && <tr>
                        <td colSpan="6" className='text-center' >No event yet ...</td>
                      </tr>}

                      {/* TODO: No result management */}
                    </tbody>
                  </Table>
                </div>
                <Row className="mt-4 mb-2" >
                  <Col lg={12} className="d-flex justify-content-center" >
                    <nav aria-label="Page navigation example">
                      <ul className="pagination pagination-rounded mb-0">
                        <li className={`page-item ${data.page === 1 && 'disabled'}`} >
                          <Link
                            className="page-link"
                            onClick={() => { onChange(data.page - 1) }}
                            to="#"
                            aria-label="Previous"
                          >
                            <i className="mdi mdi-chevron-left"></i>
                          </Link>
                        </li>
                        {Array(pageCount).fill(null).map((_, idx) => (
                          displayPage(idx)
                        ))}
                        <li className={`page-item ${data.page === pageCount && 'disabled'}`}>
                          <Link
                            className="page-link"
                            onClick={() => { onChange(data.page + 1) }}
                            to="#"
                            aria-label="Next" >
                            <i className="mdi mdi-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </div>
  )
}
export default EventsPage;